import {Button, Col, Form, InputGroup, Modal, Row, Spinner} from 'react-bootstrap'
import {IAllMembersNode, IResCreateMember, IResEditMember} from '../../../types/members'
import {useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {toast} from 'react-toastify'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {CREATE_USER, EDIT_USER} from '../../../gql/mutations/userMutations'
import {parseId} from '../../../helpers'
import {GET_ROLES_GROUP} from '../../../gql/queries/rolesGroupQuery'
import {IResRolesGroups} from '../../../types/roles'

const INIT_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  cellphone: '',
  applyDeductions: '',
  password: '',
  confirmPassword: '',
  rol: '',
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  cellphone: Yup.string().required('Cellphone is required'),
  applyDeductions: Yup.string().required('Apply deductions is required'),
  rol: Yup.string().required('Role is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
})

interface IModalAddUserProps {
  isOpen: true
  onClose: () => void
  refetch: () => void
  user?: IAllMembersNode
}

export const ModalAddUser: React.FC<IModalAddUserProps> = ({isOpen, onClose, refetch, user}) => {
  const {
    data: dataRoles,
    loading: loadingRoles,
    error: errorRoles,
  } = useQuery<IResRolesGroups>(GET_ROLES_GROUP, {fetchPolicy: 'no-cache'})
  const [gqlCreate, {data, loading, error}] = useMutation<IResCreateMember>(CREATE_USER)
  const [gqlEdit, {data: dataEdit, loading: loadingEdit, error: errorEdit}] =
    useMutation<IResEditMember>(EDIT_USER)

  const formik = useFormik({
    initialValues: INIT_VALUES,
    validationSchema,

    onSubmit: (v) => {
      const userData = {
        ...v,
        applyDeductions: v.applyDeductions
          ? v.applyDeductions === 'yes'
            ? true
            : false
          : undefined,
        confirmPassword: undefined,
        groups: [parseId(v.rol)],
        isActive: true,
        rol: undefined,
      }
      if (user) {
        gqlEdit({
          variables: {
            userData,
            userId: parseId(user.id),
          },
        })
      } else {
        gqlCreate({
          variables: {
            userData,
          },
        })
      }
    },
  })

  const [showPass, setShowPass] = useState(false)
  const [showConfirmPass, setShowConfirmPass] = useState(false)

  useEffect(() => {
    if (!user) return
    formik.setValues({
      applyDeductions: user.applyDeductions ? 'yes' : 'no',
      cellphone: user.cellphone || '',
      confirmPassword: '',
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      password: '',
      rol: user.groups.edges.length > 0 ? user.groups.edges[0].node.id : '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])
  useEffect(() => {
    if (errorEdit) toast.error(`Error: ${errorEdit.message}`)
  }, [errorEdit])
  useEffect(() => {
    if (errorRoles) toast.error(`Error: ${errorRoles.message}`)
  }, [errorRoles])

  useEffect(() => {
    if (!data) return
    if (data?.createUserMutation?.user?.id) {
      toast.success('user added successfully')
      refetch()
      onClose()
    } else if (data?.createUserMutation.errors?.length > 0) {
      toast.error(`Error: ${data?.createUserMutation.errors[0]?.message || 'Something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (!dataEdit) return
    if (dataEdit?.updateUserMutation?.user?.id) {
      toast.success('user updated successfully')
      refetch()
      onClose()
    } else if (dataEdit?.updateUserMutation.errors?.length > 0) {
      toast.error(`Error: ${dataEdit?.updateUserMutation.errors[0]?.message || 'Something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit])

  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Invite a new user</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row className='g-2'>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>
                  First name <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Control
                  placeholder='First name'
                  name='firstName'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  isInvalid={Boolean(formik.errors.firstName && formik.touched.firstName)}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>
                  Last name <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Control
                  placeholder='Last name'
                  name='lastName'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  isInvalid={Boolean(formik.errors.lastName && formik.touched.lastName)}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>
                  Email address <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Control
                  placeholder='Email address'
                  type='email'
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  isInvalid={Boolean(formik.errors.email && formik.touched.email)}
                />
                <Form.Control.Feedback type='invalid'>{formik.errors.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>Cellphone number</Form.Label>
                <Form.Control
                  placeholder='Cellphone'
                  type='tel'
                  name='cellphone'
                  value={formik.values.cellphone}
                  onChange={(e) => {
                    const {value} = e.target
                    if (value === '') {
                      formik.setFieldValue('cellphone', '')
                    }
                    if (Number(value)) {
                      formik.setFieldValue('cellphone', value)
                    }
                  }}
                  maxLength={10}
                  isInvalid={Boolean(formik.errors.cellphone && formik.touched.cellphone)}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.cellphone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    type={showPass ? 'text' : 'password'}
                    placeholder='Insert password'
                    aria-label='Insert password'
                    aria-describedby='password'
                    name='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isInvalid={Boolean(formik.errors.password && formik.touched.password)}
                  />
                  <Button
                    variant='outline-secondary'
                    id='button-addon2'
                    style={{border: '1px solid #dbdfe9'}}
                    onClick={() => setShowPass((p) => !p)}
                  >
                    <i
                      className={`bi bi-${showPass ? 'eye-slash' : 'eye'} fs-2`}
                      style={{color: 'inherit'}}
                    />
                  </Button>
                </InputGroup>
                {formik.errors.password && formik.touched.password && (
                  <small className='text-danger'>{formik.errors.password}</small>
                )}
              </Form.Group>
            </Col>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    type={showConfirmPass ? 'text' : 'password'}
                    placeholder='Confirm password'
                    aria-label='Confirm password'
                    aria-describedby='Confirm password'
                    name='confirmPassword'
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    isInvalid={Boolean(
                      formik.errors.confirmPassword && formik.touched.confirmPassword
                    )}
                  />
                  <Button
                    variant='outline-secondary'
                    id='Confirm-pass'
                    onClick={() => setShowConfirmPass((p) => !p)}
                    style={{border: '1px solid #dbdfe9'}}
                  >
                    <i
                      className={`bi bi-${showConfirmPass ? 'eye-slash' : 'eye'} fs-2`}
                      style={{color: 'inherit'}}
                    />
                  </Button>
                </InputGroup>
                {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                  <small className='text-danger'>{formik.errors.confirmPassword}</small>
                )}
              </Form.Group>
            </Col>

            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>
                  ¿Apply deductions? <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Select
                  name='applyDeductions'
                  value={formik.values.applyDeductions}
                  onChange={formik.handleChange}
                  isInvalid={Boolean(
                    formik.errors.applyDeductions && formik.touched.applyDeductions
                  )}
                >
                  <option value=''>Select One</option>
                  {['yes', 'no'].map((text) => (
                    <option key={text} value={text}>
                      {text}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.applyDeductions}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <small>
                <span className='text-primary' style={{textDecoration: 'underline'}}>
                  Click here
                </span>{' '}
                to learn more about our access roles.
              </small> */}
            </Col>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>
                  Rol <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Select
                  name='rol'
                  value={formik.values.rol}
                  onChange={formik.handleChange}
                  isInvalid={Boolean(formik.errors.rol && formik.touched.rol)}
                  disabled={loadingRoles}
                >
                  {loading ? (
                    <option value=''>Loading...</option>
                  ) : (
                    <option value=''>Select One</option>
                  )}
                  {dataRoles?.allGroups.edges.map(({node}) => (
                    <option key={node.id} value={node.id}>
                      {node.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{formik.errors.rol}</Form.Control.Feedback>
              </Form.Group>
              {/* <small>
                <span className='text-primary' style={{textDecoration: 'underline'}}>
                  Click here
                </span>{' '}
                to learn more about our access roles.
              </small> */}
            </Col>
            {/* <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>
                  Preferred language <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Select>
                  <option>Spanish</option>
                  <option>English</option>
                </Form.Select>
              </Form.Group>
            </Col> */}
            <Col className='mt-5'>
              <Form.Check
                type='checkbox'
                label={
                  <span className='text-dark'>
                    Check this box to send this user an email invitation.
                  </span>
                }
                defaultChecked
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' variant='secondary' onClick={onClose}>
            Close
          </Button>
          <Button type='submit' variant='primary' disabled={loading || loadingEdit}>
            {(loading || loadingEdit) && <Spinner animation='border' size='sm' />}
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

import {useMutation} from '@apollo/client'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import {UPDATE_PASSWORD_ADMIN} from '../../../gql/mutations/userMutations'
import {toast} from 'react-toastify'
import {parseId} from '../../../helpers'
import {IResUpdatePassAdmin} from '../../../types/membersPolicy'

interface IChangePassProps {
  id: string
  refetch: () => void
}

export const ChangePassword: React.FC<IChangePassProps> = ({id, refetch}) => {
  const [gqlUpdatePass, {data, loading, error}] =
    useMutation<IResUpdatePassAdmin>(UPDATE_PASSWORD_ADMIN)
  const [showPass, setShowPass] = useState(false)
  const [showConfirmPass, setShowConfirmPass] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .required('Password require'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Password require'),
    }),
    onSubmit: (v, o) => {
      if (v.password && v.password !== v.confirmPassword) {
        o.setFieldError('confirmPassword', 'Passwords must match')
        return
      }
      gqlUpdatePass({
        variables: {
          nuevaClave: v.password,
          userId: parseId(id),
        },
      })
    },
  })

  useEffect(() => {
    if (error) toast.error(`Error: ${error}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  useEffect(() => {
    if (!data) return
    if (data.changeUserPasswordMutation.success) {
      toast.success('Password updated successfully')
      refetch()
    } else if (
      data.changeUserPasswordMutation.errors &&
      data.changeUserPasswordMutation.errors.length > 0
    ) {
      toast.error(
        `Error: ${data.changeUserPasswordMutation.errors[0]?.message || 'Something wrong!'}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Change Password</h3>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className='card-body border-top p-9'>
          <Row>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    type={showPass ? 'text' : 'password'}
                    placeholder='Insert password'
                    aria-label='Insert password'
                    aria-describedby='password'
                    name='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={Boolean(formik.errors.password && formik.touched.password)}
                  />
                  <Button
                    variant='outline-secondary'
                    id='button-addon2'
                    style={{border: '1px solid #dbdfe9'}}
                    onClick={() => setShowPass((p) => !p)}
                  >
                    <i
                      className={`bi bi-${showPass ? 'eye-slash' : 'eye'} fs-2`}
                      style={{color: 'inherit'}}
                    />
                  </Button>
                </InputGroup>
                {formik.errors.password && formik.touched.password && (
                  <small className='text-danger'>{formik.errors.password}</small>
                )}
              </Form.Group>
            </Col>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    type={showConfirmPass ? 'text' : 'password'}
                    placeholder='Confirm password'
                    aria-label='Confirm password'
                    aria-describedby='Confirm password'
                    name='confirmPassword'
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={Boolean(
                      formik.errors.confirmPassword && formik.touched.confirmPassword
                    )}
                  />
                  <Button
                    variant='outline-secondary'
                    id='Confirm-pass'
                    onClick={() => setShowConfirmPass((p) => !p)}
                    style={{border: '1px solid #dbdfe9'}}
                  >
                    <i
                      className={`bi bi-${showConfirmPass ? 'eye-slash' : 'eye'} fs-2`}
                      style={{color: 'inherit'}}
                    />
                  </Button>
                </InputGroup>
                {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                  <small className='text-danger'>{formik.errors.confirmPassword}</small>
                )}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-info' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

import {Button, Spinner} from 'react-bootstrap'

export const LoadingCarriers = () => {
  return (
    <div>
      <header className='d-flex align-items-center justify-content-between'>
        <h2>Manage Carriers</h2>
        <Button disabled>+ Carriers</Button>
      </header>
      <div className='d-flex flex-center' style={{height: '50vh'}}>
        <Spinner animation='border' />
      </div>
    </div>
  )
}

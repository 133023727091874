import {Card, Form, InputGroup, Table} from 'react-bootstrap'

export const LoadingMsgs = () => {
  return (
    <>
      <Card.Header>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input widget-9-check'
                disabled
                type='checkbox'
                value='1'
              />
            </div>
          </div>
          <div className='d-flex gap-2 align-items center'>
            <InputGroup className=''>
              <InputGroup.Text id='basic-addon1'>
                <i className='bi bi-search' />
              </InputGroup.Text>
              <Form.Control
                disabled
                placeholder='Username'
                aria-label='Username'
                aria-describedby='basic-addon1'
              />
            </InputGroup>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className='table-responsive'>
          <Table className='w-100 table-hover table-row-dashed fs-6 gy-5 my-0 dataTable'>
            <tbody>
              {[1, 2, 3, 4].map((info) => (
                <tr key={info}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        disabled
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value='1'
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex gap-2'>
                      <button style={{backgroundColor: 'transparent', border: 0}}>
                        <i className='bi bi-star-fill fs-3' />
                      </button>
                      <button style={{backgroundColor: 'transparent', border: 0}}>
                        <i className='bi bi-chat-left fs-3' />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-start'>
                      <div className='symbol symbol-45px me-5' style={{overflow: 'hidden'}}>
                        <div style={{width: '45px', height: '45px', backgroundColor: 'gray'}} />
                      </div>
                      <div
                        style={{minWidth: '8ch'}}
                        className='d-flex w-100 justify-content-start flex-column placeholder-glow'
                      >
                        <span className='placeholder col-12 p-0 m-0'></span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        minWidth: '16ch',
                        border: 0,
                        backgroundColor: 'transparent',
                        textAlign: 'left',
                      }}
                      className='text-dark fw-bold text-hover-primary d-flex fs-6 placeholder-glow'
                      onClick={() => {}}
                    >
                      <span className='placeholder col-12'></span>
                    </div>
                    {/* {info.badge &&
                        info.badge.map((bg) => (
                          <Badge key={bg.text} bg={bg.color} className='mx-1'>
                            {bg.text}
                          </Badge>
                        ))} */}
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div
                        className='d-flex flex-stack mb-2 placeholder-glow'
                        style={{minWidth: '4ch'}}
                      >
                        <span className='placeholder col-12'></span>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </>
  )
}

import React, {useEffect} from 'react'
import {NodeNotes} from '../../leads/components/sectionsTopNav/sectionNavTypes'
import {Button, Col, FloatingLabel, Form, Offcanvas, Row, Spinner} from 'react-bootstrap'
import {useMutation} from '@apollo/client'
import {CREATE_NOTE_LEAD, EDIT_NOTE_LEAD} from '../../../gql/mutations/leadMutations'
import {IResDataAddNote, IResDataEditNote} from '../../leads/leadTypes'
import {toast} from 'react-toastify'
import {parseId} from '../../../helpers'

interface INoteDrawerProps {
  onClose: () => void
  isOpen: boolean
  leadId: string
  infoEdit: Omit<NodeNotes, 'created' | 'user' | 'date'> | null
  refetch: () => void
}
export const AddEditNoteDrawer: React.FC<INoteDrawerProps> = ({
  infoEdit,
  isOpen,
  leadId,
  onClose,
  refetch,
}) => {
  const [gqlCreate, {data: dataNote, loading: loadingNote, error: errorNote}] =
    useMutation<IResDataAddNote>(CREATE_NOTE_LEAD)
  const [gqlEdit, {data: dataEditNote, loading: loadingEditNote, error: errorEditNote}] =
    useMutation<IResDataEditNote>(EDIT_NOTE_LEAD)

  useEffect(() => {
    if (errorNote) toast.error(`Error: ${errorNote.message}`)
  }, [errorNote])
  useEffect(() => {
    if (errorEditNote) toast.error(`Error: ${errorEditNote.message}`)
  }, [errorEditNote])

  useEffect(() => {
    if (!dataNote) return
    if (dataNote.createNote.success) {
      toast.success('Note added successfully')
      onClose()
      refetch()
    } else if (dataNote.createNote?.errors?.length > 0) {
      toast.error(`Error: ${dataNote.createNote.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNote])
  useEffect(() => {
    if (!dataEditNote) return
    if (dataEditNote.updateNote.success) {
      toast.success('Note edited successfully')
      onClose()
      refetch()
    } else if (dataEditNote.updateNote?.errors?.length > 0) {
      toast.error(`Error: ${dataEditNote.updateNote.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEditNote])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const {tittle, description} = Object.fromEntries(new FormData(e.currentTarget))
    if (!tittle || !description || !leadId) return
    const noteData = {
      tittle,
      description,
      lead: leadId,
    }
    if (infoEdit) {
      const variables = {
        id: parseId(infoEdit.id),
        noteData,
      }
      gqlEdit({variables})
    } else {
      gqlCreate({
        variables: {
          noteData,
        },
      })
    }
  }

  return (
    <Offcanvas show={isOpen} onHide={onClose} placement='end' className='w-100 w-md-50 w-lg-50'>
      <Offcanvas.Header closeButton className='bg-light'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Offcanvas.Title className='d-flex align-items-center gap-4'>
            {infoEdit ? 'Edit' : 'Add'} Note
          </Offcanvas.Title>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form onSubmit={handleSubmit}>
          <div className='w-100 h-100'>
            <div>
              <Row>
                <Col className='col-12 mb-4'>
                  <FloatingLabel
                    controlId='tittle'
                    label={
                      <span>
                        Title <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      placeholder='Title'
                      name='tittle'
                      required
                      defaultValue={infoEdit?.tittle || undefined}
                    />
                  </FloatingLabel>
                </Col>
                <Col className='col-12 mb-4'>
                  <FloatingLabel
                    controlId='description'
                    label={
                      <span>
                        Note <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      placeholder='Insert note'
                      as='textarea'
                      rows={4}
                      type='textarea'
                      name='description'
                      className='h-100'
                      required
                      defaultValue={infoEdit?.description || undefined}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>

            <div className='d-flex algin-items-center justify-content-end gap-3 mt-4'>
              <Button type='button' variant='light' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit' variant='primary' disabled={loadingNote || loadingEditNote}>
                {(loadingNote || loadingEditNote) && <Spinner size='sm' animation='border' />}
                Save
              </Button>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

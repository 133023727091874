import {Button, Dropdown} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'

export const LoadingOneUserPage = () => {
  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/tfi/logo-icoN.png')} alt='Metornic' />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='/#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      <span
                        className='placeholder-glow'
                        style={{
                          width: '120px',
                          display: 'inline-block',
                        }}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </a>
                    <a href='/#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='/#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                      <span
                        className='placeholder-glow'
                        style={{
                          width: '120px',
                          display: 'inline-block',
                        }}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </a>
                    <a
                      href='/#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      <span
                        className='placeholder-glow'
                        style={{
                          width: '120px',
                          display: 'inline-block',
                        }}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </a>
                    <a
                      href='/#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      <span
                        className='placeholder-glow'
                        style={{
                          width: '120px',
                          display: 'inline-block',
                        }}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </a>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <button className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTIcon iconName='check' className='fs-3 d-none' />

                    <span className='indicator-label'>Follow</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                  <button className='btn btn-sm btn-primary me-3'>Hire Me</button>
                  <div className='me-0'>
                    <Dropdown>
                      <Dropdown.Toggle size='sm' variant='success' id='dropdown-basic' disabled>
                        <i className='bi bi-three-dots fs-3'></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>4500$</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Earnings</div>
                    </div>

                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                        <div className='fs-2 fw-bolder'>75</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>60%</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                    </div> */}
                  </div>
                </div>

                {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                    <span className='fw-bolder fs-6'>50%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{width: '50%'}}
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {(['Overview', 'Edit user', 'Transactions'] as const).map((tb) => (
                <li className='nav-item'>
                  <button className={`nav-link text-active-primary me-6`}>{tb}</button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>

          <Button disabled className='align-self-center'>
            Edit Profile
          </Button>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                <span
                  className='placeholder-glow'
                  style={{
                    width: '120px',
                    display: 'inline-block',
                  }}
                >
                  <span className='placeholder col-12' />
                </span>
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Company</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                <span
                  className='placeholder-glow'
                  style={{
                    width: '120px',
                    display: 'inline-block',
                  }}
                >
                  <span className='placeholder col-12' />
                </span>
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Contact Phone
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>
                <span
                  className='placeholder-glow'
                  style={{
                    width: '120px',
                    display: 'inline-block',
                  }}
                >
                  <span className='placeholder col-12' />
                </span>
              </span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Company Site</label>

            <div className='col-lg-8'>
              <a href='/#' className='fw-bold fs-6 text-dark text-hover-primary'>
                <span
                  className='placeholder-glow'
                  style={{
                    width: '120px',
                    display: 'inline-block',
                  }}
                >
                  <span className='placeholder col-12' />
                </span>
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Country
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                <span
                  className='placeholder-glow'
                  style={{
                    width: '120px',
                    display: 'inline-block',
                  }}
                >
                  <span className='placeholder col-12' />
                </span>
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Communication</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>Email, Phone</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Allow Changes</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>Yes</span>
            </div>
          </div>

          <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
                <div className='fs-6 text-gray-600 d-flex align-items-center gap-2'>
                  Your payment was declined. To start using tools, please
                  <button className='fw-bolder btn btn-link'> Add Payment Method</button>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

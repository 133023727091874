import {Dispatch, SetStateAction} from 'react'
import {Accordion, Alert, Button, Col, Form, Row} from 'react-bootstrap'
import {IForm} from '../oneQuoteTypes'
import {useNavigate} from 'react-router-dom'

export const AccordionForm = ({
  handleSubmit,
  formData,
  setFormData,
  handleChange,
}: {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  formData: IForm
  setFormData: Dispatch<SetStateAction<IForm>>
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const navigate = useNavigate()
  return (
    <Accordion>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>Household Form</Accordion.Header>
        <Accordion.Body>
          <Form onSubmit={handleSubmit}>
            <div>
              <Alert variant='primary' className='my-5 d-flex align-items-center gap-2'>
                <span>
                  <i className='bi bi-exclamation-circle fs-1' style={{color: 'inherit'}} />
                </span>
                Please fill in the necessary information below to receive a quote for insurance
                plans.
              </Alert>
              <Row className='mt-4'>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label>Household Income</Form.Label>
                    <Form.Control
                      type='text'
                      name='householdIncome'
                      value={formData.householdIncome}
                      onChange={(e) => {
                        const {value} = e.target
                        const noComma = value.replaceAll(',', '')
                        if (noComma !== '' && isNaN(Number(noComma))) return
                        const str = Intl.NumberFormat('en-US').format(Number(noComma))
                        setFormData((p) => ({...p, householdIncome: str}))
                      }}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type='date'
                      name='dob'
                      value={formData.dob}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      required
                      as='select'
                      name='gender'
                      value={formData.gender}
                      onChange={handleChange}
                    >
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                      <option value='Other'>Other</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-4'>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <Form.Group className='d-flex'>
                    <Form.Check
                      type='switch'
                      // label='Is Pregnant'
                      name='isPregnant'
                      id='isPregnant'
                      checked={formData.isPregnant}
                      onChange={handleChange}
                    />
                    <Form.Label htmlFor='isPregnant'>
                      Is Pregnant ({formData.isPregnant ? 'YES' : 'NO'})
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <Form.Group className='d-flex'>
                    <Form.Check
                      type='switch'
                      // label='Is Pregnant'
                      name='isParent'
                      id='isParent'
                      checked={formData.isParent}
                      onChange={handleChange}
                    />
                    <Form.Label htmlFor='isParent'>
                      Is Parent ({formData.isParent ? 'YES' : 'NO'})
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <Form.Group className='d-flex'>
                    <Form.Check
                      type='switch'
                      // label='Is Pregnant'
                      name='aptcEligible'
                      id='aptcEligible'
                      checked={formData.aptcEligible}
                      onChange={handleChange}
                    />
                    <Form.Label htmlFor='aptcEligible'>
                      APTC Eligible ({formData.aptcEligible ? 'YES' : 'NO'})
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <Form.Group className='d-flex'>
                    <Form.Check
                      type='switch'
                      // label='Is Pregnant'
                      name='usesTobacco'
                      id='usesTobacco'
                      checked={formData.usesTobacco}
                      onChange={handleChange}
                    />
                    <Form.Label htmlFor='usesTobacco'>
                      Uses Tobacco ({formData.usesTobacco ? 'YES' : 'NO'})
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <Form.Group className='d-flex'>
                    <Form.Check
                      type='switch'
                      // label='Is Pregnant'
                      name='hasMarriedCouple'
                      id='hasMarriedCouple'
                      checked={formData.hasMarriedCouple}
                      onChange={handleChange}
                    />
                    <Form.Label htmlFor='hasMarriedCouple'>
                      Has Married Couple ({formData.hasMarriedCouple ? 'YES' : 'NO'})
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <Form.Group className='d-flex'>
                    <Form.Check
                      type='switch'
                      // label='Is Pregnant'
                      name='hasMec'
                      id='hasMec'
                      checked={formData.hasMec}
                      onChange={handleChange}
                    />
                    <Form.Label htmlFor='hasMec'>
                      Has MEC ({formData.hasMec ? 'YES' : 'NO'})
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-4'>
                {/* <Col lg={4}>
                <Form.Group>
                  <Form.Label>Market</Form.Label>
                  <Form.Control
                    as='select'
                    name='market'
                    value={formData.market}
                    onChange={handleChange}
                  >
                    <option value='Individual'>Individual</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
                <Col lg={3} md={6} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>County FIPS</Form.Label>
                    <Form.Control
                      type='text'
                      name='countyfips'
                      value={formData.countyfips}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type='text'
                      name='state'
                      value={formData.state}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3} md={6} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type='text'
                      name='zipCode'
                      value={formData.zipCode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Order</Form.Label>
                    <Form.Control
                      as='select'
                      name='order'
                      value={formData.order}
                      onChange={handleChange}
                    >
                      <option value='asc'>Ascending</option>
                      <option value='desc'>Descending</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              {/* <Row className='mt-4'>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Limit</Form.Label>
                  <Form.Control
                    type='number'
                    name='limit'
                    value={formData.limit}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type='number'
                    name='offset'
                    value={formData.offset}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            </div>
            <div className='d-flex align-items-center justify-content-end my-5 gap-3'>
              <Button variant='secondary' type='button' onClick={() => navigate(-1)}>
                Back
              </Button>
              <Button variant='primary' type='submit'>
                Search
              </Button>
            </div>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

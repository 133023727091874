import {useLazyQuery} from '@apollo/client'
import {ChangeEvent, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {GET_COMISSIONS_ID, GET_ONE_CARRIER} from '../../gql/queries/carriersQuery'
import {ICommissions, IResCarriers, IResCommissions, IResFileExcel} from '../../types/carriers'
import {toast} from 'react-toastify'
import {LoadingOneCarrier} from './components/LoadingOneCarrier'
import {useGetUser} from '../../store/userStore'
import {Button, Spinner} from 'react-bootstrap'
import moment from 'moment'
import {URL} from '../../gql/client'
import {parsePrice} from '../../helpers'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalEdit} from '../carriers/components/ModalEdit'
import {ModalDelete} from '../carriers/components/ModalDelete'
import {UPLOAD_EXCEL_STR} from '../../gql/mutations/carriersMutations'
import {useUploadFile} from '../../hooks/useUploadFile'
import {ModalAddCommission} from './components/ModalAddCommission'

export const OneCarrierPage = () => {
  const [gqlGetCommisions, {data, loading, error, refetch}] = useLazyQuery<IResCommissions>(
    GET_COMISSIONS_ID,
    {
      fetchPolicy: 'no-cache',
    }
  )
  const [gqlGetCarrier, {data: dataCarrier, loading: loadingCarrier, error: errorCarrier}] =
    useLazyQuery<IResCarriers>(GET_ONE_CARRIER, {
      fetchPolicy: 'no-cache',
    })

  const [uploadFile, {data: dataFile, error: errorFile, loading: loadingFile}] =
    useUploadFile<IResFileExcel>()

  const fileInputRef = useRef<HTMLInputElement>(null)

  const user = useGetUser((s) => s.user)

  const navigate = useNavigate()
  const {id} = useParams()
  const [loadingExport, setLoadingExport] = useState(false)

  const [info, setInfo] = useState<ICommissions | null>(null)

  const {isOpen: isOpenAdd, onClose: onCloseAdd, onOpen: onOpenAdd} = useDisclourse()
  const {isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete} = useDisclourse()
  const {isOpen: isOpenEdit, onClose: onCloseEdit, onOpen: onOpenEdit} = useDisclourse()

  useEffect(() => {
    if (!id) {
      return navigate('/')
    }
    if (Number(id)) {
      gqlGetCarrier({
        variables: {
          id,
        },
      })
      gqlGetCommisions({
        variables: {
          operatorId: id,
        },
      })
      return
    }
    navigate('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (errorCarrier) toast.error(`Error: ${errorCarrier.message}`)
  }, [errorCarrier])
  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])
  useEffect(() => {
    if (errorFile) toast.error(`Error: ${errorFile}`)
  }, [errorFile])

  useEffect(() => {
    if (!dataFile) return
    if (dataFile.UpdateCommissionMutation.success) {
      toast.success('File uploaded successfully')
      refetch()
    } else {
      toast.error(`Error: ${dataFile.UpdateCommissionMutation.message || 'something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFile])

  const handleDownload = async () => {
    if (!user) return
    const {token} = user
    if (!token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    }

    setLoadingExport(true)
    try {
      const res = await fetch(`${URL}/export-commissions-excel`, requestOptions)
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `carriers-${moment().format('DD-MM-YY')}.xlsx`
      document.body.appendChild(link)
      setLoadingExport(false)
      link.click()
    } catch (err) {
      setLoadingExport(false)
      toast.error(`ERROR: ${(err as {message: string}).message}`)
    }
  }

  const handleButtonClick = () => {
    fileInputRef?.current?.click()
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]
    if (!file) return
    const formdata = new FormData()
    formdata.append('0', file, file.name)
    formdata.append('map', '{"0": ["variables.excelFile"]}')
    const obj = {
      query: UPLOAD_EXCEL_STR,
      variables: {
        excelFile: null,
      },
    }
    formdata.append('operations', JSON.stringify(obj))
    uploadFile(formdata)
  }

  if (loading || loadingCarrier || !data || !dataCarrier) {
    return <LoadingOneCarrier />
  }

  const {edges} = dataCarrier.allInsuranceOperators

  if (edges.length === 0) return <div>Not found</div>

  const carrier = edges[0].node

  return (
    <>
      <div>
        <header className='d-flex align-items-center flex-column flex-md-row justify-content-between'>
          <h3>{carrier.name}</h3>
          <div className='d-flex align-items-center  gap-3 flex-column flex-md-row'>
            <Button
              className='w-100 w-md-auto'
              onClick={handleDownload}
              disabled={loadingExport}
              variant='info'
            >
              {loadingExport ? (
                <Spinner animation='border' size='sm' />
              ) : (
                <i className='bi bi-file-earmark-arrow-down fs-3' />
              )}
              Excel base
            </Button>
            <Button
              className='w-100 w-md-auto'
              variant='success'
              onClick={handleButtonClick}
              disabled={loadingFile}
            >
              {loadingExport || loadingFile ? (
                <Spinner animation='border' size='sm' />
              ) : (
                <i className='bi bi-file-earmark-arrow-up-fill fs-3' />
              )}
              Upload Excel
            </Button>
            <input
              type='file'
              ref={fileInputRef}
              style={{display: 'none'}}
              accept='.xlsx,.xls'
              onChange={handleFileChange}
            />
            <Button
              className='w-100 w-md-auto'
              variant='primary'
              onClick={() => {
                onOpenAdd()
              }}
            >
              <i className='bi bi-plus fs-3' />
              Commission
            </Button>
          </div>
        </header>
        {data.commissionsByOperatorId.length === 0 ? (
          <div className='d-flex flex-center' style={{height: '30vh'}}>
            <h4>No commission has been registered yet</h4>
          </div>
        ) : (
          <div className='card px-4 mt-8' style={{backgroundColor: 'white'}}>
            <table className='table table-row-dashed table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>State</th>
                  <th>PolicyType</th>
                  <th>Nominal Value</th>
                  <th className='text-center'>Percentage Value</th>
                  <th>Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.commissionsByOperatorId.map((node) => (
                  <tr>
                    <td>{node.state.name}</td>
                    <td>{node.policyType.typePolicy}</td>
                    <td>{Number(node.nominalValue) > 0 ? parsePrice(node.nominalValue) : '-'}</td>
                    <td className='text-center'>
                      {Number(node.percentageValue) > 0
                        ? Number(node.percentageValue).toFixed(0) + '%'
                        : '-'}
                    </td>
                    <td>{moment(node.created).format('DD MMM, YYYY')}</td>
                    <td>
                      <div className='d-flex gap-2 align-items-center'>
                        <button
                          onClick={() => {
                            setInfo(node)
                            onOpenEdit()
                          }}
                          className='text-info'
                          style={{backgroundColor: 'transparent', border: 0}}
                        >
                          <i className='bi bi-pencil fs-3' style={{color: 'inherit'}} />
                        </button>

                        <button
                          onClick={() => {
                            setInfo(node)
                            onOpenDelete()
                          }}
                          className='text-danger'
                          style={{backgroundColor: 'transparent', border: 0}}
                        >
                          <i className='bi bi-trash fs-3' style={{color: 'inherit'}} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {isOpenEdit && info && (
        <ModalEdit info={info} isOpen={isOpenEdit} onClose={onCloseEdit} refetch={refetch} />
      )}
      {isOpenDelete && info && (
        <ModalDelete info={info} isOpen={isOpenDelete} onClose={onCloseDelete} refetch={refetch} />
      )}
      {isOpenAdd && (
        <ModalAddCommission
          info={{id: carrier.id}}
          isOpen={isOpenAdd}
          onClose={onCloseAdd}
          refetch={refetch}
        />
      )}
    </>
  )
}

import {useMutation} from '@apollo/client'
import {Button, Col, FloatingLabel, Form, Offcanvas, Row, Spinner} from 'react-bootstrap'
import {CREATE_UPDATE_APPOINTMENT} from '../../../../gql/mutations/leadMutations'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {toast} from 'react-toastify'
import {IResAddAppointment} from '../../leadTypes'
import {IAllMembersEdges} from '../../../../types/members'
import {NodeAppointmentSet} from '../sectionsTopNav/sectionNavTypes'
import {parseId} from '../../../../helpers'

export const FormAppointment: React.FC<{
  onBack: () => void
  leadId: string
  members: IAllMembersEdges[]
  infoEdit: Omit<NodeAppointmentSet, 'created'> | null
  setInfoEditApp: Dispatch<SetStateAction<Omit<NodeAppointmentSet, 'created'> | null>>
}> = ({onBack, leadId, members, infoEdit}) => {
  const [gqlCreate, {data, loading, error}] =
    useMutation<IResAddAppointment>(CREATE_UPDATE_APPOINTMENT)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.appointmentCreateupdateMutation.appointment?.id) {
      toast.success(`Appointment ${infoEdit ? 'edited' : 'added'} successfully`)
      onBack()
    } else if (data.appointmentCreateupdateMutation?.errors?.length > 0) {
      toast.error(`Error: ${data.appointmentCreateupdateMutation.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const {
      tittle,
      typeAppointment,
      meetingLocation,
      date,
      duration,
      assignedTo,
      creditTo,
      comment,
    } = Object.fromEntries(new FormData(e.currentTarget))
    gqlCreate({
      variables: {
        input: {
          tittle,
          typeAppointment,
          meetingLocation,
          date,
          duration,
          assignedTo,
          creditTo,
          comment,
          lead: leadId,
          id: infoEdit && infoEdit.id ? parseId(infoEdit.id) : undefined,
        },
      },
    })
  }
  return (
    <>
      <Offcanvas.Header closeButton className='bg-light'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Offcanvas.Title className='d-flex align-items-center gap-4'>Appointment</Offcanvas.Title>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form onSubmit={handleSubmit}>
          <div className='w-100 h-100'>
            <div>
              <Row>
                <Col className='col-12 mb-4'>
                  <FloatingLabel
                    controlId='name'
                    label={
                      <span>
                        Tittle <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      placeholder='Tittle'
                      name='tittle'
                      required
                      defaultValue={infoEdit?.tittle || undefined}
                    />
                  </FloatingLabel>
                </Col>
                {/* two */}
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='typeAppointment'
                    label={
                      <span>
                        Appointment Type <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Control
                      placeholder='Appointment Type'
                      name='typeAppointment'
                      required
                      defaultValue={infoEdit?.typeAppointment || undefined}
                    />
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='meetingLocation'
                    label={
                      <span>
                        Meeting Location <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      placeholder='Meeting Location'
                      name='meetingLocation'
                      required
                      defaultValue={infoEdit?.meetingLocation || undefined}
                    />
                  </FloatingLabel>
                </Col>
                {/* two */}
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='date'
                    label={
                      <span>
                        When is the appointment <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      name='date'
                      type='date'
                      required
                      min={infoEdit ? undefined : new Date().toISOString().split('T')[0]}
                      defaultValue={infoEdit?.date || undefined}
                    />
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='duration'
                    label={
                      <span>
                        Duration(minutes) <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      placeholder='Duration(minutes)'
                      name='duration'
                      type='number'
                      required
                      min={1}
                      defaultValue={infoEdit?.duration || undefined}
                    />
                  </FloatingLabel>
                </Col>
                {/* two */}
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='assignedTo'
                    label={
                      <span>
                        Assigned To <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select
                      placeholder='Assigned To'
                      name='assignedTo'
                      required
                      defaultValue={infoEdit?.assignedTo || ''}
                    >
                      <option value=''>Please Select</option>
                      {members.map(({node}) => (
                        <option key={node.id} value={`${node.firstName} ${node.lastName}`}>
                          {node.firstName} {node.lastName}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                  <FloatingLabel
                    controlId='creditTo'
                    label={
                      <span>
                        Credit To <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                  >
                    <Form.Select
                      placeholder='Credit To'
                      name='creditTo'
                      required
                      defaultValue={infoEdit?.creditTo || ''}
                    >
                      <option value=''>Please Select</option>
                      {members.map(({node}) => (
                        <option key={node.id} value={`${node.firstName} ${node.lastName}`}>
                          {node.firstName} {node.lastName}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                {/* ONE */}
                <Col className='col-12 mb-4'>
                  <FloatingLabel controlId='comment' label='Comment' className='mb-3'>
                    <Form.Control
                      placeholder='Insert comment'
                      as='textarea'
                      rows={4}
                      type='textarea'
                      name='comment'
                      className='h-100'
                      required
                      defaultValue={infoEdit?.comment || undefined}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>

            <div className='d-flex algin-items-center justify-content-end gap-3 mt-4'>
              <Button type='button' variant='light' onClick={onBack}>
                Cancel
              </Button>
              <Button type='submit' variant='primary' disabled={loading}>
                {loading && <Spinner size='sm' animation='border' />}
                Save
              </Button>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </>
  )
}

import {Alert, Breadcrumb, Col, Dropdown, Offcanvas, Row} from 'react-bootstrap'
import './soldStyles.css'
import {useEffect, useState} from 'react'
import {PolicyForm} from './components/PolicyForm'
import {DocumentsTab} from './components/DocumentsTab'
import {RemindersTab} from './components/RemindersTab'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalNotes} from './components/ModalNotes'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {useLazyQuery, useMutation} from '@apollo/client'
import {GET_ONE_POLICY_API} from '../../gql/queries/policiesApiQueries'
import {IResOnePolicyApi, Node} from '../../types/policiesApi'
import {toast} from 'react-toastify'
import {LoadingOnePolicy} from './components/LoadingOnePolicy'
import moment from 'moment'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {TFormKeys} from '../policies/policiesTypes'
import {parseId} from '../../helpers'
import {CREATE_POLICY} from '../../gql/mutations/policyMutation'
import {IResCreatePolicyApi} from '../../types/policies'
import {BadgeStatePolicy} from './components/BadgeStatePolicy'
import {STATUS_POLICY} from '../../helpers/consts'
import {ModalCancelPolicy} from './components/ModalCancelPolicy'
import {ModalCompletePolicy} from './components/ModalCompletePolicy'
import {HolderInfoSide} from './components/HolderInfoSide'
import {ModalMemberIdPolicy} from './components/ModalMemberIdPolicy'

const NOT_FOUND = 'not-found'

type TTabs = 'Policy Form' | 'Documents' | 'Notes' | 'Reminders'
const TABS = [
  {name: 'Policy Form', icon: 'person-fill'},
  {name: 'Documents', icon: 'folder-fill'},
  {name: 'Notes', icon: 'pencil-square'},
  {name: 'Reminders', icon: 'arrow-repeat'},
] as const

const INIT_FORM = {
  policyName: '',
  applicants: '',
  members: '',
  dependents: '',
  stateDocs: '',
  paymentStatus: '',
  FFM: '',
  NPN: '',
  typeSale: '',
  effectiveDate: '',
  marketplaceId: '',
  memberId: '',
  CMSPlanId: '',
  planName: '',
  metalLevel: '',
  policyTotalCost: '',
  taxCredit: '',
  premium: '',
  typePolicy: '',
  stateEU: '',
  carrier: '',
  percentageValue: '',
  nominalValue: '',
  lead: '',
}

const validationSchema = Yup.object().shape({
  policyName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  metalLevel: Yup.string().required('Required'),
  carrier: Yup.string().required('Required'),
  effectiveDate: Yup.string().required('Required'),
  typePolicy: Yup.string().required('Required'),
  stateEU: Yup.string().required('Required'),
})

export const SoldLeadPage = () => {
  const [gqlCreateP, {data: dataCreate, loading: loadingCreate, error: errorCreate}] =
    useMutation<IResCreatePolicyApi>(CREATE_POLICY)

  const [gqlGetPolicy, {data: dataPolicy, loading: loadingPolicy, error: errorPolicy, refetch}] =
    useLazyQuery<IResOnePolicyApi>(GET_ONE_POLICY_API, {fetchPolicy: 'no-cache'})

  const navigate = useNavigate()

  const [data, setData] = useState<undefined | Node | 'not-found'>()

  const [tabs, setTabs] = useState<TTabs>('Policy Form')
  const {isOpen: isOpenNotes, onOpen: onOpenNotes, onClose: onCloseNotes} = useDisclourse()
  const {isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel} = useDisclourse()
  const {isOpen: isOpenComplete, onOpen: onOpenComplete, onClose: onCloseComplete} = useDisclourse()
  const {isOpen: isOpenMemberId, onOpen: onOpenMemberId, onClose: onCloseMemberId} = useDisclourse()
  const [show, setShow] = useState(false)
  const [searchParams] = useSearchParams()
  const lead = searchParams.get('lead')
  const policy = searchParams.get('policy')

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: INIT_FORM,
    onSubmit: (v) => {
      const policyData = {
        ...v,
        stateEU: v.stateEU ? Number(parseId(v.stateEU)) : undefined,
        typePolicy: Number(parseId(v.typePolicy)),
        policyTotalCost: Number(v.policyTotalCost),
        percentageValue: Number(v.percentageValue),
        nominalValue: Number(v.nominalValue),
        carrier: v.carrier ? Number(parseId(v.carrier)) : undefined,
        premium: Number(v.premium),
        metalLevel: Number(v.metalLevel),
        taxCredit: Number(v.taxCredit),
        memberId: v.memberId ? parseId(v.memberId.split('***')[0]) : undefined,
        lead: lead && Number(lead) ? Number(lead) : undefined,
      }
      console.log('policyInput', policyData)
      if (policy && Number(policy)) {
        console.log('vls policy', v)
      } else if (lead && Number(lead)) {
        gqlCreateP({
          variables: {
            policyData,
          },
        })
      }
    },
  })

  useEffect(() => {
    if (Number(lead)) {
      formik.setFieldValue('lead', lead)
      setData({} as Node)
      navigate(`/lead/${lead}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead])

  useEffect(() => {
    if (Number(policy)) {
      gqlGetPolicy({
        variables: {
          id: policy,
        },
      })
    }
  }, [gqlGetPolicy, policy])

  useEffect(() => {
    if (errorPolicy) toast.error(`Error: ${errorPolicy.message}`)
  }, [errorPolicy])

  useEffect(() => {
    if (!dataPolicy) return
    setData(dataPolicy.policiesById || NOT_FOUND)
    const {policiesById} = dataPolicy
    if (!policiesById) return
    formik.setValues({
      applicants: `${Number(policiesById.applicants)}`,
      carrier: policiesById.carrier?.id || '',
      CMSPlanId: policiesById.CMSPlanId || '',
      dependents: `${Number(policiesById.dependents)}`,
      effectiveDate: moment(policiesById.effectiveDate).format('YYYY-MM-DD'),
      FFM: policiesById.FFM || '',
      marketplaceId: policiesById.marketplaceId || '',
      memberId: policiesById.memberId || '',
      members: `${Number(policiesById.members)}`,
      metalLevel: `${Number(policiesById.metalLevel)}`,
      nominalValue: policiesById.nominalValue || '',
      NPN: policiesById.NPN || '',
      paymentStatus: policiesById.paymentStatus || '',
      percentageValue: `${Number(policiesById.percentageValue)}`,
      planName: policiesById.planName || '',
      policyName: policiesById.policyName || '',
      policyTotalCost: policiesById.policyTotalCost || '',
      premium: policiesById.premium || '',
      stateDocs: policiesById.stateDocs || '',
      stateEU: policiesById.stateEU?.id || '',
      taxCredit: policiesById.taxCredit || '',
      typePolicy: policiesById.typePolicy?.id || '',
      typeSale: policiesById.typeSale || '',
      lead: '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPolicy])

  useEffect(() => {
    if (errorCreate) {
      toast.error(`Error: ${errorCreate.message}`)
    }
  }, [errorCreate])

  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.createPolicy?.policy?.id) {
      toast.success('Policy added successfully')
      navigate(`/sold?policy=${parseId(dataCreate.createPolicy.policy.id)}`)
      setTabs('Policy Form')
      setData(undefined)
    } else if (dataCreate.createPolicy?.errors?.length > 0) {
      toast.error(`Error: ${dataCreate.createPolicy.errors[0]?.message || 'Something wrong'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreate])

  const handleChange = (field: TFormKeys, value: string) => {
    formik.setFieldValue(field, value)
  }
  const handleBlur = (field: TFormKeys) => {
    formik.setFieldTouched(field, true)
  }

  const handleSubmit = () => {
    formik.handleSubmit()
  }

  if (loadingPolicy || !data) {
    return <LoadingOnePolicy />
  }

  if (data === 'not-found') {
    return <div>Not found Policy</div>
  }

  console.log('data', data)

  return (
    <div>
      <header
        className='py-4 d-flex align-items-center justify-content-between'
        style={{width: '100%', marginTop: '-10px'}}
      >
        <Breadcrumb>
          <Breadcrumb.Item className='d-blok d-md-none' onClick={() => setShow(true)}>
            <span>
              <i className='bi bi-list fs-1' />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Quote</Breadcrumb.Item>
          <Breadcrumb.Item active>Quote {tabs}</Breadcrumb.Item>
        </Breadcrumb>

        <Dropdown>
          <Dropdown.Toggle variant='primary' size='sm' id='dropdown-basic'>
            Options
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              disabled={data.status !== STATUS_POLICY.PENDING}
              onClick={() => {
                onOpenComplete()
              }}
            >
              <span className='d-flex align-items-center gap-2'>
                <i className='bi bi-check-circle-fill fs-2' style={{color: 'green'}} />
                <span>Mark as Completed</span>
              </span>
            </Dropdown.Item>
            <Dropdown.Item
              disabled={data.status !== STATUS_POLICY.PENDING}
              onClick={() => {
                onOpenCancel()
              }}
            >
              <span className='d-flex align-items-center gap-2'>
                <i className='bi bi-x-circle-fill fs-2' style={{color: 'red'}} />
                <span>Cancel policy</span>
              </span>
            </Dropdown.Item>
            <Dropdown.Item
              disabled={data.status !== STATUS_POLICY.PENDING}
              onClick={() => {
                onOpenMemberId()
              }}
            >
              <span className='d-flex align-items-center gap-2'>
                <i className='bi bi-person-fill-up fs-2' style={{color: 'purple'}} />
                <span>{data.memberId ? 'Edit' : 'Add'} Member ID</span>
              </span>
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => {}}>Something else</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </header>
      {(lead || data.lead?.id) && (
        <div>
          <Alert>
            This policy originates from a lead.{' '}
            <Link
              to={`/lead/${lead ? lead : data.lead?.id ? parseId(data.lead.id) : '0'}`}
              style={{textDecoration: 'underline'}}
              target='_blank'
            >
              Click here
            </Link>{' '}
            for more information about the lead.
          </Alert>
        </div>
      )}
      <div className='mt-4'>
        <Row>
          <Col md={4} lg={4} sm={0} xl={3} className='d-none d-md-block'>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
                backgroundColor: '#fff',
                marginBottom: '20px',
                borderRadius: '4px',
                padding: '8px 10px',
              }}
            >
              <div className='kt-widget__content pt-0'>
                <div className='kt-heading kt-heading--sm custom-border-brand my-3'>
                  Policy Summary
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Agent:</span>
                  <span className='kt-widget__data'>
                    {data.user.firstName} {data.user.lastName}
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Internal code:</span>
                  <span className='kt-widget__data'>{policy}</span>
                </div>

                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Carrier:</span>
                  <span className='kt-widget__data'>{data?.carrier?.name || 'N/R'}</span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Effective:</span>
                  <span className='kt-widget__data' id='effective_label'>
                    {data.effectiveDate ? moment(data.effectiveDate).format('DD MMM, YY') : 'N/R'}
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>No. of applicants:</span>
                  <span className='kt-widget__data' id='no_applicants'>
                    {data.applicants ? Number(data.applicants) : 'N/R'}
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>State:</span>
                  <span className='kt-widget__data'>
                    <BadgeStatePolicy state={data.status} />
                  </span>
                </div>
                {data.status !== STATUS_POLICY.PENDING && (
                  <div className='kt-widget__info'>
                    <span className='kt-widget__label'>
                      {data.status === STATUS_POLICY.CANCELED
                        ? 'Canceled'
                        : data.status === STATUS_POLICY.COMPLETED
                        ? 'Completed'
                        : data.status}
                      {': '}
                    </span>
                    <span className='kt-widget__data'>
                      {moment(data.canceledDate || data.completedDate).format('DD MMM YYYY')}
                    </span>
                  </div>
                )}
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Documents status:</span>
                  <span className='kt-widget__data'>{data.stateDocs || 'N/R'} </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Payment status:</span>
                  <span className='kt-widget__data'>{data.paymentStatus}</span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Last update:</span>
                  <span className='kt-widget__data' id='last_update_label'>
                    {data.updated ? moment(data.updated).format('DD MMM, YY') : 'N/R'}
                  </span>
                </div>
                <div className='kt-heading kt-heading--sm custom-border-brand my-3'>
                  Holder Information
                </div>
                <HolderInfoSide members={data.memberPolicySet.edges} memberId={data.memberId} />
              </div>

              <div>
                <ul className='nav flex-column nav-pills w-100'>
                  {TABS.map((tabl) => (
                    <li className='nav-item w-100 py-2' key={tabl.name}>
                      <button
                        style={{textAlign: 'left'}}
                        className={`nav-link w-100 d-flex align-items-center gap-2 ${
                          tabl.name === tabs ? 'active' : ''
                        }`}
                        aria-current='page'
                        onClick={() => {
                          if (lead && Number(lead)) return
                          if (tabl.name === 'Notes') {
                            onOpenNotes()
                            return
                          }
                          setTabs(tabl.name)
                        }}
                      >
                        <span>
                          <i className={`bi bi-${tabl.icon} fs-4`} style={{color: 'inherit'}} />
                        </span>
                        <span>{tabl.name}</span>
                      </button>
                    </li>
                  ))}
                  {/* <li className='nav-item w-100 py-2'>
                    <button
                      style={{textAlign: 'left'}}
                      className='nav-link w-100 d-flex align-items-center gap-2'
                      aria-current='page'
                    >
                      <span>
                        <i className='bi bi-folder-fill fs-4' style={{color: 'inherit'}} />
                      </span>
                      <span>Documents</span>
                    </button>
                  </li>
                  <li className='nav-item w-100 py-2'>
                    <button
                      style={{textAlign: 'left'}}
                      className='nav-link w-100 d-flex align-items-center gap-2'
                      aria-current='page'
                    >
                      <span>
                        <i className='bi bi-pencil-square fs-4' style={{color: 'inherit'}} />
                      </span>
                      <span>Notes</span>
                    </button>
                  </li>
                  <li className='nav-item w-100 py-2'>
                    <button
                      style={{textAlign: 'left'}}
                      className='nav-link w-100 d-flex align-items-center gap-2'
                      aria-current='page'
                    >
                      <span>
                        <i className='bi bi-arrow-repeat fs-4' style={{color: 'inherit'}} />
                      </span>
                      <span>Reminders</span>
                    </button>
                  </li> */}
                </ul>
              </div>
            </div>
          </Col>
          <Col md={8} lg={8} sm={12} xl={9}>
            {tabs === 'Policy Form' && (
              <PolicyForm
                values={formik.values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                errors={formik.errors}
                touched={formik.touched}
                loadingCreate={loadingCreate}
                dataPolicy={data}
                refetch={refetch}
              />
            )}
            {tabs === 'Documents' && (
              <DocumentsTab
                refetch={refetch}
                idPolicy={data.id}
                members={data.memberPolicySet.edges}
                documents={data.attachmentsSet.edges}
              />
            )}
            {tabs === 'Reminders' && <RemindersTab />}
          </Col>
        </Row>
      </div>

      {isOpenNotes && <ModalNotes isOpen={isOpenNotes} onClose={onCloseNotes} idPolicy={data.id} />}

      <Offcanvas show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className='kt-widget__content pt-0 m-0'>
            <div className='kt-heading kt-heading--sm custom-border-brand my-3'>Policy Summary</div>
            <div className='kt-widget__info'>
              <span className='kt-widget__label'>Agent:</span>
              <span className='kt-widget__data'>
                {data.user.firstName} {data.user.lastName}
              </span>
            </div>
            <div className='kt-widget__info'>
              <span className='kt-widget__label'>Internal code:</span>
              <span className='kt-widget__data'>{policy}</span>
            </div>

            <div className='kt-widget__info'>
              <span className='kt-widget__label'>Carrier:</span>
              <span className='kt-widget__data'>{data?.carrier?.name || 'N/R'}</span>
            </div>
            <div className='kt-widget__info'>
              <span className='kt-widget__label'>Effective:</span>
              <span className='kt-widget__data' id='effective_label'>
                {data.effectiveDate ? moment(data.effectiveDate).format('DD MMM, YY') : 'N/R'}
              </span>
            </div>
            <div className='kt-widget__info'>
              <span className='kt-widget__label'>No. of applicants:</span>
              <span className='kt-widget__data' id='no_applicants'>
                {data.applicants ? Number(data.applicants) : 'N/R'}
              </span>
            </div>
            <div className='kt-widget__info'>
              <span className='kt-widget__label'>State:</span>
              <span className='kt-widget__data'>
                <BadgeStatePolicy state={data.status} />
              </span>
            </div>
            {data.status !== STATUS_POLICY.PENDING && (
              <div className='kt-widget__info'>
                <span className='kt-widget__label'>
                  {data.status === STATUS_POLICY.CANCELED
                    ? 'Canceled'
                    : data.status === STATUS_POLICY.COMPLETED
                    ? 'Completed'
                    : data.status}
                  {': '}
                </span>
                <span className='kt-widget__data'>
                  {moment(data.canceledDate || data.completedDate).format('DD MMM YYYY')}
                </span>
              </div>
            )}
            <div className='kt-widget__info'>
              <span className='kt-widget__label'>Documents status:</span>
              <span className='kt-widget__data'>{data.stateDocs || 'N/R'} </span>
            </div>
            <div className='kt-widget__info'>
              <span className='kt-widget__label'>Payment status:</span>
              <span className='kt-widget__data'>{data.paymentStatus}</span>
            </div>
            <div className='kt-widget__info'>
              <span className='kt-widget__label'>Last update:</span>
              <span className='kt-widget__data' id='last_update_label'>
                {data.updated ? moment(data.updated).format('DD MMM, YY') : 'N/R'}
              </span>
            </div>
            <div className='kt-heading kt-heading--sm custom-border-brand my-3'>
              Holder Information
            </div>
            <HolderInfoSide members={data.memberPolicySet.edges} memberId={data.memberId} />
          </div>

          <div>
            <ul className='nav flex-column nav-pills w-100'>
              {TABS.map((tabl) => (
                <li className='nav-item w-100 py-2' key={tabl.name}>
                  <button
                    style={{textAlign: 'left'}}
                    className={`nav-link w-100 d-flex align-items-center gap-2 ${
                      tabl.name === tabs ? 'active' : ''
                    }`}
                    aria-current='page'
                    onClick={() => {
                      if (tabl.name === 'Notes') {
                        onOpenNotes()
                        return
                      }
                      setTabs(tabl.name)
                    }}
                  >
                    <span>
                      <i className={`bi bi-${tabl.icon} fs-4`} style={{color: 'inherit'}} />
                    </span>
                    <span>{tabl.name}</span>
                  </button>
                </li>
              ))}
              {/* <li className='nav-item w-100 py-2'>
                    <button
                      style={{textAlign: 'left'}}
                      className='nav-link w-100 d-flex align-items-center gap-2'
                      aria-current='page'
                    >
                      <span>
                        <i className='bi bi-folder-fill fs-4' style={{color: 'inherit'}} />
                      </span>
                      <span>Documents</span>
                    </button>
                  </li>
                  <li className='nav-item w-100 py-2'>
                    <button
                      style={{textAlign: 'left'}}
                      className='nav-link w-100 d-flex align-items-center gap-2'
                      aria-current='page'
                    >
                      <span>
                        <i className='bi bi-pencil-square fs-4' style={{color: 'inherit'}} />
                      </span>
                      <span>Notes</span>
                    </button>
                  </li>
                  <li className='nav-item w-100 py-2'>
                    <button
                      style={{textAlign: 'left'}}
                      className='nav-link w-100 d-flex align-items-center gap-2'
                      aria-current='page'
                    >
                      <span>
                        <i className='bi bi-arrow-repeat fs-4' style={{color: 'inherit'}} />
                      </span>
                      <span>Reminders</span>
                    </button>
                  </li> */}
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {isOpenCancel && data.id && (
        <ModalCancelPolicy
          id={data.id}
          isOpen={isOpenCancel}
          onClose={onCloseCancel}
          refetch={refetch}
        />
      )}
      {isOpenComplete && data.id && (
        <ModalCompletePolicy
          id={data.id}
          isOpen={isOpenComplete}
          onClose={onCloseComplete}
          refetch={refetch}
        />
      )}
      {isOpenMemberId && data.id && (
        <ModalMemberIdPolicy
          id={data.id}
          isOpen={isOpenMemberId}
          onClose={onCloseMemberId}
          refetch={refetch}
          memberId={data.memberId}
        />
      )}
    </div>
  )
}

import {useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import Select from 'react-select'
import {TFormKeys} from '../policiesTypes'
import {useLazyQuery} from '@apollo/client'
import {GET_MEMBERS_ALL_USERS_FILTER} from '../../../gql/queries/memberPoliciesQuery'
import {toast} from 'react-toastify'
import {useDebounce} from '../../../../../_metronic/helpers'
import {IResAllUsers} from '../../oneUser/oneUserTypes'

export const PolicyHolderTab = ({
  onNext,
  handleChange,
}: {
  onNext: () => void
  handleChange: (field: TFormKeys, value: string) => void
  handleBlur: (field: TFormKeys) => void
}) => {
  const [gqlGetMembers, {data, loading, error}] = useLazyQuery<IResAllUsers>(
    GET_MEMBERS_ALL_USERS_FILTER
  )

  const [inputSelect, setInputSelect] = useState('')

  const valueDeb = useDebounce(inputSelect, 500)

  const [options, setOptions] = useState<{label: string; value: string}[]>([])
  const [tab, setTab] = useState<'new' | 'existing'>('existing')

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    setOptions(
      data.allUsers.edges.map(({node}) => ({
        label: `${node.firstName} ${node.lastName}`,
        value: node.id,
      }))
    )
  }, [data])

  useEffect(() => {
    if (!valueDeb) return
    gqlGetMembers({
      variables: {
        firstName_Icontains: valueDeb,
      },
    })
  }, [gqlGetMembers, valueDeb])

  return (
    <div className='ps-2'>
      <h6>Policyholder Information </h6>
      <div>
        <Button
          disabled
          onClick={() => setTab('new')}
          variant={tab === 'new' ? 'primary' : 'light'}
        >
          + New Contac
        </Button>
        <Button
          onClick={() => setTab('existing')}
          className='ms-2'
          variant={tab === 'existing' ? 'primary' : 'light'}
        >
          <i className='bi bi-search' />
          EXISTING CONTACT
        </Button>
      </div>
      {tab === 'new' && (
        <div className='mt-6'>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6 '>
              <label htmlFor='applicant'>
                Is this member an applicant? <small className='text-danger'>(required)</small>
              </label>
              <Form.Select
                // value={form.isApplicant}
                name='isApplicant'
                // onChange={(e) => onChange(e.target, 'policyholder')}
              >
                <option value='yes'>Yes</option>
                <option value='no'>No</option>
              </Form.Select>
            </div>
            <div className='col-lg-6 '>
              <label htmlFor='Preferredlanguage'>Preferred language</label>
              <Form.Select
                // value={form.isApplicant}
                name='Preferredlanguage'
                // onChange={(e) => onChange(e.target, 'policyholder')}
              >
                <option value='yes'>Spanish</option>
                <option value='no'>English</option>
              </Form.Select>
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <label htmlFor='first_name'>
                First name <small className='text-danger'>(required)</small>
              </label>
              <input
                type='text'
                name='firstName'
                // value={form.firstName}
                id='first_name'
                className='form-control '
                required
                // onChange={(e) => onChange(e.target, 'policyholder')}
              />
            </div>
            <div className='col-lg-6'>
              <label htmlFor='middle_name'>Middle name</label>
              <input
                type='text'
                name='middleName'
                id='middle_name'
                // value={form.middleName}
                // onChange={(e) => onChange(e.target, 'policyholder')}
                placeholder=''
                className='form-control '
              />
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <label htmlFor='last_name'>
                Last name <small className='text-danger'>(required)</small>
              </label>
              <input
                type='text'
                name='lastName'
                // value={form.lastName}
                // onChange={(e) => onChange(e.target, 'policyholder')}
                id='last_name'
                className='form-control '
                required
              />
            </div>
            <div className='col-lg-6'>
              <label htmlFor='last_name2'>Second last name</label>
              <input
                type='text'
                name='secondLastName'
                // value={form.secondLastName}
                // onChange={(e) => onChange(e.target, 'policyholder')}
                id='last_name2'
                defaultValue=''
                placeholder=''
                className='form-control '
              />
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <label style={{width: '100%'}} htmlFor='dob' className='d-flex align-items-center'>
                <span>Date of birth</span>
                <span style={{display: 'inline-block', textAlign: 'right', flex: 1}}>Age: 33</span>
              </label>
              {/* <InputGroup className='mb-3'>
                  <InputGroup.Text id='basic-addon1'>
                    <i className='la la-calendar-check-o' />
                  </InputGroup.Text> */}
              <Form.Control
                type='date'
                id='dob'
                name='dateBirth'
                // value={form.dateBirth}
                // onChange={(e) => onChange(e.target, 'policyholder')}
              />
              {/* </InputGroup> */}
            </div>
            <div className='col-lg-6'>
              <label htmlFor='gender'>
                Gender <small className='text-danger'>(required)</small>
              </label>
              <Form.Select
                aria-label='Default select example'
                name='gender'
                // value={form.gender}
                // onChange={(e) => onChange(e.target, 'policyholder')}
              >
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
                <option value='Other'>Other</option>
              </Form.Select>
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <div className='col-lg-6'>
              <label htmlFor='phone'>Phone</label>
              <input
                type='tel'
                name='phone'
                // value={form.phone}
                // onChange={(e) => onChange(e.target, 'policyholder')}
                id='phone'
                className='form-control  phone_mask'
                im-insert='true'
              />
            </div>
            <div className='col-lg-6'>
              <label htmlFor='email'>Email address</label>
              <input
                type='email'
                name='email'
                // value={form.email}
                // onChange={(e) => onChange(e.target, 'policyholder')}
                id='email'
                className='form-control '
                autoComplete='off'
              />
            </div>
          </div>
          <div className='mb-3 form-group form-group-md row'>
            <Form.Group>
              <Form.Label className='d-block'>Select any that apply</Form.Label>
              <Form.Check inline label='Tobacco user' />
              <Form.Check inline label='Pregnant' />
            </Form.Group>
          </div>
        </div>
      )}

      {tab === 'existing' && (
        <div className='mt-6'>
          <Form.Group>
            <Form.Label>Find and select a contact</Form.Label>
            <Select
              isLoading={loading}
              loadingMessage={() => 'loading members'}
              placeholder='Search and select a member...'
              options={options}
              inputValue={inputSelect}
              onInputChange={setInputSelect}
              onChange={(e) => console.log('e', e)}
            />
            <Form.Text style={{fontSize: '12px'}}>
              Search by name, email address, phone number or last 4 digits of SSN
            </Form.Text>
          </Form.Group>
        </div>
      )}

      <div className='py-6'>
        <div className='py-6 my-6 d-flex align-items-center justify-content-end'>
          <Button onClick={onNext}>NEXT STEP</Button>
        </div>
      </div>
    </div>
  )
}

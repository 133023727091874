/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout} from '../modules/auth'
import {App} from '../App'
import {LoginPage} from '../tfi/pages/authentication/LoginPage'
import {useGetUser} from '../tfi/store/userStore'

const AppRoutes: FC = () => {
  const currentUser = useGetUser((s) => s.user)
  const location = useLocation()

  return (
    <Routes>
      <Route element={<App />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
        {currentUser ? (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
            {/* <Route index element={<Navigate to='/dashboard' />} /> */}
          </>
        ) : (
          <>
            <Route path='/login' element={<LoginPage />} />
            <Route
              path='*'
              element={<Navigate to='/login' replace state={{prevUrl: location.pathname}} />}
            />
          </>
        )}
      </Route>
    </Routes>
  )
}

export {AppRoutes}

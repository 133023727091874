import {useLazyQuery} from '@apollo/client'
import {GET_MARKET_PLACE_2} from '../../gql/queries/leadsQuery'
import {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {IResultsJson} from '../oneLead/oneleadTypes'
import {useParams} from 'react-router-dom'
import {validateJsonQuote} from '../../helpers'
import {AccordionForm} from './components/AccordionForm'
import {IForm} from './oneQuoteTypes'
import {ResultsCards} from './components/ResultsCards'
import {LoadingOneAddQuote} from './components/LoadingOneAddQuote'

interface IResTest {
  marketplace2: string
}

export const OneAddQuotePage = () => {
  const [gqlGetMarket, {data, loading, error}] = useLazyQuery<IResTest>(GET_MARKET_PLACE_2, {
    fetchPolicy: 'no-cache',
  })

  const {id} = useParams()

  const [formData, setFormData] = useState<IForm>({
    queryType: 'BUSCAR_PLANES',
    year: 2024,
    householdIncome: '0', // cahnge
    aptcEligible: false, // cahnge
    hasMec: false, // cahnge
    dob: '', // cahnge
    isPregnant: false, // cahnge
    isParent: false, // cahnge
    usesTobacco: false, // cahnge
    gender: 'Male', // cahnge
    hasMarriedCouple: false, // cahnge
    market: 'Individual',
    countyfips: '17033',
    state: 'IL',
    zipCode: '62466',
    limit: 10,
    offset: 0,
    order: 'asc', // cahnge
    utilizationLevel: 'Low',
  })

  const [errorData, setErrorData] = useState(false)

  const [result, setResult] = useState<null | IResultsJson>(null)

  const moreArr = useRef(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [arrOffset, setArrOffset] = useState<number[]>([])
  const [infoPagination, setInfoPagination] = useState({hasNext: true, offset: 0, initialOffset: 0})

  useEffect(() => {
    if (!id) return

    try {
      const str = atob(id)
      const obj = JSON.parse(str) as IForm
      const isOk = validateJsonQuote(obj)
      if (!isOk) {
        return setErrorData(true)
      }
      setFormData((p) => ({...p, ...obj}))
      gqlGetMarket({
        variables: {
          ...obj,
          householdIncome: obj.householdIncome.replaceAll(',', ''),
          dob: obj?.dob || undefined,
        },
      })
    } catch (error) {
      console.log('error', error)
      setErrorData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.marketplace2) {
      const json = JSON.parse(data.marketplace2) as IResultsJson
      if (json.error) {
        toast.error('Error: Not found Quote')
        return
      }
      setInfoPagination((p) => ({
        hasNext: json.plans.length > 0 && moreArr.current,
        offset: moreArr.current ? p.offset + json.plans.length : p.offset,
        initialOffset: moreArr.current ? json.plans.length : p.initialOffset,
      }))
      if (json.plans.length === 0) moreArr.current = false
      if (json.plans.length > 0 && moreArr.current) setArrOffset((p) => p.concat(p.length + 1))
      setResult(json)
    }
  }, [data])

  const loadMoreQuotes = (pag: number) => {
    gqlGetMarket({
      variables: {
        ...formData,
        householdIncome: formData.householdIncome.replaceAll(',', ''),
        dob: formData?.dob || undefined,
        offset: infoPagination.offset * pag - infoPagination.offset,
      },
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    gqlGetMarket({
      variables: {
        ...formData,
        householdIncome: formData.householdIncome.replaceAll(',', ''),
        dob: formData.dob || undefined,
      },
    })
  }

  const plusPage = () => {
    setCurrentPage((p) => p + 1)
  }

  const updatePage = (n: number) => {
    setCurrentPage(n)
  }

  if (!id) {
    return <div>NOT FOUND</div>
  }

  if (errorData) {
    return <div>Error</div>
  }

  if (loading) return <LoadingOneAddQuote arrOffset={arrOffset} currentPage={currentPage} />

  return (
    <div>
      <AccordionForm
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
        setFormData={setFormData}
      />
      {result && (
        <ResultsCards
          result={result}
          arrOffset={arrOffset}
          infoPagination={infoPagination}
          loadMoreQuotes={loadMoreQuotes}
          currentPage={currentPage}
          plusPage={plusPage}
          updatePage={updatePage}
        />
      )}
    </div>
  )
}

import {gql} from '@apollo/client'

export const ADD_MEMBERS = gql`
  mutation createUserMutation($userData: UserInput!) {
    createUserMutation(userData: $userData) {
      user {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

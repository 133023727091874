import {useQuery} from '@apollo/client'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {ListGroup, Spinner} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {IResGroups} from '../../../types/assignmentGroups'
import {GET_ALL_GROUPS} from '../../../gql/queries/asiignmentGruopQuery'
import {toast} from 'react-toastify'
import {getColorRamdomBoots} from '../../../helpers'
/* const FILTERS_NAME_ARR = [
  {name: 'Agentes Independientes', quantity: 0, color: 'primary'},
  {name: 'Call Center USA', quantity: 7, color: 'dark'},
  {name: 'Contratos', quantity: 3, color: 'light'},
  {name: 'Grupo Roberto Ramirez', quantity: 4, color: 'success'},
  {name: 'Operaciones', quantity: 5, color: 'warning'},
  {name: 'Procesamiento', quantity: 3, color: 'success'},
  {name: 'Prueba comisiones', quantity: 3, color: 'warning'},
  {name: 'Support', quantity: 11, color: 'dark'},
] */
export const AsideUserFilters: React.FC<{
  tabFilters: string
  setTabFilters: Dispatch<SetStateAction<string>>
  onOpenManage: () => void
}> = ({tabFilters, setTabFilters, onOpenManage}) => {
  const {
    data: dataGroups,
    loading: loadingGroups,
    error: errorGroups,
  } = useQuery<IResGroups>(GET_ALL_GROUPS, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (errorGroups) toast.error('Error: ' + errorGroups.message)
  }, [errorGroups])

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
        backgroundColor: '#fff',
        marginBottom: '20px',
        borderRadius: '4px',
        padding: '8px 10px',
      }}
    >
      <div
        style={{
          borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
          paddingBottom: '7px',
        }}
      >
        <h5 style={{fontSize: '13px'}}>New agent application form URL:</h5>
        <a href='#/'>coming soon</a>
      </div>

      <div className='my-4'>
        <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
          <span className='fw-bold'>Active users</span>
          <span className='badge badge-success'>90</span>
        </div>
        <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
          <span className='fw-bold'>Inctive users</span>
          <span className='badge badge-danger'>10</span>
        </div>
        <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
          <span className='fw-bold'>Candidates</span>
          <span className='badge badge-primary'>0</span>
        </div>
      </div>

      <div>
        <div
          style={{
            borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
          }}
          className='d-flex align-items-center justify-content-between'
        >
          <h5>User Groups</h5>
          <Link className='btn btn-link' to='/assignment-groups'>
            <i className='bi bi-pencil-square' style={{color: 'inherit'}} /> Manage
          </Link>
        </div>
        <ListGroup className='mt-5' variant='flush'>
          <ListGroup.Item
            action
            variant={tabFilters === 'All Users' ? 'primary' : 'light'}
            className='my-2 py-4'
            onClick={() => setTabFilters('All Users')}
          >
            <i className='bi bi-person-fill fs-3' style={{color: 'inherit'}} /> All Users
          </ListGroup.Item>
          {!dataGroups || loadingGroups ? (
            <div className='d-flex flex-center' style={{height: '220px'}}>
              <Spinner animation='border' />
            </div>
          ) : (
            dataGroups?.allGroupAssignments.edges.map(({node: lis}, idx) => (
              <ListGroup.Item
                key={lis.id}
                action
                variant={tabFilters === lis.id ? 'primary' : 'light'}
                className='my-1 py-4 d-flex align-items-center justify-content-between'
                onClick={() => setTabFilters(lis.id)}
              >
                <span className='d-flex align-items-center justify-content-between gap-1'>
                  <i className='bi bi-file-person-fill fs-3' style={{color: 'inherit'}} />{' '}
                  {lis.name}
                </span>
                <span className={`badge badge-${getColorRamdomBoots(idx)}`}>
                  {lis.user.edges.length}
                </span>
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </div>
    </div>
  )
}

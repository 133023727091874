import {gql} from '@apollo/client'

export const ADD_EDIT_REASON = gql`
  mutation reasonLeadLossCreateupdateMutation(
    $input: reason_lead_loss_createUpdate_mutationInput!
  ) {
    reasonLeadLossCreateupdateMutation(input: $input) {
      reasonLeadLoss {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

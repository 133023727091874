import {Button, Spinner} from 'react-bootstrap'

export const LoadingOneCarrier = () => {
  return (
    <div>
      <header className='d-flex align-items-center flex-column flex-md-row justify-content-between'>
        <h3 className='placeholder-glow' style={{width: '200px'}}>
          <span className='placeholder col-12'></span>
        </h3>
        <div className='d-flex align-items-center  gap-3 flex-column flex-md-row'>
          <Button className='w-100 w-md-auto' disabled variant='info'>
            Excel base
          </Button>
          <Button className='w-100 w-md-auto' variant='success' disabled>
            Upload Excel
          </Button>
          <Button className='w-100 w-md-auto' variant='primary' disabled>
            <i className='bi bi-plus fs-3' />
            Commission
          </Button>
        </div>
      </header>
      <div
        className='d-flex flex-center card mt-8'
        style={{height: '35vh', backgroundColor: 'white'}}
      >
        <Spinner animation='border' />
      </div>
    </div>
  )
}

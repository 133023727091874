import {Modal, Button, Spinner} from 'react-bootstrap'
import {parseId} from '../../../helpers'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {IResSummCallCounter} from '../../../types/leads'
import {CALL_COUNTER} from '../../../gql/mutations/leadMutations'
import {useMutation} from '@apollo/client'

interface IModalSumCalls {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  id: string
}

export const ModalSumCall = ({isOpen, onClose, id, refetch}: IModalSumCalls) => {
  const [gqlCall, {data, loading, error}] = useMutation<IResSummCallCounter>(CALL_COUNTER)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.sumCallCounter?.success) {
      toast.success('Call made successfully')
      refetch()
      onClose()
    } else if (data.sumCallCounter?.errors && data.sumCallCounter?.errors.length > 0) {
      toast.error(`Error: ${data.sumCallCounter?.errors[0]?.message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleSendCall = () => {
    gqlCall({
      variables: {
        leadId: parseId(id),
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Sum Call</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-center h-100 flex-column'>
          <h2 className='fs-1'>Are you sure you want to call lead?</h2>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant='light'>
          Cancel
        </Button>
        <Button onClick={handleSendCall} variant='primary' disabled={loading}>
          {loading && <Spinner animation='border' className='me-2' size='sm' />}
          Call
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

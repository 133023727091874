import Select from 'react-select'
import {EdgeMemberPolicy, IEdgesAttachments, INodeAttachments} from '../../../types/policiesApi'
import {useDisclourse} from '../../../hooks/useDisclourse'
import {ModalAddDocsPolicy} from './documents/ModalAddDocsPolicy'
import moment from 'moment'
import {RenderFilePreview} from './documents/RenderFilePreview'
import {useState} from 'react'
import {ModalEditDoc} from './documents/ModalEditDoc'
import {ModalDeleteDoc} from './documents/ModalDeleteDoc'

interface IDocProps {
  refetch: () => void
  idPolicy: string
  members: EdgeMemberPolicy[]
  documents: IEdgesAttachments[]
}

export const DocumentsTab: React.FC<IDocProps> = ({idPolicy, members, refetch, documents}) => {
  const {onOpen, isOpen, onClose} = useDisclourse()
  const {onOpen: onOpenEdit, isOpen: isOpenEdit, onClose: onCloseEdit} = useDisclourse()
  const {onOpen: onOpenDelete, isOpen: isOpenDelete, onClose: onCloseDelete} = useDisclourse()

  const [infoDoc, setInfoDoc] = useState<null | INodeAttachments>(null)

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
          backgroundColor: '#fff',
          marginBottom: '20px',
          borderRadius: '4px',
          padding: '8px 10px',
        }}
      >
        <div className='pt-0'>
          <div className='kt-heading pb-2 custom-border-brand d-flex align-items-center justify-content-between'>
            <span>Client Documents</span>
            <div className='pull-right'>
              <div className='btn-toolbar gap-2'>
                <button
                  type='button'
                  className='btn btn-sm btn-sm btn-primary'
                  onClick={() => {
                    onOpen()
                    setInfoDoc(null)
                  }}
                  // onclick="document.getElementById('uppy_upload_btn').click();"
                >
                  <i className='fa fa-upload' /> Upload Files
                </button>
                <button
                  type='button'
                  className='btn btn-sm ml-2'
                  style={{backgroundColor: '#d0e5fe', color: '#3e97ff'}}
                >
                  <i className='fa fa-redo' style={{color: 'inherit'}} /> Refresh
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <div className='form-group form-group-sm'>
                <label htmlFor='contact_documents_datatable_search'> Search document:</label>
                <div style={{position: 'relative'}}>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Type here to search...'
                    id='contact_documents_datatable_search'
                    name='contact_documents_datatable_search'
                  />
                  <span style={{position: 'absolute', right: '4px', top: '12px'}}>
                    <span>
                      <i className='bi bi-search fs-2' style={{color: 'inherit'}} />
                    </span>
                  </span>
                </div>
              </div>
              <div className='form-group'>
                <div className='kt-checkbox-list'>
                  <label className='kt-checkbox kt-checkbox--dark'>
                    <input
                      type='checkbox'
                      name='contact_documents_datatable_expired'
                      id='contact_documents_datatable_expired'
                    />{' '}
                    Show only expired documents
                    <span />
                  </label>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group form-group-sm'>
                <label htmlFor='contact_documents_datatable_tag'> Filter by tag:</label>
                <Select
                  styles={{
                    control: (base, prop) => ({...base, padding: '3px'}),
                  }}
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group form-group-sm'>
                <label htmlFor='contact_documents_datatable_family_member'>Filter by member:</label>
                <Select
                  styles={{
                    control: (base, prop) => ({...base, padding: '3px'}),
                  }}
                />
              </div>
            </div>
          </div>
          {/*begin: Datatable */}
          <div
            id='contact_documents_datatable_wrapper'
            className='dataTables_wrapper dt-bootstrap4 no-footer'
          >
            <div className='row'>
              <div className='col-sm-12 col-lg-6 '>
                <div
                  className='d-flex align-items-center gap-1 mt-2'
                  id='contact_documents_datatable_length'
                >
                  <label className='d-flex align-items-center gap-1'>
                    Show{' '}
                    <select
                      name='contact_documents_datatable_length'
                      aria-controls='contact_documents_datatable'
                      className='custom-select custom-select-sm form-control form-control-sm'
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={-1}>All</option>
                    </select>{' '}
                    entries
                  </label>
                </div>
              </div>

              <div className='col-sm-12 col-lg-6 '>
                <div
                  className='d-flex align-items-center justify-content-end w-100'
                  id='contact_documents_datatable_paginate'
                >
                  <ul className='pagination'>
                    <li
                      className='paginate_button page-item previous disabled'
                      id='contact_documents_datatable_previous'
                    >
                      <button
                        aria-controls='contact_documents_datatable'
                        data-dt-idx={0}
                        tabIndex={0}
                        className='page-link'
                      >
                        <i className='la la-angle-left' />
                      </button>
                    </li>
                    <li
                      className='paginate_button page-item next disabled'
                      id='contact_documents_datatable_next'
                    >
                      <button
                        aria-controls='contact_documents_datatable'
                        data-dt-idx={1}
                        tabIndex={0}
                        className='page-link'
                      >
                        <i className='la la-angle-right' />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 px-4' style={{overflowX: 'auto'}}>
                <table
                  className='table table-hover dataTable no-footer'
                  id='contact_documents_datatable'
                  aria-describedby='contact_documents_datatable_info'
                  role='grid'
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  <thead>
                    <tr role='row'>
                      <th
                        className='sorting_disabled'
                        rowSpan={1}
                        colSpan={1}
                        style={{width: 30}}
                        aria-label='&nbsp;'
                      >
                        &nbsp;
                      </th>
                      <th
                        className='fw-bold sorting'
                        tabIndex={0}
                        aria-controls='contact_documents_datatable'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='File name: activate to sort column ascending'
                      >
                        File name
                      </th>
                      <th
                        className='fw-bold sorting_desc'
                        tabIndex={0}
                        aria-controls='contact_documents_datatable'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Uploaded by: activate to sort column ascending'
                      >
                        Uploaded by
                      </th>
                      <th
                        className='fw-bold sorting'
                        tabIndex={0}
                        aria-controls='contact_documents_datatable'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Belongs to: activate to sort column ascending'
                      >
                        Belongs to
                      </th>
                      <th
                        className='fw-bold sorting'
                        tabIndex={0}
                        aria-controls='contact_documents_datatable'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Expiration: activate to sort column ascending'
                      >
                        Expiration
                      </th>
                      <th
                        className='fw-bold sorting'
                        tabIndex={0}
                        aria-controls='contact_documents_datatable'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Tag: activate to sort column ascending'
                      >
                        Tag
                      </th>
                      <th
                        className='fw-bold text-right sorting_disabled'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Actions'
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map(({node}) => (
                      <tr key={node.id}>
                        <td>
                          <RenderFilePreview file={node.attachments} />
                        </td>
                        <td
                          style={{
                            textAlign: 'left',
                            verticalAlign: 'middle',
                          }}
                        >
                          {node.name}.
                          {node.attachments.split('?')[0].split('.')?.pop()?.toLowerCase() || ''}
                          {/* {node.attachments.split('/').pop()?.split?.('?')?.[0].split('.')[1]} */}
                        </td>
                        <td>
                          <div>
                            <p className='m-0 p-0'>
                              <span className='fw-bold'>User:</span> N/R
                            </p>
                            <small className='m-0 p-0'>
                              {moment(node.created).format('MMM DD, YYYY hh:mm A')}
                            </small>
                          </div>
                        </td>
                        <td>
                          {node.memberPolicy ? (
                            <span>
                              {node.memberPolicy.firstName} {node.memberPolicy.lastName}
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </td>
                        <td>
                          {node.expirationDate ? (
                            moment(node.expirationDate).format('DD MMM YYYY')
                          ) : (
                            <span></span>
                          )}
                        </td>
                        <td>
                          {node.documentTag ? (
                            <span>{node.documentTag.tagName}</span>
                          ) : (
                            <span></span>
                          )}
                        </td>
                        <td>
                          <div className='flex align-items-center gap-x-1'>
                            <button
                              onClick={() => window.open(node.attachments, '_blank')}
                              className='btn-reset'
                              title='View'
                            >
                              <i className='bi bi-eye text-primary fs-2' />
                            </button>
                            <button
                              className='btn-reset'
                              title='Edit'
                              onClick={() => {
                                onOpenEdit()
                                setInfoDoc(node)
                              }}
                            >
                              <i className='bi bi-pencil text-warning fs-2' />
                            </button>
                            <button
                              className='btn-reset'
                              title='Delete'
                              onClick={() => {
                                onOpenDelete()
                                setInfoDoc(node)
                              }}
                            >
                              <i className='bi bi-trash text-danger fs-2' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {documents.length === 0 && (
                      <tr className='odd'>
                        <td valign='top' colSpan={7} className='dataTables_empty'>
                          <div
                            className='d-flex align-items-center justify-content-center flex-column'
                            style={{padding: '3% 0', backgroundColor: '#f0f3ff'}}
                          >
                            <svg
                              style={{width: 50, height: 50}}
                              xmlns='http://www.w3.org/2000/svg'
                              xmlnsXlink='http://www.w3.org/1999/xlink'
                              width='24px'
                              height='24px'
                              viewBox='0 0 24 24'
                              version='1.1'
                              className='kt-svg-icon mb-3'
                            >
                              <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                                <rect id='bound' x={0} y={0} width={24} height={24} />
                                <path
                                  d='M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z'
                                  id='Combined-Shape'
                                  fill='#000000'
                                  opacity='0.3'
                                />
                                <path
                                  d='M8.63657261,16.4632487 C7.65328954,15.8436137 7,14.7480988 7,13.5 C7,11.5670034 8.56700338,10 10.5,10 C12.263236,10 13.7219407,11.3038529 13.9645556,13 L15,13 C16.1045695,13 17,13.8954305 17,15 C17,16.1045695 16.1045695,17 15,17 L10,17 C9.47310652,17 8.99380073,16.7962529 8.63657261,16.4632487 Z'
                                  id='Combined-Shape'
                                  fill='#000000'
                                />
                              </g>
                            </svg>
                            <h5 className='kt-font-dark'>
                              There are no documents uploaded for this contact.
                            </h5>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-5'>
                <div className='dataTables_info'>Showing 0 to 0 of 0 entries</div>
              </div>
              <div className='col-sm-12 col-md-7'>
                <div className='dataTables_paginate paging_simple_numbers'>
                  <ul className='pagination'>
                    <li className='paginate_button page-item previous disabled'>
                      <a
                        href='/#'
                        aria-controls='contact_documents_datatable'
                        data-dt-idx={0}
                        tabIndex={0}
                        className='page-link'
                      >
                        <i className='la la-angle-left' />
                      </a>
                    </li>
                    <li className='paginate_button page-item next disabled'>
                      <a
                        href='/#'
                        aria-controls='contact_documents_datatable'
                        data-dt-idx={1}
                        tabIndex={0}
                        className='page-link'
                      >
                        <i className='la la-angle-right' />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*end: Datatable */}
        </div>
      </div>

      {isOpen && (
        <ModalAddDocsPolicy
          id={idPolicy}
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
          members={members}
        />
      )}

      {isOpenEdit && infoDoc && (
        <ModalEditDoc
          infoDoc={infoDoc}
          id={idPolicy}
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          refetch={refetch}
          members={members}
        />
      )}

      {isOpenDelete && infoDoc && (
        <ModalDeleteDoc
          infoDoc={infoDoc}
          id={idPolicy}
          isOpen={isOpenDelete}
          onClose={onCloseDelete}
          refetch={refetch}
        />
      )}
    </>
  )
}

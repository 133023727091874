import {Button, Col, FloatingLabel, Form, InputGroup, Offcanvas, Row} from 'react-bootstrap'

export const FormSendMail: React.FC<{onBack: () => void}> = ({onBack}) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
  }
  return (
    <>
      <Offcanvas.Header closeButton className='bg-light'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Offcanvas.Title className='d-flex align-items-center gap-4'>Send SMS</Offcanvas.Title>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form onSubmit={handleSubmit}>
          <div className='w-100 h-100'>
            <div>
              <Row>
                <Col className='col-12 mb-4'>
                  <FloatingLabel
                    controlId='form'
                    label={
                      <span>
                        Form <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control placeholder='Form' name='form' />
                  </FloatingLabel>
                </Col>
                <Col className='col-12 mb-4'>
                  <FloatingLabel
                    controlId='to'
                    label={
                      <span>
                        To <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control placeholder='to' name='to' />
                  </FloatingLabel>
                </Col>
                <Col className='col-12 mb-4'>
                  <InputGroup className='mb-3'>
                    <FloatingLabel
                      controlId='subject'
                      label={
                        <span>
                          Subject <span style={{color: 'red'}}>*</span>
                        </span>
                      }
                    >
                      <Form.Control name='subject' />
                    </FloatingLabel>
                    <InputGroup.Text id='basic-addon2'>Cc</InputGroup.Text>
                    <InputGroup.Text id='basic-addon3'>Bcc</InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col className='col-12 mb-4'>
                  <FloatingLabel
                    controlId='message'
                    label={
                      <span>
                        Message <span style={{color: 'red'}}>*</span>
                      </span>
                    }
                    className='mb-3'
                  >
                    <Form.Control
                      placeholder='Message'
                      as='textarea'
                      rows={6}
                      type='textarea'
                      name='message'
                      className='h-100'
                      defaultValue={`\n\n\n\nCarlos Namtrik\nThe Future Insurance`}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
            <div className='d-flex algin-items-center justify-content-end gap-3 mt-4'>
              <Button type='button' variant='light' onClick={onBack}>
                Cancel
              </Button>
              <Button type='submit' variant='primary' disabled>
                Send
              </Button>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </>
  )
}

import {useQuery} from '@apollo/client'
import {GET_ALL_PIPELINES} from '../../gql/queries/pipelines'
import {forwardRef, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {IResPipelines} from '../../types/pipelines'
import {Button, Dropdown, Spinner} from 'react-bootstrap'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalAddPipeline} from './components/ModalAddPipeline'
import {format} from 'date-fns'
import {Link} from 'react-router-dom'
import {parseId} from '../../helpers'
import {ModalDuplicatePipeline} from './components/ModalDuplicatePipeline'
import {ModalDeletePipeline} from './components/ModalDeletePipeline'

const CustomToggle = forwardRef<
  HTMLAnchorElement,
  {children: JSX.Element; onClick: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void}
>(({children, onClick}, ref) => (
  <a
    href='/#'
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </a>
))

export const PipelinesPage = () => {
  const {data, loading, error, refetch} = useQuery<IResPipelines>(GET_ALL_PIPELINES, {
    fetchPolicy: 'no-cache',
  })

  const {isOpen, onClose, onOpen} = useDisclourse()
  const {
    isOpen: isOpenDuplicate,
    onClose: onCloseDuplicate,
    onOpen: onOpenDuplicate,
  } = useDisclourse()
  const {
    isOpen: isOpenDeletePipe,
    onClose: onCloseDeletePipe,
    onOpen: onOpenDeletePipe,
  } = useDisclourse()

  const [infoEdit, setInfoEdit] = useState<null | undefined | {id: string; name: string}>(null)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data)
    return (
      <div className='m-4'>
        <h3 className='display-6'>Lead Pipelines</h3>
        <div className='container py-5'>
          <div
            className='d-flex align-items-center justify-content-center flex-column gap-2'
            style={{height: '30vh'}}
          >
            <Spinner animation='border' />
          </div>
        </div>
      </div>
    )

  if (data.allPipelines.edges.length === 0) {
    return (
      <>
        <div className='m-4'>
          <h3 className='display-6'>Lead Pipelines</h3>
          <div className='container py-5'>
            <div
              className='d-flex align-items-center justify-content-center flex-column gap-2'
              style={{height: '30vh'}}
            >
              <h5>There are no pipelines registered</h5>
              <Button onClick={onOpen}>Add pipeline</Button>
            </div>
          </div>
        </div>
        <ModalAddPipeline isOpen={isOpen} onClose={onClose} refetch={refetch} />
      </>
    )
  }

  return (
    <>
      <div className='m-4'>
        <div className='d-flex align-items-center justify-content-between'>
          <h3 className='display-6'>Lead Pipelines</h3>
          <Button
            onClick={() => {
              onOpen()
              setInfoEdit(null)
            }}
          >
            Add pipeline
          </Button>
        </div>
        <div className='container py-5'>
          <div className='row'>
            <div className='col mx-auto'>
              <ul className='timeline'>
                {data.allPipelines.edges.map(({node}) => (
                  <li
                    key={node.id}
                    className='timeline-item bg-white rounded ms-4 p-4 shadow mb-2 flex-column'
                  >
                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <div style={{overflowX: 'auto'}}>
                        <div className='timeline-arrow'></div>
                        <h2 className='h5 mb-0'>{node.name}</h2>
                        <span className='small text-gray'>
                          <i className='fa fa-clock-o mr-1'></i>
                          {format(new Date(node.created), 'PPP')}
                        </span>
                        <p
                          className='text-small mt-2 font-weight-light d-flex align-items-center gap-2'
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {node.stagePipelineSet.edges.length > 0 ? (
                            node.stagePipelineSet.edges
                              .sort((a, b) => a.node.order - b.node.order)
                              .map(({node: nodeStage}, i) => (
                                <span
                                  key={nodeStage.id}
                                  className='d-inline-flex align-items-center gap-2'
                                >
                                  {nodeStage.name}{' '}
                                  {i + 1 !== node.stagePipelineSet.edges.length ? (
                                    <>
                                      <i className='bi bi-arrow-right-short text-dark fs-1' />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              ))
                          ) : (
                            <span className='ms-4'>
                              <i className='bi bi-exclamation-octagon' /> There are no stages
                            </span>
                          )}
                        </p>
                      </div>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle variant='success' id='dropdown-basic' as={CustomToggle}>
                            <i className='bi bi-three-dots fs-2 text-dark' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              as={Link}
                              to={`/stages/${parseId(node.id)}`}
                              className='d-flex align-items-center gap-1'
                            >
                              <i className='bi bi-pencil-square' />
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              className='d-flex align-items-center gap-1'
                              onClick={() => {
                                onOpenDuplicate()
                                setInfoEdit({id: node.id, name: node.name})
                              }}
                            >
                              <i className='bi bi-clipboard' />
                              Duplicate
                            </Dropdown.Item>
                            <Dropdown.Item
                              className='d-flex align-items-center gap-1'
                              onClick={() => {
                                onOpenDeletePipe()
                                setInfoEdit({id: node.id, name: node.name})
                              }}
                            >
                              <i className='bi bi-trash' />
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <ModalAddPipeline isOpen={isOpen} onClose={onClose} refetch={refetch} infoEdit={infoEdit} />
      )}

      {isOpenDuplicate && infoEdit && (
        <ModalDuplicatePipeline
          isOpen={isOpenDuplicate}
          onClose={onCloseDuplicate}
          refetch={refetch}
          infoEdit={infoEdit}
        />
      )}
      {isOpenDeletePipe && infoEdit && (
        <ModalDeletePipeline
          isOpen={isOpenDeletePipe}
          onClose={onCloseDeletePipe}
          refetch={refetch}
          infoEdit={infoEdit}
        />
      )}
    </>
  )
}

import {gql} from '@apollo/client'

export const LOGIN = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      payload
      token
      settings
      refreshToken
      refreshExpiresIn
      permissions
    }
  }
`

export const VERIFY_TOKEN = gql`
  mutation ($token: String) {
    verifyToken(token: $token) {
      payload
    }
  }
`

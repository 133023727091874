import Chart from 'react-apexcharts'
import {GET_USER_REPORT} from '../../../gql/queries/reportsQuery'
import {useLazyQuery} from '@apollo/client'
import {IResUserReport} from '../../../types/reportsTypes'
import {useEffect} from 'react'
import {parseId} from '../../../helpers'
import {toast} from 'react-toastify'
import {Spinner} from 'react-bootstrap'

interface UserMetricsProps {
  dates: Date[]
  pipelineId: string
}

export const UserMetrics: React.FC<UserMetricsProps> = ({dates, pipelineId}) => {
  const [gqlGet, {data, loading, error}] = useLazyQuery<IResUserReport>(GET_USER_REPORT, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!pipelineId || !dates || dates.length <= 1) return

    gqlGet({
      variables: {
        pipelineId: parseId(pipelineId),
        startDate: dates[0].toISOString().split('T')[0],
        endDate: dates[1].toISOString().split('T')[0],
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, pipelineId])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data)
    return (
      <div className='d-flex flex-center' style={{height: '290px'}}>
        <Spinner animation='border' />
      </div>
    )

  if (data.leadsByUserMetrics.length === 0) {
    return (
      <div className='d-flex flex-column h-100'>
        <h5 className='text-center'>User metrics</h5>
        <div style={{flex: 1}} className='d-flex flex-center h-100'>
          There is no information recorded for this pipeline.
        </div>
      </div>
    )
  }

  const series = data.leadsByUserMetrics.map((user) => ({
    name: user.user,
    data: [user.totalLeads, user.soldLeads, user.declinedLeads, user.successRate, user.failureRate],
    type: 'bar',
  }))

  return (
    <div>
      <h4>User metrics</h4>

      <div>
        <Chart
          series={series}
          options={{
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            stroke: {
              width: 1,
              colors: ['#fff'],
            },
            xaxis: {
              categories: [
                'Total Leads',
                'Sold Leads',
                'Declined Leads',
                'Success Rate',
                'Failure Rate',
              ],
            },
            yaxis: {
              show: false,
            },
            fill: {
              opacity: 1,
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left',
              offsetX: 5,
              offsetY: 20,
            },
          }}
        />
      </div>
    </div>
  )
}

import {gql} from '@apollo/client'

export const CREATE_POLICY = gql`
  mutation createPolicy($policyData: policy_input!) {
    createPolicy(policyData: $policyData) {
      policy {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const EDIT_POLICY = gql`
  mutation updatePolicy($id: Int!, $policyData: policy_input!) {
    updatePolicy(id: $id, policyData: $policyData) {
      success
      errors {
        message
      }
    }
  }
`

export const CANCEL_POLICY = gql`
  mutation cancelPolicy($id: Int!) {
    cancelPolicy(id: $id) {
      success
      errors {
        message
      }
    }
  }
`

export const COMPLETE_POLICY = gql`
  mutation completePolicy($id: Int!) {
    completePolicy(id: $id) {
      success
      errors {
        message
      }
    }
  }
`

export const ADD_MEMBER_ID_POLICY = gql`
  mutation assignMemberIdToPolicy($id: ID!, $memberId: String!) {
    assignMemberIdToPolicy(id: $id, memberId: $memberId) {
      success
      errors {
        message
      }
    }
  }
`

export const ADD_DOCUMENT_POLICY_STR = `
  mutation createAttachments($attachmentsData: attachments_input!) {
    createAttachments(attachmentsData: $attachmentsData) {
      success
      errors {
        message
      }
    }
  }
`

export const EDIT_DOCUMENT_POLICY_STR = `
  mutation updateAttachments($attachmentsData: attachments_input!, $id: Int!) {
    updateAttachments(attachmentsData: $attachmentsData, id: $id) {
      success
      errors {
        message
      }
    }
  }
`

export const DELETE_FILE_POLICY = gql`
  mutation deleteAttachments($id: Int!) {
    deleteAttachments(id: $id) {
      success
      errors {
        message
      }
    }
  }
`

export const ADD_NOTE_POLICY = gql`
  mutation createPolicyNote($policyNoteData: policy_note_input!) {
    createPolicyNote(policyNoteData: $policyNoteData) {
      success
      errors {
        message
      }
    }
  }
`

export const GET_NOTES_POLICY = gql`
  query policyNotesByPolicy($policyId: Int) {
    policyNotesByPolicy(policyId: $policyId) {
      id
      comments
      created
      user {
        id
        firstName
        lastName
      }
      urgent
    }
  }
`

import {gql} from '@apollo/client'

export const CONVERSION_SALES = gql`
  query leadsByConversionPercentage(
    $pipelineId: Int
    $stageName: String
    $startDate: Date
    $endDate: Date
  ) {
    leadsByConversionPercentage(
      pipelineId: $pipelineId
      stageName: $stageName
      startDate: $startDate
      endDate: $endDate
    )
  }
`

export const DECLINE_SALES = gql`
  query leadsByConversionPercentage(
    $pipelineId: Int
    $stageName: String
    $startDate: Date
    $endDate: Date
  ) {
    leadsByConversionPercentage(
      pipelineId: $pipelineId
      stageName: $stageName
      startDate: $startDate
      endDate: $endDate
    )
  }
`

export const GET_REPORT_SOURCE_PERCENT = gql`
  query leadsBySourcePercentage($pipelineId: Int!, $startDate: Date, $endDate: Date) {
    leadsBySourcePercentage(pipelineId: $pipelineId, startDate: $startDate, endDate: $endDate) {
      leadSource
      percentage
    }
  }
`

export const GET_REPORT_LOSS_REASON_PERCENT = gql`
  query leadsByLossReasonPercentage($pipelineId: Int!, $startDate: Date, $endDate: Date) {
    leadsByLossReasonPercentage(pipelineId: $pipelineId, startDate: $startDate, endDate: $endDate) {
      lossReason
      percentage
    }
  }
`

export const GET_TAG_PERCENT = gql`
  query leadsByTagPercentage($pipelineId: Int!, $startDate: Date, $endDate: Date) {
    leadsByTagPercentage(pipelineId: $pipelineId, startDate: $startDate, endDate: $endDate) {
      tag
      percentage
    }
  }
`

export const GET_USER_REPORT = gql`
  query leadsByUserMetrics($pipelineId: Int!, $startDate: Date, $endDate: Date) {
    leadsByUserMetrics(pipelineId: $pipelineId, startDate: $startDate, endDate: $endDate) {
      user
      totalLeads
      soldLeads
      declinedLeads
      successRate
      failureRate
    }
  }
`

export const GET_POLICY_REPORTS_GLOBAL = gql`
  query policyStatistics(
    $createdAfter: DateTime
    $createdBefore: DateTime
    $carrier: String
    $stateEU: String
    $policyType: String
    $status: String
  ) {
    policyStatistics(
      createdAfter: $createdAfter
      createdBefore: $createdBefore
      carrier: $carrier
      stateEU: $stateEU
      policyType: $policyType
      status: $status
    ) {
      count
      totalCost
    }
  }
`

export const GET_POLICY_REPORTS_USERS = gql`
  query policyStatus(
    $startDate: DateTime
    $endDate: DateTime
    $pendingByDate: Boolean
    $sortOrder: String
  ) {
    policyStatus(
      startDate: $startDate
      endDate: $endDate
      pendingByDate: $pendingByDate
      sortOrder: $sortOrder
    ) {
      userStats {
        user {
          id
          firstName
          lastName
        }
        completedCount
        canceledCount
        pendingCount
      }
      totals {
        totalCompleted
        totalCanceled
        totalPending
      }
    }
  }
`

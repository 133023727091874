import {useQuery} from '@apollo/client'
import {Accordion, Button, Card, ListGroup} from 'react-bootstrap'
import {GET_ALL_GROUPS} from '../../gql/queries/asiignmentGruopQuery'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {IMembersNode, IResGroups} from '../../types/assignmentGroups'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalAddAssignmetGroup} from './components/ModalAddAssignmetGroup'
import clsx from 'clsx'
import {getColorMember} from '../../helpers'

export const AssignmentGroupsPage = () => {
  const {data, loading, error, refetch} = useQuery<IResGroups>(GET_ALL_GROUPS, {
    fetchPolicy: 'no-cache',
  })

  const {isOpen, onClose, onOpen} = useDisclourse()

  const [infoGruop, setInfoGruop] = useState<null | {
    id: string
    name: string
    members: IMembersNode[]
  }>(null)

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  if (loading || !data) return <div>Loading...</div>

  console.log('data', data)

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between flex-column flex-md-row gap-2'>
        <h2>Assignment Groups</h2>
        <Button
          className='d-flex align-items-center gap-2'
          onClick={() => {
            setInfoGruop(null)
            onOpen()
          }}
        >
          <i className='bi bi-plus fs-4 fw-bold' />
          Add Group
        </Button>
      </div>
      <div className='mt-8'>
        {data.allGroupAssignments.edges.length > 0 ? (
          <Accordion defaultActiveKey='0'>
            {data.allGroupAssignments.edges.map(({node}, i) => (
              <Accordion.Item key={node.id} eventKey={i.toString()}>
                <Accordion.Header>
                  <span className='fs-3 fw-bold'>{node.name}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h5>Members</h5>
                    <button
                      title='Edit group'
                      className='border-0 bg-warning m-0 rounded-circle'
                      onClick={() => {
                        setInfoGruop({
                          id: node.id,
                          name: node.name,
                          members: node.user.edges,
                        })
                        onOpen()
                      }}
                    >
                      <i className='bi bi-pencil text-light fw-bold' />
                    </button>
                  </div>
                  <div className='mt-4' style={{overflowY: 'auto', maxHeight: '400px'}}>
                    <ListGroup>
                      {node.user.edges.length === 0 && <div>There is not members</div>}
                      {node.user.edges.map(({node: nodeMember}, j) => (
                        <ListGroup.Item>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                              <div
                                className={clsx(
                                  'symbol-label fs-3 text-uppercase',
                                  `bg-light-${getColorMember(j)}`,
                                  `text-${getColorMember(j)}`
                                )}
                              >
                                {nodeMember.firstName?.charAt(0)}
                                {nodeMember.lastName?.charAt(0)}
                              </div>
                            </div>
                            <div className='d-flex flex-column'>
                              <span className='text-gray-800 text-hover-primary mb-1'>
                                {nodeMember.firstName} {nodeMember.lastName}
                              </span>
                              <span className='text-muted'>{nodeMember.rol}</span>
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <Card>
            <Card.Body>
              <div
                className='w-100 d-flex align-items-center justify-content-center'
                style={{height: '30vh'}}
              >
                No assignment groups created
              </div>
            </Card.Body>
          </Card>
        )}
      </div>

      {isOpen && (
        <ModalAddAssignmetGroup
          isOpen={isOpen}
          onClose={onClose}
          infoGroup={infoGruop}
          refetch={refetch}
        />
      )}
    </div>
  )
}

import {Badge, Button, Card, Col, Dropdown, ListGroup, Row, Spinner} from 'react-bootstrap'

export const LoadingOneLead = () => {
  return (
    <div>
      <header>
        <div className='d-flex align-items-center gap-3'>
          <div>
            <span className='d-flex align-items-center gap-2 text-primary'>
              <i className='bi bi-chevron-left text-primary fs-3' />
              Pipelines
            </span>
          </div>
          <div
            className='d-flex flex-column w-100 py-1 ps-3'
            style={{borderLeft: '1px solid #e9e9e9'}}
          >
            <div>
              <h2 className='placeholder-glow'>
                <span className='placeholder col-6' />
              </h2>
            </div>
            <div className='d-flex algin-items-center gap-3 text-muted'>
              <span>
                <i className='bi bi-envelope' />{' '}
                <span
                  className='placeholder-glow'
                  style={{display: 'inline-block', width: '110px'}}
                >
                  <span className='placeholder col-12' />
                </span>
              </span>
              <span className='ps-3' style={{borderLeft: '1px solid #c9c9c9'}}>
                <i className='bi bi-phone-vibrate' />{' '}
                <span
                  className='placeholder-glow'
                  style={{display: 'inline-block', width: '110px'}}
                >
                  <span className='placeholder col-12' />
                </span>
              </span>
              <span className='ps-3' style={{borderLeft: '1px solid #c9c9c9'}}>
                Idendifier:{' '}
                <span
                  className='placeholder-glow'
                  style={{display: 'inline-block', width: '110px'}}
                >
                  <span className='placeholder col-4' />
                </span>
              </span>
            </div>
          </div>
          <div>
            <div style={{flex: 0}}>
              <Badge bg='success' pill className='fs-2 d-flex align-items-center gap-1'>
                <i className='bi bi-telephone-plus' style={{color: 'inherit'}} />
                <Spinner animation='grow' size='sm' />
              </Badge>
            </div>
          </div>
        </div>

        <Row>
          <Col lg={6} />
          <Col
            lg={6}
            className='d-flex algin-items-center justify-content-start justify-content-md-end'
          >
            <div className='d-flex algin-items-center gap-3'>
              <Button disabled>
                <i className='bi bi-check-lg fs-2' />
                Sold
              </Button>
              <Button variant='danger' disabled>
                <i className='bi bi-x-lg fs-2' />
                Dead
              </Button>
              <Dropdown>
                <Dropdown.Toggle variant='white' className='border' id='dropdown-basic' disabled>
                  Actions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                  <Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
                  <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className='d-flex align-items-center justify-content-center'>
                <button
                  disabled
                  style={{
                    border: 0,
                    padding: '0px 10px',
                    borderRadius: '15px',
                    width: '85px',
                    height: '30px',
                    color: '#fff',
                    backgroundColor: '#b2c7df',
                    borderColor: '#005cbf',
                  }}
                  className='d-flex align-items-center gap-2 justify-content-center'
                >
                  <i className='bi bi-pencil text-white' />
                  Edit
                </button>
              </div>
            </div>
          </Col>
        </Row>

        <div className=''>
          {/* <div style={{position: 'absolute', marginLeft: ' 20px'}}>
            <p className='text-primary'>Pipelines</p>
            <p>Stages</p>
          </div> */}
          <div className='wizard-form py-4 my-2'>
            <ul
              id='progressBar'
              className='progressbar px-lg-5 px-0 w-100 d-flex align-items-center justify-content-center'
            >
              {[1, 2, 3].map((i) => {
                return (
                  <li
                    id='progressList-1'
                    className={`d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list`}
                  >
                    <span
                      className='info-idx'
                      style={{
                        boxShadow: '1px 1px 3px #606060',
                        backgroundColor: '#f1f1f1',
                        color: '#111',
                      }}
                    >
                      {i}
                    </span>
                    <span
                      style={{
                        display: 'inline-block',
                        maxWidth: '12ch',
                        textOverflow: 'ellipsis',
                        overflowX: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <span
                        className='placeholder-glow'
                        style={{display: 'inline-block', width: '80px'}}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </span>
                  </li>
                )
              })}
              {/* <li
              id='progressList-1'
              className='d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list active'
            >
              <span
                className='info-idx'
                style={{
                  boxShadow: '1px 1px 3px #606060' || '0 0 0 7.5px rgb(176 60 70 / 11%)',
                  backgroundColor: '#f1f1f1' || '#dc3545',
                }}
              >
                1
              </span>
              Paso 1
            </li>
            <li
              id='progressList-2'
              className='d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list active'
            >
              <span className='info-idx'>2</span>
              Paso 2
            </li>
            <li
              id='progressList-3'
              className='d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list'
            >
              <span className='info-idx'>3</span>
              Paso 3
            </li>
            <li
              id='progressList-4'
              className='d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list'
            >
              <span className='info-idx'>4</span>
              Comencemos
            </li> */}
            </ul>
          </div>
        </div>
      </header>
      <div className='pt-20'>
        <Row>
          <Col lg={5}>
            <div className='d-flex flex-column gap-4'>
              <Card>
                <Card.Header>
                  <Card.Title>Lead Information</Card.Title>
                </Card.Header>
                <ListGroup variant='flush' className='px-5 py-3'>
                  <ListGroup.Item className='d-flex align-items-center justify-content-between py-4'>
                    <div className='text-muted'>Identifier</div>
                    <div className='fw-bold'>
                      <span
                        className='placeholder-glow'
                        style={{display: 'inline-block', width: '80px'}}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex align-items-center justify-content-between py-4'>
                    <div className='text-muted'>Name</div>
                    <div className='fw-bold'>
                      <span
                        className='placeholder-glow'
                        style={{display: 'inline-block', width: '80px'}}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex align-items-center justify-content-between py-4'>
                    <div className='text-muted'>Phone</div>
                    <div className='fw-bold'>
                      <span
                        className='placeholder-glow'
                        style={{display: 'inline-block', width: '80px'}}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex align-items-center justify-content-between py-4'>
                    <div className='text-muted'>Email</div>
                    <div className='fw-bold'>
                      <span
                        className='placeholder-glow'
                        style={{display: 'inline-block', width: '80px'}}
                      >
                        <span className='placeholder col-12' />
                      </span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>

              <Card>
                <Card.Header>
                  <Card.Title>Tags</Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className='d-flex align-items-center justify-content-center gap-2'>
                    <Spinner animation='border' />
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col lg={7}>
            <div>
              <Card>
                <Card.Header className='align-items-center'>
                  <Card.Title>Lead type information</Card.Title>
                  <div>
                    <div
                      style={{
                        borderBottom: 0,
                        display: 'flex',
                        background: '#f6f7fb',
                        padding: '6px',
                        borderRadius: '60px',
                      }}
                      className='d-flex align-items-center'
                    >
                      <button
                        style={{
                          flex: 1,
                          borderRadius: '20px',
                        }}
                        className={`border-0 bg-transparent`}
                      >
                        Personal
                      </button>
                      <button
                        style={{
                          flex: 1,
                          borderRadius: '20px',
                        }}
                        className={`border-0 bg-transparent`}
                      >
                        Commercial
                      </button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body style={{minHeight: '200px'}}>
                  <Spinner animation='border' />
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {IModalAddProps, IResCreate} from '../leadSourceTypes'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Form, Spinner} from 'react-bootstrap'
import {useEffect} from 'react'
import {parseId} from '../../../helpers'
import {useMutation} from '@apollo/client'
import {ADD_EDIT_LEAD_SOURCES} from '../../../gql/mutations/leadSourceMutations'
import {toast} from 'react-toastify'

const validationSchema = Yup.object({
  name: Yup.string().required(),
  idCat: Yup.string().required(),
  id: Yup.string(),
})

export const ModalAddEditLeadSource: React.FC<IModalAddProps> = ({
  isOpen,
  onClose,
  refetch,
  info,
}) => {
  const [gqlCreate, {data, loading, error}] = useMutation<IResCreate>(ADD_EDIT_LEAD_SOURCES)
  const formik = useFormik({
    initialValues: {
      name: '',
      idCat: '',
      id: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (!values.idCat) return
      const input: {name: string; category: string; id?: string} = {
        name: values.name,
        category: values.idCat,
      }
      if (values.id) {
        input.id = values.id
      }
      gqlCreate({
        variables: {
          input,
        },
      })
    },
  })

  useEffect(() => {
    if (!info.idCat) return
    formik.setFieldValue('idCat', parseId(info.idCat))
    if (info.id && info.name) {
      formik.setFieldValue('name', info.name)
      formik.setFieldValue('id', parseId(info.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.leadSourceCreateupdateMutation?.leadSource?.id) {
      toast.success(`Lead Source ${info.id ? 'edited' : 'added'} correctly`)
      onClose()
      refetch()
    } else if (
      data.leadSourceCreateupdateMutation?.errors &&
      data.leadSourceCreateupdateMutation?.errors?.length > 0
    ) {
      toast.error(
        `Error: ${
          data.leadSourceCreateupdateMutation.errors?.[0]?.messages?.[0] || 'something happened'
        }`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{info.id ? 'Edit Lead Source' : 'Add Lead Source'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div>
            <Form.Label htmlFor='name'>Name</Form.Label>
            <Form.Control
              id='name'
              aria-describedby='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={Boolean(formik.errors.name && formik.touched.name)}
            />
            {formik.errors.name && formik.touched.name && (
              <Form.Text id='error-name' className='text-danger'>
                {formik.errors.name}
              </Form.Text>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' variant='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' variant='primary' disabled={loading}>
            {loading && <Spinner size='sm' animation='border' className='me-2' />}
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

import {gql} from '@apollo/client'

export const GET_ALL_LEADS_BY_STAGE = gql`
  query allLeads($offset: Int, $stagePipelineId: Int, $stagePipeline_PipelineId: Int, $first: Int) {
    allLeads(
      offset: $offset
      stagePipelineId: $stagePipelineId
      stagePipeline_PipelineId: $stagePipeline_PipelineId
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          created
          callCounter
          phone
          user {
            edges {
              node {
                id
                firstName
                lastName
              }
            }
          }
          personalLead {
            id
          }
          comercialLead {
            id
          }
          tagInsurance {
            edges {
              node {
                id
                name
                color
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ALL_LEADS_SEARCH = gql`
  query allLeads($name_Icontains: String, $phone_Icontains: String) {
    allLeads(name_Icontains: $name_Icontains, phone_Icontains: $phone_Icontains) {
      edges {
        node {
          id
          name
          phone
          stagePipeline {
            name
          }
        }
      }
    }
  }
`

export const GET_ONE_LEAD = gql`
  query leadById($id: String) {
    leadById(id: $id) {
      id
      created
      name
      email
      phone
      observations
      callCounter
      stagePipeline {
        id
        name
        pipeline {
          id
          name
          stagePipelineSet {
            edges {
              node {
                id
                name
                order
              }
            }
          }
        }
      }
      comercialLead {
        id
        created
        dba
        businessEntity
        businessClassification
        federalEin
        businessStarted
        numberEmployees
        totalPayroll
        secondPhone
        secondEmail
        contactFirstname
        contactMiddlename
        contactLastname
      }
      personalLead {
        id
        created
        birthDay
        civilStatus
        secondPhone
        streetAddress
        city
        country
        state
        postalCode
      }
      user {
        edges {
          node {
            id
            firstName
            lastName
            email
            cellphone
          }
        }
      }
      leadSource {
        id
        name
      }
      campus {
        id
        name
      }
      tagInsurance {
        edges {
          node {
            id
            name
            color
          }
        }
      }
      reasonLeadLoss {
        id
        reason
      }
    }
  }
`

export const GET_ALL_APPOINTMENTS_BY_LEAD = gql`
  query leadById($id: String) {
    leadById(id: $id) {
      id
      appointmentSet {
        edges {
          node {
            id
            tittle
            created
            typeAppointment
            meetingLocation
            date
            duration
            assignedTo
            creditTo
            comment
          }
        }
      }
    }
  }
`

export const GET_ALL_NOTES_BY_LEAD = gql`
  query leadById($id: String) {
    leadById(id: $id) {
      id
      noteSet {
        edges {
          node {
            id
            created
            tittle
            user {
              id
              firstName
              lastName
            }
            date
            description
          }
        }
      }
    }
  }
`

export const GET_ALL_FILES_BY_LEAD = gql`
  query leadById($id: String) {
    leadById(id: $id) {
      id
      archiveSet {
        edges {
          node {
            id
            name
            created
            archivo
          }
        }
      }
    }
  }
`

export const GET_TOTAL_STAGES_LEAD = gql`
  query leadsByStage($stageId: Int!) {
    leadsByStage(stageId: $stageId)
  }
`

export const GET_MARKET_PLACE_2 = gql`
  query marketplace2(
    $queryType: MarketplaceEnum!
    $year: Int!
    $householdIncome: Int
    $age: Int
    $isPregnant: Boolean
    $isParent: Boolean
    $usesTobacco: Boolean
    $gender: String
    $hasMarriedCouple: Boolean
    $countyfips: String
    $state: String
    $zipCode: String
    $market: String
    $planIds: [String]
    $aptcOverride: Int
    $csrOverride: String
    $catastrophicOverride: Boolean
    $aptcEligible: Boolean
    $dob: String
    $hasMec: Boolean
    $utilizationLevel: String
    $limit: Int
    $offset: Int
    $order: String
  ) {
    marketplace2(
      queryType: $queryType
      year: $year
      householdIncome: $householdIncome
      age: $age
      isPregnant: $isPregnant
      isParent: $isParent
      usesTobacco: $usesTobacco
      gender: $gender
      hasMarriedCouple: $hasMarriedCouple
      countyfips: $countyfips
      state: $state
      zipCode: $zipCode
      market: $market
      planIds: $planIds
      aptcOverride: $aptcOverride
      csrOverride: $csrOverride
      catastrophicOverride: $catastrophicOverride
      aptcEligible: $aptcEligible
      dob: $dob
      hasMec: $hasMec
      utilizationLevel: $utilizationLevel
      limit: $limit
      offset: $offset
      order: $order
    )
  }
`

export const GET_ZIP_INFO = gql`
  query zipcodeByZip($zipCode: String!) {
    zipcodeByZip(zipCode: $zipCode) {
      id
      zipCode
      stateId
      countyFips
      stateName
    }
  }
`

export const GET_REQUEST_CONSET = gql`
  query allDocusignEvents($leadId: ID) {
    allDocusignEvents(leadId: $leadId) {
      edges {
        node {
          id
          templateDescription
          createdAt
          event
          envelopeId
        }
      }
    }
  }
`

export const GET_REQUEST_CONSENT_LASTED = gql`
  query latestDocusignEventsByLead($leadId: Int!) {
    latestDocusignEventsByLead(leadId: $leadId) {
      id
      templateDescription
      envelopeId
      event
    }
  }
`

import {Button, Col, Form, Modal, Row} from 'react-bootstrap'

const FILTERS_NAME_ARR = [
  {name: 'Agentes Independientes', description: 'this is a test', color: 'primary'},
  {name: 'Call Center USA', description: 'this is a test', color: 'dark'},
  {name: 'Contratos', description: '', color: 'light'},
  {name: 'Grupo Roberto Ramirez', description: 'this is a test', color: 'success'},
  {name: 'Operaciones', description: 'this is a test', color: 'warning'},
  {name: 'Procesamiento', description: '', color: 'success'},
  {name: 'Prueba comisiones', description: 'this is a test', color: 'warning'},
  {name: 'Support', description: 'this is a test', color: 'dark'},
]

export const ModalAddManage: React.FC<{isOpen: boolean; onClose: () => void}> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Create a new group</Modal.Title>
      </Modal.Header>
      <form onSubmit={(e) => e.preventDefault()}>
        <Modal.Body>
          <Row>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>
                  Name: <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Control />
              </Form.Group>
            </Col>
            <Col md={6} sm={12}>
              <Form.Group>
                <Form.Label>
                  Color: <small className='text-danger'>(required)</small>
                </Form.Label>
                <Form.Control className='w-50' type='color' />
              </Form.Group>
            </Col>
            <Col md={12} sm={12} className='mt-4'>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control as='textarea' rows={4} />
              </Form.Group>
            </Col>
          </Row>
          <div className='my-5 d-flex align-items-center justify-content-end w-100'>
            <Button size='sm'>
              <i className='bi bi-check fs-3' style={{color: 'inherit'}} />
              CREATE GROUP
            </Button>
          </div>
          <div>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th className='fw-bold'>Name</th>
                  <th className='fw-bold'>Description</th>
                  <th className='fw-bold text-right'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {FILTERS_NAME_ARR.map((fil) => (
                  <tr>
                    <td>{fil.name}</td>
                    <td>{fil.description}</td>
                    <td>
                      <Button variant='outline-primary' size='sm'>
                        <i className='bi bi-pencil' style={{color: 'inherit'}} />
                        Edit
                      </Button>
                      <Button variant='outline-danger' className='ms-4' size='sm'>
                        <i className='bi bi-x' style={{color: 'inherit'}} />
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='outline-secondary'
            onClick={onClose}
            style={{border: '1px solid #dbdfe9'}}
          >
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

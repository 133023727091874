import moment from 'moment'
import {EdgeMemberPolicy} from '../../../types/policiesApi'

interface IHolderProps {
  members: EdgeMemberPolicy[]
  memberId?: string
}

export const HolderInfoSide = ({members, memberId}: IHolderProps) => {
  const findHolder = members.find(({node}) => node.rol === 'HOLDER')

  if (!findHolder) {
    return (
      <div className='kt-widget__info d-flex flex-center' style={{height: '120px'}}>
        <span className='kt-widget__label'>There is no information about the owner yet</span>
      </div>
    )
  }

  const {node} = findHolder

  return (
    <>
      <div className='kt-widget__info'>
        <span className='kt-widget__label'>Holder:</span>
        <span className='kt-widget__data'>
          {node.firstName} {node.lastName}
        </span>
      </div>
      <div className='kt-widget__info'>
        <span className='kt-widget__label'>Date of birth:</span>
        <span className='kt-widget__data'>
          {' '}
          {node.dateBirth ? moment(node.dateBirth).format('DD MMM, YY') : 'N/R'}
        </span>
      </div>
      <div className='kt-widget__info'>
        <span className='kt-widget__label'>Phone</span>
        <span className='kt-widget__data'>{node.phone || 'N/R'}</span>
      </div>
      <div className='kt-widget__info'>
        <span className='kt-widget__label'>Location</span>
        <span className='kt-widget__data'>{node.mailingAddress || 'N/R'}</span>
      </div>
      <div className='kt-widget__info'>
        <span className='kt-widget__label'>Annual Income</span>
        <span className='kt-widget__data' id='household_income_label'>
          {node.sourceOfIncome1 || 'N/R'}
        </span>
      </div>
      <div className='kt-widget__info'>
        <span className='kt-widget__label'>Member ID:</span>
        <span className='kt-widget__data'>{memberId || 'N/R'}</span>
      </div>
    </>
  )
}

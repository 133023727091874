import {gql} from '@apollo/client'

export const CREATE_LEAD = gql`
  mutation createLead($leadData: lead_input!) {
    createLead(leadData: $leadData) {
      lead {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

export const UPDATE_LEAD = gql`
  mutation updateLead($id: Int!, $leadData: lead_input!) {
    updateLead(id: $id, leadData: $leadData) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const CREATE_NOTE_LEAD = gql`
  mutation createNote($noteData: note_input!) {
    createNote(noteData: $noteData) {
      success
      note {
        id
      }
      errors {
        field
        message
      }
    }
  }
`
export const EDIT_NOTE_LEAD = gql`
  mutation updateNote($id: Int!, $noteData: note_input!) {
    updateNote(id: $id, noteData: $noteData) {
      note {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const CREATE_UPDATE_APPOINTMENT = gql`
  mutation appointmentCreateupdateMutation($input: appointment_createUpdate_mutationInput!) {
    appointmentCreateupdateMutation(input: $input) {
      appointment {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_FILE_STR = `
  mutation createArchive($archiveData: archive_input!) {
    createArchive(archiveData: $archiveData) {
      success
      archive {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

export const CHANGE_STATE_LEAD = gql`
  mutation changeLeadStage($leadId: Int!, $stageId: Int!) {
    changeLeadStage(leadId: $leadId, stageId: $stageId) {
      lead {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const CALL_COUNTER = gql`
  mutation sumCallCounter($leadId: Int!) {
    sumCallCounter(leadId: $leadId) {
      success
      lead {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

export const DEAD_LEAD = gql`
  mutation declineLeadMutation($leadId: Int!, $reasonLeadLossId: Int!) {
    declineLeadMutation(leadId: $leadId, reasonLeadLossId: $reasonLeadLossId) {
      lead {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const REQUEST_CONSENT = gql`
  mutation CreateEnvelopeWithTemplate($template: TemplateID!, $args: TemplateArguments!) {
    createDocusignEnvelopeWithTemplate(template: $template, args: $args) {
      success
      envelopeId
    }
  }
`

import {Fragment, useEffect, useState} from 'react'
import {
  Badge,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Form,
  ListGroup,
  Pagination,
  Row,
  Spinner,
} from 'react-bootstrap'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalAddUser} from './components/ModalAddUser'
// import Select from 'react-select'
import {Link} from 'react-router-dom'
import {AsideUserFilters} from './components/AsideUserFilters'
import {OffCanvaAsideFilters} from './components/OffCanvaAsideFilters'
import {ModalAddManage} from './components/ModalAddManage'
import {GET_USER_MEMBERS} from '../../gql/queries/membersQuery'
import {useLazyQuery} from '@apollo/client'
import {IResAllMembers} from '../../types/members'
import {LoadingUsersPage} from './components/LoadingUsersPage'
import {toast} from 'react-toastify'
import {getColorRamdomBoots, parseId} from '../../helpers'

/* const DATA_USERS = [
  {
    id: '15521',
    name: 'Alvaro Barroso',
    npn: null,
    avatar: null,
    email: 'support+4@thefutureinsurance.com',
    url: '/users/profile/15521',
    phone: null,
    status: '1',
    role_id: '10',
    role: 'Staff (Level 3)',
    free: '0',
    groups: [
      {
        id: '223',
        name: 'Support',
        color: 'danger',
        rel_id: '4246',
        user_id: '15521',
      },
    ],
  },
  {
    id: '15532',
    name: 'Amayris Velasquez',
    npn: null,
    avatar: null,
    email: 'amvr@thefutureinsurance.com',
    url: '/users/profile/15532',
    phone: null,
    status: '1',
    role_id: '5',
    role: 'Manager',
    free: '0',
    groups: [
      {
        id: '221',
        name: 'Operaciones',
        color: 'success',
        rel_id: '4260',
        user_id: '15532',
      },
    ],
  },
  {
    id: '7173',
    name: 'Ana Pabon',
    npn: '20467449',
    avatar: null,
    email: 'apabon@thefutureinsurance.com',
    url: '/users/profile/7173',
    phone: '+17868053121',
    status: '1',
    role_id: '2',
    role: 'Agent',
    free: '0',
    groups: [],
  },
  {
    id: '15519',
    name: 'Anais Gonzalez',
    npn: null,
    avatar: null,
    email: 'support+2@thefutureinsurance.com',
    url: '/users/profile/15519',
    phone: null,
    status: '1',
    role_id: '10',
    role: 'Staff (Level 3)',
    free: '0',
    groups: [
      {
        id: '223',
        name: 'Support',
        color: 'danger',
        rel_id: '4247',
        user_id: '15519',
      },
    ],
  },
  {
    id: '15534',
    name: 'Anais Moncada',
    npn: null,
    avatar: null,
    email: 'anais@thefutureinsurance.com',
    url: '/users/profile/15534',
    phone: null,
    status: '1',
    role_id: '5',
    role: 'Manager',
    free: '0',
    groups: [
      {
        id: '221',
        name: 'Operaciones',
        color: 'success',
        rel_id: '4261',
        user_id: '15534',
      },
    ],
  },
  {
    id: '2438',
    name: 'Cesar Ramirez',
    npn: '20455825',
    avatar:
      'https://apizeal-user-public-files.s3.amazonaws.com/avatars/2438/b892457e939b691f301cd08a44ad05b2.jpg?=1716567751',
    email: 'cesar@thefutureinsurance.com',
    url: '/users/profile/2438',
    phone: '+12107142815',
    status: '1',
    role_id: '2',
    role: 'Agent',
    free: '0',
    groups: [
      {
        id: '226',
        name: 'Prueba Comisiones',
        color: 'danger',
        rel_id: '4266',
        user_id: '2438',
      },
    ],
  },
  {
    id: '7212',
    name: 'Anibal Sanchez',
    npn: null,
    avatar: null,
    email: 'anibal@thefutureinsurance.com',
    url: '/users/profile/7212',
    phone: null,
    status: '0',
    role_id: '2',
    role: 'Agent',
    free: '0',
    groups: [],
  },
  {
    id: '15480',
    name: 'Barbara De Armas',
    npn: null,
    avatar: null,
    email: 'barbarad@thefutureinsurance.com',
    url: '/users/profile/15480',
    phone: null,
    status: '1',
    role_id: '6',
    role: 'Recruiter',
    free: '0',
    groups: [
      {
        id: '224',
        name: 'Contratos',
        color: 'dark',
        rel_id: '4243',
        user_id: '15480',
      },
    ],
  },
  {
    id: '3955',
    name: 'Barbara Nunez',
    npn: '18114763',
    avatar: null,
    email: 'barbara@thefutureinsurance.com',
    url: '/users/profile/3955',
    phone: '+13059881403',
    status: '1',
    role_id: '2',
    role: 'Agent',
    free: '0',
    groups: [],
  },
] */

export const AddUserPage = () => {
  const [gqlGetMembers, {data, loading, error, refetch}] = useLazyQuery<IResAllMembers>(
    GET_USER_MEMBERS,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const {isOpen, onClose, onOpen} = useDisclourse()
  const {isOpen: isOpenOffCanva, onClose: onCloseOffCanva, onOpen: onOpenOffCanva} = useDisclourse()
  const {isOpen: isOpenManage, onClose: onCloseManage, onOpen: onOpenManage} = useDisclourse()
  const [tabFilters, setTabFilters] = useState('All Users')

  const [dataTable, setDataTable] = useState<IResAllMembers['allUsers']['edges']>([])

  const [offset, setOffset] = useState(0)
  const [first, setFirst] = useState(10)

  const [isPag, setIsPag] = useState(false)
  const [page, setPage] = useState(1)

  const [arrShowMoreTable, setArrShowMoreTable] = useState<{id: string | number; show: boolean}[]>(
    []
  )

  useEffect(() => {
    const variables = {
      offset,
      first,
    }

    gqlGetMembers({
      variables,
    })
  }, [gqlGetMembers, offset, first])

  useEffect(() => {
    if (!data) return
    const {edges} = data.allUsers
    if (edges.length === 0) return
    if (isPag) {
      setDataTable((p) => [...p, ...edges])
      setArrShowMoreTable((p) => [...p, ...edges.map(({node}) => ({id: node.id, show: false}))])
    } else {
      setDataTable(edges)
      setArrShowMoreTable(edges.map(({node}) => ({id: node.id, show: false})))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  /* const getRandomRole = () => {
    const rols = ['Staff (Level 3)', 'Manager', 'Agent', '']
    const num = Math.floor(Math.random() * 5)
    return rols[num]
  } */

  if (loading || !data) {
    return <LoadingUsersPage />
  }

  return (
    <>
      <header
        className='py-4 d-flex align-items-center justify-content-between'
        style={{width: '100%', marginTop: '-10px'}}
      >
        <Breadcrumb>
          <Breadcrumb.Item className='d-blok d-md-none' onClick={onOpenOffCanva}>
            <span>
              <i className='bi bi-list fs-1' />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Users</Breadcrumb.Item>
          <Breadcrumb.Item active>{isOpen ? 'Add user' : 'View users'} </Breadcrumb.Item>
        </Breadcrumb>

        <Button size='sm' onClick={onOpen}>
          <i className='bi bi-plus fs-3' /> New User
        </Button>
      </header>

      <Row>
        <Col md={4} lg={4} sm={0} xl={3} className='d-none d-md-block'>
          <AsideUserFilters
            setTabFilters={setTabFilters}
            tabFilters={tabFilters}
            onOpenManage={onOpenManage}
          />
        </Col>
        <Col md={8} lg={8} sm={12} xl={9}>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
              backgroundColor: '#fff',
              marginBottom: '20px',
              borderRadius: '4px',
              padding: '8px 10px',
            }}
          >
            <div
              className='d-flex align-items-center justify-content-between'
              style={{
                borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
                paddingBottom: '10px',
              }}
            >
              <h3>List of users</h3>
              {/* <Dropdown>
                <Dropdown.Toggle
                  variant='outline-primary'
                  id='dropdown-basic'
                  style={{border: '1px solid #3e97ff'}}
                >
                  <i className='bi bi-download fs-3' style={{color: 'inherit'}} /> Export
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                  <Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
                  <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>

            {/* <Row className='mt-4'>
              <Col sm={12} md={12} lg={4}>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label>Search users:</Form.Label>
                  <Form.Control placeholder='Type here to search...' />
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <Form.Group className='mb-3' controlId='exampleFotrolInput1'>
                  <Form.Label>Filter by status:</Form.Label>
                  <Select
                    styles={{
                      control: (base) => ({...base, padding: '2px 4px'}),
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <Form.Group className='mb-3' controlId='exampleFotrolInput1'>
                  <Form.Label>Filter by status:</Form.Label>
                  <Form.Select aria-label='Default select example'>
                    <option>Open this select menu</option>
                    <option value='1'>One</option>
                    <option value='2'>Two</option>
                    <option value='3'>Three</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row> */}
            <Row>
              <Col sm={12} md={8} />
              {/* <Col sm={12} md={8}>
                <p className='fw-bold m-0 p-0'>Only show users without:</p>
                <div>
                  <Form.Check
                    inline
                    label='A group assigned'
                    name='group1'
                    type='checkbox'
                    id='inline-1'
                  />
                  <Form.Check inline label='Licenses' name='group2' type='checkbox' id='inline-2' />
                  <Form.Check inline label='NPN' name='group3' type='checkbox' id='inline-3' />
                  <Form.Check
                    inline
                    label='Free mark'
                    name='group4'
                    type='checkbox'
                    id='inline-4'
                  />
                </div>
              </Col> */}
              {/* <Col sm={12} md={4}>
                <div className='mt-1'>
                  <Button size='sm' variant='outline-primary' style={{border: '1px solid #3e97ff'}}>
                    <i className='bi bi-eraser-fill' style={{color: 'inherit'}} />
                    Reset Filters
                  </Button>
                  <Button
                    size='sm'
                    variant='outline-success'
                    style={{border: '1px solid #50cd89'}}
                    className='ms-1'
                  >
                    <i className='bi bi-search' style={{color: 'inherit'}} />
                    Search
                  </Button>
                </div>
              </Col> */}
            </Row>

            <Row className='mt-5'>
              <Col sm={12} md={6} xl={4}>
                <div className='d-flex align-items-center gap-1'>
                  <span style={{flex: 0}}>Show</span>
                  <Form.Select
                    value={first}
                    onChange={(e) => {
                      setIsPag(false)
                      setOffset(0)
                      setFirst(Number(e.target.value))
                      setPage(1)
                    }}
                    style={{flex: 0, flexBasis: '80px'}}
                  >
                    <option>2</option>
                    <option>5</option>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>50</option>
                  </Form.Select>
                  <span style={{flex: 0}}>entries</span>
                </div>
              </Col>
              <Col className='d-none d-xl-block' xl={4}>
                <p className='d-flex align-items-center justify-content-center h-100 w-100'>
                  Showing {page} to {Math.ceil(dataTable.length / first)} of 100 entries
                </p>
              </Col>
              <Col sm={12} md={6} xl={4}>
                <div className='d-flex align-items-center justify-content-end'>
                  <Pagination size='sm'>
                    <Pagination.Prev
                      disabled={page <= 1}
                      onClick={() => {
                        if (page <= 1) return
                        setPage((p) => p - 1)
                      }}
                    />
                    {Array.from({length: Math.ceil(dataTable.length / first)})
                      .map((_, i) => i + 1)
                      .map((n) => (
                        <Pagination.Item
                          key={n}
                          active={n === page}
                          onClick={() => {
                            setPage(n)
                          }}
                        >
                          {n}
                        </Pagination.Item>
                      ))}
                    {loading && (
                      <Pagination.Item>
                        <Spinner animation='grow' size='sm' />
                      </Pagination.Item>
                    )}
                    <Pagination.Next
                      disabled={!data?.allUsers.pageInfo.hasNextPage}
                      onClick={() => {
                        setIsPag(true)
                        setOffset((p) => p + first)
                        setPage((p) => p + 1)
                      }}
                    />
                  </Pagination>
                </div>
              </Col>
            </Row>
            <div style={{width: '100%', overflowX: 'auto'}}>
              <table className='table table-hover table-sm compact dataTable no-footer dtr-inline'>
                <thead>
                  <tr role='row'>
                    <th>
                      <Form.Check type='checkbox' />
                    </th>
                    <th className='fw-bold'>Avatar</th>
                    <th className='fw-bold'>Name</th>
                    <th className='fw-bold d-none d-lg-table-cell'>Contact information</th>
                    <th className='fw-bold d-none d-lg-table-cell'>Groups</th>
                    <th className='fw-bold d-none d-lg-table-cell'>Status</th>
                    <th className='fw-bold d-none d-lg-table-cell'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTable.map(({node: user}) => (
                    <Fragment key={user.id}>
                      <tr role='row' className='odd parent'>
                        <td tabIndex={0} style={{verticalAlign: 'middle'}}>
                          <div className='d-flex align-items-center gap-1'>
                            <Form.Check type='checkbox' />
                            <button
                              className='d-flex d-lg-none align-items-center justify-content-center'
                              style={{
                                backgroundColor: 'transparent',
                                border: '1px solid blue',
                                borderRadius: '100%',
                                color: 'blue',
                                width: '20px',
                                height: '20px',
                              }}
                              onClick={() => {
                                setArrShowMoreTable((p) => {
                                  const n = [...p]
                                  const find = n.find((s) => s.id === user.id)
                                  if (!find) return n
                                  find.show = !find.show
                                  return n
                                })
                              }}
                            >
                              {arrShowMoreTable.find((ar) => ar.id === user.id)?.show ? (
                                <i className='bi bi-dash fs-3' style={{color: 'inherit'}} />
                              ) : (
                                <i className='bi bi-plus fs-3' style={{color: 'inherit'}} />
                              )}
                            </button>
                          </div>
                        </td>
                        <td>
                          {user.avatar ? (
                            <img
                              width={50}
                              height={50}
                              style={{objectFit: 'cover', borderRadius: '100%'}}
                              src={user.avatar}
                              alt={user.firstName}
                            />
                          ) : (
                            <span
                              className='d-flex align-items-center justify-content-center fs-2'
                              style={{
                                borderRadius: '100%',
                                background: 'rgba(85, 120, 235, .1)',
                                color: '#5578eb',
                                height: '50px',
                                width: '50px',
                              }}
                            >
                              {user.firstName?.[0]?.toUpperCase()}
                            </span>
                          )}
                        </td>
                        <td>
                          <Link className='text-info' to={`/user/${parseId(user.id)}`}>
                            {user.firstName} {user.lastName}
                          </Link>
                          {user.groups.edges.length > 0 && (
                            <>
                              <br />
                              <span className='fw-bold'>Role: </span>
                              {user.groups.edges[0].node.name}
                            </>
                          )}
                          {/* {user.role ? (
                          ) : (
                            <>
                              <br />
                              <span className='fw-bold'>Role: </span>
                              {getRandomRole()}
                            </>
                          )} */}
                          {user.npn && (
                            <>
                              <br />
                              <span className='fw-bold'>NPN: </span>
                              {user.npn}
                            </>
                          )}
                        </td>

                        <td className='d-none d-lg-table-cell'>
                          <a href={`mailto:${user.email}`}>{user.email}</a>
                          {user.cellphone && (
                            <>
                              <br />
                              <span>
                                <i className='bi bi-phone'></i>
                              </span>
                              {user.cellphone}
                            </>
                          )}
                        </td>
                        <td className='d-none d-lg-table-cell'>
                          <div className='d-flex flex-col gap-2'>
                            {user.groupAssignmentSet.edges.length > 0 && (
                              <div>
                                {user.groupAssignmentSet.edges.map(({node: b}, idx) => (
                                  <div
                                    key={b.id}
                                    className={`d-flex align-items-center justify-content-between gap-1 py-2 px-2 bg-${
                                      getColorRamdomBoots(idx) || 'light'
                                    }`}
                                    style={{
                                      borderRadius: '3px',
                                      color: 'white',
                                      fontSize: '11px',
                                    }}
                                  >
                                    {b.name}
                                    <button
                                      style={{
                                        fontWeight: 'bolder',
                                        border: 0,
                                        backgroundColor: 'transparent',
                                        color: 'red',
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className='d-none d-lg-table-cell'>
                          <Badge
                            className='d-flex'
                            bg='success'
                            /* bg={
                              user.status === '0'
                                ? 'danger'
                                : user.status === '1'
                                ? 'success'
                                : 'dark'
                            } */
                          >
                            ACTIVE
                            {/* {user.status === '0'
                              ? 'INACTIVE'
                              : user.status === '1'
                              ? 'ACTIVE'
                              : 'N/R'} */}
                          </Badge>
                        </td>
                        <td className='d-none d-lg-table-cell'>
                          <Dropdown>
                            <Dropdown.Toggle size='sm' variant='success' id='dropdown-basic'>
                              Actions
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                              <Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
                              <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                      {arrShowMoreTable.find((ar) => ar.id === user.id)?.show && (
                        <tr className='d-lg-none'>
                          <td colSpan={3}>
                            <ListGroup variant='flush' className='d-table mx-auto w-75'>
                              <ListGroup.Item>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <span className='fw-bold'>Contact information:</span>
                                  <div>
                                    <a href={`mailto:${user.email}`}>{user.email}</a>
                                    {user.cellphone && (
                                      <>
                                        <br />
                                        <span>
                                          <i className='bi bi-phone'></i>
                                        </span>
                                        {user.cellphone}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <span className='fw-bold'>Groups:</span>
                                  <div>
                                    <div className='d-flex flex-col gap-2'>
                                      {user.groupAssignmentSet.edges.length > 0 && (
                                        <div>
                                          {user.groupAssignmentSet.edges.map(({node: b}, idx) => (
                                            <div
                                              key={b.id}
                                              className={`d-flex align-items-center justify-content-between gap-1 py-2 px-2 bg-${
                                                getColorRamdomBoots(idx) || 'light'
                                              }`}
                                              style={{
                                                borderRadius: '3px',
                                                color: 'white',
                                                fontSize: '11px',
                                              }}
                                            >
                                              {b.name}
                                              <button
                                                style={{
                                                  fontWeight: 'bolder',
                                                  border: 0,
                                                  backgroundColor: 'transparent',
                                                  color: 'red',
                                                }}
                                              >
                                                X
                                              </button>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <span className='fw-bold'>Status:</span>
                                  <div>
                                    <Badge
                                      className='d-flex'
                                      bg='success'
                                      /* bg={
                                        user.status === '0'
                                          ? 'danger'
                                          : user.status === '1'
                                          ? 'success'
                                          : 'dark'
                                      } */
                                    >
                                      ACTIVE
                                      {/* {user.status === '0'
                                        ? 'INACTIVE'
                                        : user.status === '1'
                                        ? 'ACTIVE'
                                        : 'N/R'} */}
                                    </Badge>
                                  </div>
                                </div>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <span className='fw-bold'>Actions:</span>
                                  <div>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        size='sm'
                                        variant='success'
                                        id='dropdown-basic'
                                      >
                                        Actions
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item href='#/action-1'>Coming soon</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </ListGroup.Item>
                            </ListGroup>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>

      {isOpen && <ModalAddUser isOpen={isOpen} onClose={onClose} refetch={refetch} />}
      {isOpenManage && <ModalAddManage isOpen={isOpenManage} onClose={onCloseManage} />}
      {isOpenOffCanva && (
        <OffCanvaAsideFilters
          isOpen={isOpenOffCanva}
          onClose={onCloseOffCanva}
          setTabFilters={setTabFilters}
          tabFilters={tabFilters}
          onOpenManage={onOpenManage}
        />
      )}
    </>
  )
}

import {useLazyQuery} from '@apollo/client'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {Button, Card, Collapse, Spinner, Table} from 'react-bootstrap'
import DatePicker from 'react-multi-date-picker'
import {IResTransactions} from '../../../types/transactions'
import {GET_TRANSACTIONS} from '../../../gql/queries/transactionsQuery'
import {toast} from 'react-toastify'
import {useDisclourse} from '../../../hooks/useDisclourse'
import {ModalAddTransaction} from './ModalAddTransaction'
import {parsePrice} from '../../../helpers'

interface IViewOneProps {
  infoWallet: {id: string; balance: string}
  refetchTransaction: () => void
}

const START_DATE = moment().startOf('month').format('YYYY-MM-DD')
const END_DATE = moment().format('YYYY-MM-DD')

export const ViewOneWallet: React.FC<IViewOneProps> = ({infoWallet, refetchTransaction}) => {
  const [gqlGetTransaction, {data, loading, error, refetch}] = useLazyQuery<IResTransactions>(
    GET_TRANSACTIONS,
    {fetchPolicy: 'no-cache'}
  )

  const [startDate, setStartDate] = useState(START_DATE)
  const [endDate, setEndDate] = useState(END_DATE)

  const {isOpen, onClose, onOpen} = useDisclourse()

  const [infoAddTransaction, setInfoAddTransaction] = useState<string | null>(null)

  useEffect(() => {
    if (!infoWallet) return
    const {id} = infoWallet
    if (!id || !Number(id)) return
    if (startDate === 'Invalid date' || endDate === 'Invalid date') return
    const filters = {
      walletId: id,
      startDate,
      endDate,
    }
    gqlGetTransaction({
      variables: {
        filters,
      },
    })
  }, [endDate, gqlGetTransaction, infoWallet, startDate])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  return (
    <>
      <Collapse in={Boolean(infoWallet)} dimension='width'>
        <Card.Body>
          <div className='d-flex flex-column gap-3 flex-md-row align-items-start align-items-md-center justify-content-between '>
            <div className='d-flex align-items-center gap-3'>
              <h4 style={{padding: 0, margin: 0}}>
                All transactions -{' '}
                <span className='text-info'>{parsePrice(infoWallet.balance)}</span>
              </h4>
            </div>
            <div className='d-flex gap-2 flex-column flex-md-row'>
              <DatePicker
                value={[startDate, endDate]}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: 'inherit',
                  width: '100%',
                  textAlign: 'center',
                }}
                onChange={(val) => {
                  const [uno, dos] = val
                  if (!uno || !dos) return
                  setStartDate(moment(uno.toDate()).format('YYYY-MM-DD'))
                  setEndDate(moment(dos.toDate()).format('YYYY-MM-DD'))
                }}
                range
                rangeHover
                containerClassName='btn btn-info btn-sm'
              />
              <Button
                size='sm'
                onClick={() => {
                  onOpen()
                  setInfoAddTransaction(infoWallet.id)
                }}
              >
                + transactions
              </Button>
            </div>
          </div>
          <div className='mt-5'>
            {!data || loading ? (
              <div className='d-flex flex-center' style={{height: '35vh'}}>
                <Spinner animation='border' />
              </div>
            ) : (
              <div>
                <Table>
                  <thead className='bg-secondary'>
                    <tr>
                      <th className='px-4'>Value</th>
                      <th className='px-4'>Balance</th>
                      <th className='px-4'>Type</th>
                      <th className='px-4'>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.transactionsByWallet.map((node) => (
                      <tr key={node.id}>
                        <td className='px-4'>{parsePrice(node.value)}</td>
                        <td className='px-4'>{parsePrice(node.balance)}</td>
                        <td className='px-4'>
                          {node.type === 'INGRESO' ? (
                            <span className='d-flex align-items-center gap-2'>
                              <i className='ms-2 fs-2 bi bi-arrow-left' style={{color: 'green'}} />
                              Income
                            </span>
                          ) : node.type === 'EGRESO' ? (
                            <span className='d-flex align-items-center gap-2'>
                              <i className='ms-2 fs-2 bi bi-arrow-right' style={{color: 'red'}} />
                              Expense
                            </span>
                          ) : null}
                        </td>
                        <td className='px-4'>
                          {moment(node.created).format('MMM DD, YYYY [at] hh:mm A')}
                        </td>
                      </tr>
                    ))}
                    {data?.transactionsByWallet.length === 0 && (
                      <tr>
                        <td colSpan={4}>
                          <div className='d-flex flex-center py-5'>
                            <h6>There is not data</h6>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </Card.Body>
      </Collapse>
      {infoAddTransaction && (
        <ModalAddTransaction
          info={infoAddTransaction}
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
          refetchTransaction={refetchTransaction}
        />
      )}
    </>
  )
}

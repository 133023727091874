import {Button, Form, Modal, Spinner} from 'react-bootstrap'
import * as Yup from 'yup'
import {IInfoUser} from '../typeMembers'
import {useFormik} from 'formik'
import {useEffect} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {ADD_MEMBERS} from '../../../gql/mutations/membersMutation'
import {toast} from 'react-toastify'
import {IResCreateMember, IResEditMember} from '../../../types/members'
import {parseId} from '../../../helpers'
import {GET_ROLES_GROUP} from '../../../gql/queries/rolesGroupQuery'
import {IResRolesGroups} from '../../../types/roles'
import {EDIT_USER} from '../../../gql/mutations/userMutations'

interface IModalAddMemberProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  infoEdit?: null | IInfoUser
}

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  lastName: Yup.string().required('Last name is required'),
  rol: Yup.string().required('Rol is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  cellphone: Yup.string().required('Phone is required'),
})

export const ModalAddMember = ({isOpen, onClose, infoEdit, refetch}: IModalAddMemberProps) => {
  const {
    data: dataRoles,
    loading: loadingRoles,
    error: errorRoles,
  } = useQuery<IResRolesGroups>(GET_ROLES_GROUP, {fetchPolicy: 'no-cache'})

  const [gqlCreate, {data, loading, error}] = useMutation<IResCreateMember>(ADD_MEMBERS)
  const [gqlEdit, {data: dataEdit, loading: loadingEdit, error: errorEdit}] =
    useMutation<IResEditMember>(EDIT_USER)
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      rol: '',
      email: '',
      cellphone: '',
    },
    validationSchema: validationSchema,
    onSubmit: (v) => {
      const userData = {
        ...v,
        rol: [parseId(v.rol)],
      }
      if (infoEdit) {
        gqlEdit({
          variables: {
            userData,
            userId: parseId(infoEdit.id),
          },
        })
      } else {
        gqlCreate({
          variables: {
            userData,
          },
        })
      }
    },
  })

  useEffect(() => {
    if (infoEdit) {
      formik.setValues({
        email: infoEdit.email,
        lastName: infoEdit.lastName,
        firstName: infoEdit.name,
        cellphone: infoEdit.phone,
        rol: infoEdit.rol,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoEdit])
  useEffect(() => {
    if (errorEdit) toast.error(`Error: ${errorEdit.message}`)
  }, [errorEdit])

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])
  useEffect(() => {
    if (errorRoles) toast.error(`Error: ${errorRoles.message}`)
  }, [errorRoles])

  useEffect(() => {
    if (!data) return
    if (data?.createUserMutation?.user?.id) {
      toast.success('user added successfully')
      refetch()
      onClose()
    } else if (data?.createUserMutation.errors?.length > 0) {
      toast.error(`Error: ${data?.createUserMutation.errors[0]?.message || 'Something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (!dataEdit) return
    if (dataEdit?.updateUserMutation?.user?.id) {
      toast.success('user updated successfully')
      refetch()
      onClose()
    } else if (dataEdit?.updateUserMutation.errors?.length > 0) {
      toast.error(`Error: ${dataEdit?.updateUserMutation.errors[0]?.message || 'Something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit])

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{infoEdit ? 'Edit' : 'Add'} Member</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className='d-flex gap-3 flex-column'>
            <Form.Group controlId='name'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter name'
                name='name'
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={Boolean(formik.touched.firstName && formik.errors.firstName)}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='lastName'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter last name'
                name='lastName'
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={Boolean(formik.touched.lastName && formik.errors.lastName)}
              />
              <Form.Control.Feedback type='invalid'>{formik.errors.lastName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='rol'>
              <Form.Label>
                Rol <small className='text-danger'>(required)</small>
              </Form.Label>
              <Form.Select
                name='rol'
                value={formik.values.rol}
                onChange={formik.handleChange}
                isInvalid={Boolean(formik.errors.rol && formik.touched.rol)}
                disabled={loadingRoles}
              >
                {loadingRoles ? (
                  <option value=''>Loading...</option>
                ) : (
                  <option value=''>Select One</option>
                )}
                {dataRoles?.allGroups.edges.map(({node}) => (
                  <option key={node.id} value={node.id}>
                    {node.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{formik.errors.rol}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter Email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={Boolean(formik.touched.email && formik.errors.email)}
              />
              <Form.Control.Feedback type='invalid'>{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='phone'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Phone'
                name='cellphone'
                value={formik.values.cellphone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={Boolean(formik.touched.cellphone && formik.errors.cellphone)}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.cellphone}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' variant='secondary' onClick={onClose}>
            Close
          </Button>
          <Button
            type='submit'
            variant='primary'
            disabled={loading || loadingEdit}
            className='d-flex align-items-center gap-2'
          >
            {(loading || loadingEdit) && <Spinner animation='border' size='sm' color='light' />}
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

import {Button, Form, Modal, Spinner} from 'react-bootstrap'
import {IMembersNode, IResAddAssignmentGroup} from '../../../types/assignmentGroups'
import {useLazyQuery, useMutation} from '@apollo/client'
import {GET_FILTERS_MEMBERS} from '../../../gql/queries/membersQuery'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {IResAllMembers} from '../../../types/members'
import {parseId} from '../../../helpers'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import {CREATE_EDIT_GROUP} from '../../../gql/mutations/assignmentGroupMutation'

interface IModalGroups {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  infoGroup?: null | {id: string; name: string; members: IMembersNode[]}
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
})

export const ModalAddAssignmetGroup: React.FC<IModalGroups> = ({
  isOpen,
  onClose,
  infoGroup,
  refetch,
}) => {
  const [gqlCreate, {data: dataCreate, loading: loadingCreate, error: errorCreate}] =
    useMutation<IResAddAssignmentGroup>(CREATE_EDIT_GROUP)

  const [gqlGetFilterMembers, {data, loading, error}] = useLazyQuery<IResAllMembers>(
    GET_FILTERS_MEMBERS,
    {fetchPolicy: 'no-cache'}
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      user: [] as (string | number)[],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const input = {
        ...values,
        id: infoGroup ? parseId(infoGroup.id) : undefined,
      }
      gqlCreate({
        variables: {
          input,
        },
      })
    },
  })

  useEffect(() => {
    gqlGetFilterMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!infoGroup) return
    formik.setValues({
      name: infoGroup.name,
      user: infoGroup.members.map(({node}) => parseId(node.id)),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoGroup])

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.groupAssignmentCreateupdateMutation?.groupAssignment?.id) {
      toast.success('Assignment group created successfully')
      refetch()
      onClose()
    } else if (dataCreate.groupAssignmentCreateupdateMutation?.errors?.length > 0) {
      toast.error(
        `Error ${
          dataCreate.groupAssignmentCreateupdateMutation.errors[0]?.messages?.[0] ||
          'Something happened'
        }`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreate])

  useEffect(() => {
    if (errorCreate) toast.error('Error: ' + errorCreate.message)
  }, [errorCreate])

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{infoGroup ? `Edit ${infoGroup.name}` : 'Add Assignment Group'}</Modal.Title>
      </Modal.Header>
      {loading || !data ? (
        <>
          <Modal.Body>
            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control placeholder='Enter Group name' disabled />
            </Form.Group>
            <div className='mt-8'>
              <h6 className='mb-4'>Members</h6>
              <div
                className='w-100 d-flex align-items-center justify-content-center'
                style={{height: '70px'}}
              >
                <Spinner animation='border' />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled variant='secondary'>
              Close
            </Button>
            <Button disabled variant='primary'>
              Save Changes
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder='Enter Group name'
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={Boolean(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type='invalid'>{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>
            <div
              className='mt-8'
              style={{
                overflowY: 'auto',
                maxHeight: '350px',
              }}
            >
              <h6 className='mb-4'>Members</h6>
              {data.allUsers.edges.map(({node}) => (
                <div key={node.id} className='mb-3'>
                  <Form.Check
                    inline
                    label={
                      <div className='d-flex flex-column p-0'>
                        <p className='p-0 m-0'>
                          {node.firstName} {node.lastName}
                        </p>
                        <small>{node.rol}</small>
                      </div>
                    }
                    name='group1'
                    checked={formik.values.user.includes(parseId(node.id))}
                    id={`check-${parseId(node.id)}`}
                    onChange={(e) => {
                      const isChecked = e.target.checked
                      if (isChecked) {
                        formik.setFieldValue('user', [...formik.values.user, parseId(node.id)])
                      } else {
                        const updatedMembers = formik.values.user.filter(
                          (m) => m !== parseId(node.id)
                        )
                        formik.setFieldValue('user', updatedMembers)
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type='button' variant='secondary' onClick={onClose}>
              Close
            </Button>
            <Button
              type='submit'
              variant='primary'
              disabled={loadingCreate}
              className='d-flex align-items-center gap-2'
            >
              {loadingCreate && <Spinner size='sm' animation='border' />}
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

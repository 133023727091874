import {gql} from '@apollo/client'

export const CREATE_USER = gql`
  mutation createUserMutation($userData: UserInput!) {
    createUserMutation(userData: $userData) {
      user {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const EDIT_USER = gql`
  mutation updateUserMutation($userData: UserInput!, $userId: ID!) {
    updateUserMutation(userData: $userData, userId: $userId) {
      user {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const UPDATE_PASSWORD_ADMIN = gql`
  mutation changeUserPasswordMutation($nuevaClave: String!, $userId: ID!) {
    changeUserPasswordMutation(nuevaClave: $nuevaClave, userId: $userId) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const CHANGE_OWNER_PASSWORD = gql`
  mutation changeOwnPasswordMutation($claveActual: String!, $nuevaClave: String!) {
    changeOwnPasswordMutation(claveActual: $claveActual, nuevaClave: $nuevaClave) {
      success
      errors {
        field
        message
      }
    }
  }
`

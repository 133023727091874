import {gql} from '@apollo/client'

export const GET_ALL_WALLETS = gql`
  query allWallet(
    $userId: Int
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $varglobal: Boolean
  ) {
    allWallet(
      userId: $userId
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      varglobal: $varglobal
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          created
          varglobal
          balance
        }
      }
    }
  }
`

export const GET_ALL_WALLETS_BALANCE = gql`
  query allWallet($userId: Int) {
    allWallet(userId: $userId) {
      edges {
        node {
          id
          balance
        }
      }
    }
  }
`

import React, {Dispatch, SetStateAction} from 'react'
import {Button, ListGroup, Offcanvas} from 'react-bootstrap'

const FILTERS_NAME_ARR = [
  {name: 'Agentes Independientes', quantity: 0, color: 'primary'},
  {name: 'Call Center USA', quantity: 7, color: 'dark'},
  {name: 'Contratos', quantity: 3, color: 'light'},
  {name: 'Grupo Roberto Ramirez', quantity: 4, color: 'success'},
  {name: 'Operaciones', quantity: 5, color: 'warning'},
  {name: 'Procesamiento', quantity: 3, color: 'success'},
  {name: 'Prueba comisiones', quantity: 3, color: 'warning'},
  {name: 'Support', quantity: 11, color: 'dark'},
]

export const OffCanvaAsideFilters: React.FC<{
  isOpen: boolean
  onClose: () => void
  tabFilters: string
  setTabFilters: Dispatch<SetStateAction<string>>
  onOpenManage: () => void
}> = ({isOpen, onClose, setTabFilters, tabFilters, onOpenManage}) => {
  return (
    <Offcanvas show={isOpen} onHide={onClose}>
      <Offcanvas.Header closeButton>
        <span />
      </Offcanvas.Header>
      <Offcanvas.Body style={{scrollbarWidth: 'none'}}>
        {/* <div
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
            backgroundColor: '#fff',
            marginBottom: '20px',
            borderRadius: '4px',
            padding: '8px 10px',
          }}
        > */}
        <div
          style={{
            borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
            paddingBottom: '7px',
          }}
        >
          <h5 style={{fontSize: '13px'}}>New agent application form URL:</h5>
          <a href='#/'>app.apizeal.com/agency/maxcapital</a>
        </div>

        <div className='my-4'>
          <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
            <span className='fw-bold'>Active users</span>
            <span className='badge badge-success'>90</span>
          </div>
          <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
            <span className='fw-bold'>Inctive users</span>
            <span className='badge badge-danger'>10</span>
          </div>
          <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
            <span className='fw-bold'>Candidates</span>
            <span className='badge badge-primary'>0</span>
          </div>
        </div>

        <div>
          <div
            style={{
              borderBottom: '2px solid rgba(93, 120, 255, 0.2)',
            }}
            className='d-flex align-items-center justify-content-between'
          >
            <h5>User Groups</h5>
            <Button
              variant='link'
              onClick={() => {
                onClose()
                onOpenManage()
              }}
            >
              <i className='bi bi-pencil-square' style={{color: 'inherit'}} /> Manage
            </Button>
          </div>
          <ListGroup className='mt-5' variant='flush'>
            <ListGroup.Item
              action
              variant={tabFilters === 'All Users' ? 'primary' : 'light'}
              className='my-2 py-4'
              onClick={() => setTabFilters('All Users')}
            >
              <i className='bi bi-person-fill fs-3' style={{color: 'inherit'}} /> All Users
            </ListGroup.Item>
            {FILTERS_NAME_ARR.map((lis) => (
              <ListGroup.Item
                key={lis.name}
                action
                variant={tabFilters === lis.name ? 'primary' : 'light'}
                className='my-1 py-4 d-flex align-items-center justify-content-between'
                onClick={() => setTabFilters(lis.name)}
              >
                <span className='d-flex align-items-center justify-content-between gap-1'>
                  <i className='bi bi-file-person-fill fs-3' style={{color: 'inherit'}} />{' '}
                  {lis.name}
                </span>
                <span className={`badge badge-${lis.color}`}>{lis.quantity}</span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
        {/* </div> */}
      </Offcanvas.Body>
    </Offcanvas>
  )
}

import {gql} from '@apollo/client'

export const GET_MEMBERS_FILTER = gql`
  query allMembersPolicy($firstName_Icontains: String) {
    allMembersPolicy(firstName_Icontains: $firstName_Icontains) {
      edges {
        node {
          id
          firstName
          middleName
          lastName
          secondLastName
        }
      }
    }
  }
`

export const GET_MEMBERS_ALL_USERS_FILTER = gql`
  query allUsers($email_Icontains: String) {
    allUsers(email_Icontains: $email_Icontains) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`

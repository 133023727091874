import {Button, Col, Form, Modal, Row, Spinner} from 'react-bootstrap'
import Select from 'react-select'
import {EdgeMemberPolicy, IResAllTagsDocuments} from '../../../../types/policiesApi'
import {useFormik} from 'formik'
import {ChangeEvent, useEffect} from 'react'
import {useQuery} from '@apollo/client'
import {GET_DOCUMENTS_POLICY} from '../../../../gql/queries/policiesApiQueries'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useUploadFile} from '../../../../hooks/useUploadFile'
import {ADD_DOCUMENT_POLICY_STR} from '../../../../gql/mutations/policyMutation'
import {parseId} from '../../../../helpers'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  attachments: Yup.mixed().test('fileType', 'Attachment must be a valid file', (value) => {
    return value instanceof File
  }),
  // expirationDate: Yup.string().required('Expiration Date is required'),
  // documentTagId: Yup.string().required('Document Tag is required'),
  // comments: Yup.string().max(100, 'Comments cannot exceed 100 characters'),
})

const INIT_FORM = {
  name: '',
  attachments: undefined as undefined | File,
  memberPolicyId: '',
  expirationDate: '',
  documentTagId: '',
  comments: '',
}

interface IResUploadFile {
  createAttachments: {success: boolean; errors?: {message: string}[]}
}

interface IModalProps {
  id: string
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  members: EdgeMemberPolicy[]
}
export const ModalAddDocsPolicy: React.FC<IModalProps> = ({
  isOpen,
  onClose,
  members,
  id,
  refetch,
}) => {
  const [uploadFile, {data, error, loading}] = useUploadFile<IResUploadFile>()

  const {
    data: dataTags,
    loading: loadingTags,
    error: errorTags,
  } = useQuery<IResAllTagsDocuments>(GET_DOCUMENTS_POLICY, {
    fetchPolicy: 'no-cache',
  })
  const formik = useFormik({
    initialValues: INIT_FORM,
    validationSchema,
    onSubmit: (v) => {
      if (!v.attachments) return
      console.log('v', v)
      const formdata = new FormData()
      formdata.append('0', v.attachments, v.attachments.name)
      const map = {'0': ['variables.attachmentsData.attachments']}
      const obj = {
        query: ADD_DOCUMENT_POLICY_STR,
        variables: {
          attachmentsData: {
            name: v.name,
            attachments: null,
            policyId: Number(id),
            memberPolicyId: v.memberPolicyId ? parseId(v.memberPolicyId) : undefined,
            expirationDate: v.expirationDate || undefined,
            documentTagId: v.documentTagId ? parseId(v.documentTagId) : undefined,
            comments: v.comments,
          },
        },
      }
      formdata.append('map', JSON.stringify(map))
      formdata.append('operations', JSON.stringify(obj))
      uploadFile(formdata)
    },
  })

  useEffect(() => {
    if (error) toast.error(`Error: ${error.toString()}`)
  }, [error])
  useEffect(() => {
    if (errorTags) toast.error(`Error: ${errorTags.message}`)
  }, [errorTags])

  useEffect(() => {
    if (!data) return
    if (data.createAttachments.success) {
      onClose()
      refetch()
      toast.success('File uploaded successfully')
    } else if (data.createAttachments.errors && data.createAttachments.errors.length > 0) {
      toast.error(`Error: ${data.createAttachments.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-items-center gap-2'>
          Add Documents {loading && <Spinner animation='border' size='sm' />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} id='formId'>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  isInvalid={Boolean(formik.errors.name && formik.touched.name)}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  name='name'
                  id='name'
                  placeholder='File name'
                />
                {formik.errors.name && formik.touched.name && (
                  <Form.Text className='text-danger'>{formik.errors.name}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Expiration</Form.Label>
                <Form.Control
                  isInvalid={Boolean(formik.errors.expirationDate && formik.touched.expirationDate)}
                  onBlur={formik.handleBlur}
                  value={formik.values.expirationDate}
                  onChange={formik.handleChange}
                  name='expirationDate'
                  id='expirationDate'
                  type='date'
                  placeholder='name@example.com'
                />
                {formik.errors.expirationDate && formik.touched.expirationDate && (
                  <Form.Text className='text-danger'>{formik.errors.expirationDate}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className='my-3'>
                <Form.Label>Document</Form.Label>
                <Form.Control
                  name='attachments'
                  isInvalid={Boolean(formik.errors.attachments && formik.touched.attachments)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'attachments',
                      (e as ChangeEvent<HTMLInputElement>)?.target?.files?.[0]
                    )
                  }}
                  type='file'
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className='my-3'>
                <Form.Label>Member</Form.Label>
                <Select
                  name='memberPolicyId'
                  onBlur={formik.handleBlur}
                  isClearable
                  onChange={(e) => {
                    if (e) {
                      formik.setFieldValue('memberPolicyId', e.value)
                    } else {
                      formik.setFieldValue('memberPolicyId', '')
                    }
                  }}
                  options={members.map(({node}) => ({
                    label: `${node.firstName} ${node.lastName}`,
                    value: node.id,
                  }))}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId='select2' className='my-3'>
                <Form.Label>Tag</Form.Label>
                <Select
                  isClearable
                  onChange={(e) => {
                    if (e) {
                      formik.setFieldValue('documentTagId', e.value)
                    } else {
                      formik.setFieldValue('documentTagId', '')
                    }
                  }}
                  options={
                    dataTags?.allAttachmentsTags.edges.map(({node}) => ({
                      label: `${node.tagName}}`,
                      value: node.id,
                    })) || []
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className='mt-3'>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              name='comments'
              onChange={formik.handleChange}
              value={formik.values.comments}
              as='textarea'
              rows={3}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose} type='button'>
          Close
        </Button>
        <Button
          variant='primary'
          type='submit'
          form='formId'
          formAction='formId'
          disabled={loading || loadingTags}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

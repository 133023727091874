import {Button, Modal, Spinner} from 'react-bootstrap'
import {CHANGE_STATE_LEAD} from '../../../gql/mutations/leadMutations'
import {IResChangeState} from '../../leads/leadTypes'
import {useMutation} from '@apollo/client'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {parseId} from '../../../helpers'

export const ModalChangeStateWizard: React.FC<{
  info: {id: string; name: string}
  refetch: () => void
  onClose: () => void
  isOpen: boolean
  leadId: string
}> = ({info, isOpen, onClose, refetch, leadId}) => {
  const [gqlChange, {data: dataChange, loading: loadingChange, error: errorChange}] =
    useMutation<IResChangeState>(CHANGE_STATE_LEAD)

  useEffect(() => {
    if (errorChange) {
      toast.error(`Error: ${errorChange.message}`)
    }
  }, [errorChange])

  useEffect(() => {
    if (!dataChange) return
    if (dataChange.changeLeadStage.success) {
      toast.success('Lead has changed state successfully')
      refetch()
      onClose()
    } else if (dataChange.changeLeadStage.errors && dataChange.changeLeadStage.errors.length > 0) {
      toast.error(`Error: ${dataChange.changeLeadStage.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChange])

  const handleChangeStage = () => {
    gqlChange({
      variables: {
        leadId: leadId,
        stageId: parseId(info.id),
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change stage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className='text-center'>
          This action will change the lead to the <span className='text-danger'>"{info.name}"</span>{' '}
          stage
        </h6>
        <h2 className='text-center'>Are you sure you want to change the stage of this lead?</h2>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
        <Button variant='primary' onClick={handleChangeStage} disabled={loadingChange}>
          {loadingChange && <Spinner animation='border' size='sm' />}
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

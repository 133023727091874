import {Button, Spinner} from 'react-bootstrap'
import {useDisclourse} from '../../hooks/useDisclourse'
import {useEffect, useState} from 'react'
import {useQuery} from '@apollo/client'
import {toast} from 'react-toastify'
import {GET_ALL_TAGS} from '../../gql/queries/tagsQuery'
import {IResTags} from '../../types/tags'
import {ModalAddTag} from './components/ModalAddTag'

export const TagManagerPage = () => {
  const {data, loading, error, refetch} = useQuery<IResTags>(GET_ALL_TAGS, {
    fetchPolicy: 'no-cache',
  })

  const {isOpen, onClose, onOpen} = useDisclourse()
  const [infoEdit, setInfoEdit] = useState<null | {name: string; id: string; color: string}>(null)

  useEffect(() => {
    if (error) {
      toast.error('Error: ' + error.message)
    }
  }, [error])

  if (!data || loading) {
    return (
      <div>
        <div className='d-flex align-items-center justify-content-between'>
          <h2>Tag Manager</h2>
          <Button>Add Tag</Button>
        </div>
        <div className='d-flex align-items-center justify-content-center' style={{height: '40vh'}}>
          <Spinner animation='border' />
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <div className='d-flex align-items-center justify-content-between'>
          <h2>Tag Manager</h2>
          <Button
            onClick={() => {
              onOpen()
              setInfoEdit(null)
            }}
          >
            Add Tag
          </Button>
        </div>
        <div>
          <table className='table gs-7 gy-7 gx-7'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th>Tag Name</th>
                <th>Color</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {data.allTagInsurances.edges.map(({node}) => (
                <tr key={node.id}>
                  <td>{node.name}</td>
                  <td>
                    <span className='badge text-light' style={{backgroundColor: node.color}}>
                      {node.name}
                    </span>
                  </td>
                  <td>
                    <div>
                      <button
                        className='bg-transparent border-0 text-info'
                        onClick={() => {
                          onOpen()
                          setInfoEdit({
                            id: node.id,
                            name: node.name,
                            color: node.color,
                          })
                        }}
                      >
                        <i className='bi bi-pencil' style={{color: 'inherit'}} title='Edit tag' />
                      </button>
                      <button className='bg-transparent border-0 text-danger' title='Delete tag'>
                        <i className='bi bi-trash' style={{color: 'inherit'}} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              {data.allTagInsurances.edges.length === 0 && (
                <tr>
                  <td colSpan={3} className='text-center'>
                    There are no tags
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isOpen && (
        <ModalAddTag isOpen={isOpen} onClose={onClose} refetch={refetch} infoEdit={infoEdit} />
      )}
    </>
  )
}

import {Accordion, Button, Card, Col, Modal, Row} from 'react-bootstrap'
import {Plan} from '../../oneLead/oneleadTypes'
import {Tooltip} from 'react-tooltip'

const ICONS = {
  'Cost for medical care': 'heart-pulse',
  'Prescription drug coverage': 'capsule-pill',
  'Access to doctors and hospitals': 'universal-access',
  'Urgent care and hospital services': 'hospital-fill',
  'Adult dental coverage': 'emoji-smile',
  'Child dental coverage': 'emoji-smile',
  'Other services': 'hearts',
} as const

const ARR_NOT_OTHERS = [
  'PRIMARY_CARE_VISIT_TO_TREAT_AN_INJURY_OR_ILLNESS',
  'SPECIALIST_VISIT',
  'X_RAYS_AND_DIAGNOSTIC_IMAGING',
  'IMAGING_CT_PET_SCANS_MRIS',
  'LABORATORY_OUTPATIENT_AND_PROFESSIONAL_SERVICES',
  'OUTPATIENT_FACILITY_FEE_EG_AMBULATORY_SURGERY_CENTER',
  'OUTPATIENT_SURGERY_PHYSICIAN_SURGICAL_SERVICES',
  'HEARING_AIDS',
  'ROUTINE_EYE_EXAM_ADULT',
  'ROUTINE_EYE_EXAM_FOR_CHILDREN',
  'EYE_GLASSES_FOR_CHILDREN',
  'GENERIC_DRUGS',
  'PREFERRED_BRAND_DRUGS',
  'NON_PREFERRED_BRAND_DRUGS',
  'SPECIALTY_DRUGS',
  'PRENATAL_AND_POSTNATAL_CARE',
  'WELL_BABY_VISITS_AND_CARE',
  'URGENT_CARE_CENTERS_OR_FACILITIES',
  'EMERGENCY_ROOM_SERVICES',
  'INPATIENT_PHYSICIAN_AND_SURGICAL_SERVICES',
  'INPATIENT_HOSPITAL_SERVICES_EG_HOSPITAL_STAY',
  'EMERGENCY_TRANSPORTATION_AMBULANCE',
  'ROUTINE_DENTAL_SERVICES_ADULT',
  'BASIC_DENTAL_CARE_ADULT',
  'MAJOR_DENTAL_CARE_ADULT',
  'ORTHODONTIA_ADULT',
  'ACCIDENTAL_DENTAL',
  'DENTAL_CHECK_UP_FOR_CHILDREN',
  'MAJOR_DENTAL_CARE_CHILD',
  'BASIC_DENTAL_CARE_CHILD',
  'ORTHODONTIA_CHILD',
]

const OBJ_BENEFITS = {
  'Cost for medical care': [
    'PRIMARY_CARE_VISIT_TO_TREAT_AN_INJURY_OR_ILLNESS',
    'SPECIALIST_VISIT',
    'X_RAYS_AND_DIAGNOSTIC_IMAGING',
    'IMAGING_CT_PET_SCANS_MRIS',
    'LABORATORY_OUTPATIENT_AND_PROFESSIONAL_SERVICES',
    'OUTPATIENT_FACILITY_FEE_EG_AMBULATORY_SURGERY_CENTER',
    'OUTPATIENT_SURGERY_PHYSICIAN_SURGICAL_SERVICES',
    'HEARING_AIDS',
    'ROUTINE_EYE_EXAM_ADULT',
    'ROUTINE_EYE_EXAM_FOR_CHILDREN',
    'EYE_GLASSES_FOR_CHILDREN',
  ],
  'Prescription drug coverage': [
    'GENERIC_DRUGS',
    'PREFERRED_BRAND_DRUGS',
    'NON_PREFERRED_BRAND_DRUGS',
    'SPECIALTY_DRUGS',
  ],
  'Access to doctors and hospitals': ['PRENATAL_AND_POSTNATAL_CARE', 'WELL_BABY_VISITS_AND_CARE'],
  'Urgent care and hospital services': [
    'URGENT_CARE_CENTERS_OR_FACILITIES',
    'EMERGENCY_ROOM_SERVICES',
    'INPATIENT_PHYSICIAN_AND_SURGICAL_SERVICES',
    'INPATIENT_HOSPITAL_SERVICES_EG_HOSPITAL_STAY',
    'EMERGENCY_TRANSPORTATION_AMBULANCE',
  ],
  'Adult dental coverage': [
    'ROUTINE_DENTAL_SERVICES_ADULT',
    'BASIC_DENTAL_CARE_ADULT',
    'MAJOR_DENTAL_CARE_ADULT',
    'ORTHODONTIA_ADULT',
    'ACCIDENTAL_DENTAL',
  ],
  'Child dental coverage': [
    'DENTAL_CHECK_UP_FOR_CHILDREN',
    'MAJOR_DENTAL_CARE_CHILD',
    'BASIC_DENTAL_CARE_CHILD',
    'ORTHODONTIA_CHILD',
  ],
} as const

interface IModalProps {
  info: Plan
  isOpen: boolean
  onClose: () => void
}

export const ModalInfoPlan = ({info: plan, isOpen, onClose}: IModalProps) => {
  console.log('plan', plan)
  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Plan details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor: '#f2f3f8'}}>
        <Card className='rounded-1 mb-4'>
          <Card.Header className='align-items-center'>
            <Card.Title>{plan.issuer.name}</Card.Title>
          </Card.Header>
          <Card.Body>
            <h4 className='text-info fs-3'>{plan.name}</h4>
            <div className='d-flex align-items-center mb-1 flex-wrap' style={{fontSize: '11px'}}>
              <span>{plan.metal_level}</span>
              <span>
                <i className='bi bi-dot fs-1' />
              </span>
              <span>{plan.type}</span>
              <span>
                <i className='bi bi-dot fs-1' />
              </span>
              <span>
                <span className='fw-bold'>Plan ID:</span> {plan.id}
              </span>
              <span>
                <i className='bi bi-dot fs-1' />
              </span>
              <span>
                {[1, 2, 3, 4, 5].map((n) => (
                  <i
                    className='bi bi-star-fill'
                    key={n}
                    style={{color: n < plan.quality_rating.global_rating ? '#da9400' : '#565454'}}
                  />
                ))}
              </span>
            </div>
            <div className='d-flex  gap-1 flex-wrap flex-column flex-lg-row'>
              <Card style={{backgroundColor: '#f1f3ff', flex: 1}}>
                <Card.Body>
                  <h6>Monthly premium:</h6>
                  <div>
                    <h3 className='fs-2 fw-bold'>
                      <small>
                        <sup>$</sup>
                      </small>
                      {Math.floor(Number(plan.premium))}
                      <small>
                        <sup>.{Number(plan.premium).toFixed(2).slice(-2)}</sup>
                      </small>
                    </h3>
                  </div>
                  <p className='m-0 p-0 text-muted'>
                    Savings total:{' '}
                    <span className='text-success fw-bold'>
                      {Number(Number(plan.ehb_premium) - Number(plan.premium)).toFixed(2)}
                    </span>
                  </p>
                  <p className='m-0 p-0 text-muted'>
                    Plan was: <span className='text-danger fw-bold'>{plan.ehb_premium}</span>
                  </p>
                </Card.Body>
              </Card>
              <Card style={{backgroundColor: '#f1f3ff', flex: 1}}>
                <Card.Body>
                  <h6>Deductible:</h6>
                  <div>
                    <span className='fs-2 fw-bold'>{plan.deductibles?.[0]?.amount || 'N/R'}</span>
                  </div>

                  <p className='m-0 p-0 text-muted'>
                    Individual total (${plan.deductibles?.[0]?.amount || 'N/R'} per person)
                  </p>

                  <p className='m-0 p-0 text-muted'> {plan.deductibles?.[0]?.type || 'N/R'}</p>
                </Card.Body>
              </Card>
              <Card style={{backgroundColor: '#f1f3ff', flex: 1}}>
                <Card.Body>
                  <h6>Out-of-pocket max:</h6>
                  <div>
                    <span className='fs-2 fw-bold'>{plan.moops?.[0]?.amount}</span>
                  </div>

                  <p className='m-0 p-0 text-muted'>Individual total</p>

                  <p className='m-0 p-0 text-muted'>
                    Maximum for Medical and Drug {plan.moops?.[0]?.type}
                  </p>
                </Card.Body>
              </Card>
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <a
                  className='btn btn-outline-info btn-sm'
                  style={{whiteSpace: 'nowrap'}}
                  href={plan.benefits_url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-box-arrow-up-right' style={{color: 'inherit'}} /> Summary
                  benefits
                </a>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <a
                  className='btn btn-outline-info btn-sm'
                  style={{whiteSpace: 'nowrap'}}
                  href={plan.brochure_url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-box-arrow-up-right' style={{color: 'inherit'}} /> Plan
                  Brochure
                </a>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <a
                  className='btn btn-outline-info btn-sm'
                  style={{whiteSpace: 'nowrap'}}
                  href={plan.network_url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-box-arrow-up-right' style={{color: 'inherit'}} /> Provider
                  directory
                </a>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <a
                  className='btn btn-outline-info btn-sm'
                  style={{whiteSpace: 'nowrap'}}
                  href={plan.formulary_url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-box-arrow-up-right' style={{color: 'inherit'}} /> Covered
                  drugs
                </a>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
        <Accordion>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>
              <span className='me-3'>
                <i className='bi bi-currency-dollar fs-1' style={{color: 'inherit'}} />
              </span>{' '}
              <span className='fw-bold fs-2'>Deductible</span>
            </Accordion.Header>
            <Accordion.Body>
              <table className='table table-striped' style={{tableLayout: 'fixed'}}>
                <thead>
                  <tr>
                    <th className='py-1'>&nbsp;</th>
                    <th className='py-1 fw-bold'>In Network</th>
                    <th className='py-1 fw-bold'>Out of Network</th>
                  </tr>
                </thead>
                <tbody>
                  {plan.tiered_deductibles.map((dedu, idx) => {
                    if (dedu.network_tier === 'Out-of-Network') return null
                    return (
                      <tr key={idx}>
                        <td className='fw-bold'>Deductible</td>
                        <td>
                          <span className='fw-bold'>{dedu.type}</span>
                          <br />
                          {dedu.network_tier}: ${dedu.amount} {dedu.family_cost}
                        </td>
                        <td></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
          {Object.keys(OBJ_BENEFITS).map((k, idx: number) => (
            <Accordion.Item key={idx} eventKey={`${idx + 1}`} style={{marginTop: '20px'}}>
              <Accordion.Header>
                <span className='me-3'>
                  <i className={`bi bi-${ICONS[k] || 'box'} fs-1`} style={{color: 'inherit'}} />
                </span>{' '}
                <span className='fw-bold fs-2'>{k}</span>
              </Accordion.Header>
              <Accordion.Body>
                <table className='table table-striped' style={{tableLayout: 'fixed'}}>
                  <thead>
                    <tr>
                      <th className='py-1'>&nbsp;</th>
                      <th className='py-1 fw-bold'>In Network</th>
                      <th className='py-1 fw-bold'>Out of Network</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OBJ_BENEFITS[k as keyof typeof OBJ_BENEFITS]?.map(
                      (str: string, idx: number) => {
                        if (!str) return null
                        const find = plan.benefits.find((ben) => ben.type === str)
                        if (!find) return null
                        if (!find.covered) {
                          return (
                            <tr>
                              <td className='fw-bold'>{find.name}</td>
                              <td>Not covered</td>
                              <td>Not covered</td>
                            </tr>
                          )
                        }
                        return (
                          <tr key={str}>
                            <td className='fw-bold'>
                              <span>{find.name}</span>
                              {find.explanation && (
                                <>
                                  <span
                                    data-tooltip-id={`${idx}-${str.replaceAll(' ', '')}`}
                                    data-tooltip-content={find.explanation}
                                  >
                                    <i
                                      className='bi bi-info-circle-fill fs-5 ms-1'
                                      style={{color: '#5867dd'}}
                                    />
                                  </span>
                                  <Tooltip
                                    id={`${idx}-${str.replaceAll(' ', '')}`}
                                    place='right'
                                    style={{
                                      backgroundColor: 'white',
                                      color: 'black',
                                      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                                      opacity: 1,
                                    }}
                                  />
                                </>
                              )}
                            </td>
                            <td>
                              {find.cost_sharings
                                .filter((cost) => cost.network_tier !== 'Out-of-Network')
                                .map((cost, idx) => {
                                  return (
                                    <p key={idx}>
                                      {cost.network_tier}: {cost.display_string}
                                      <br />
                                    </p>
                                  )
                                })}
                            </td>
                            <td>
                              {find.cost_sharings
                                .filter((cost) => cost.network_tier === 'Out-of-Network')
                                .map((cost, idx) => {
                                  return (
                                    <p key={idx}>
                                      {cost.network_tier}: {cost.display_string}
                                      <br />
                                    </p>
                                  )
                                })}
                            </td>
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          ))}
          <Accordion.Item
            eventKey={`${Object.keys(OBJ_BENEFITS).length + 1}`}
            style={{marginTop: '20px'}}
          >
            <Accordion.Header>
              <span className='me-3'>
                <i
                  className={`bi bi-${ICONS['Other services'] || 'box'} fs-1`}
                  style={{color: 'inherit'}}
                />
              </span>{' '}
              <span className='fw-bold fs-2'>Other services</span>
            </Accordion.Header>
            <Accordion.Body>
              <table className='table table-striped' style={{tableLayout: 'fixed'}}>
                <thead>
                  <tr>
                    <th className='py-1'>&nbsp;</th>
                    <th className='py-1 fw-bold'>In Network</th>
                    <th className='py-1 fw-bold'>Out of Network</th>
                  </tr>
                </thead>
                <tbody>
                  {plan.benefits
                    .filter((ben) => !ARR_NOT_OTHERS.includes(ben.type))
                    .map((ben, i) => {
                      if (!ben.covered) {
                        return (
                          <tr>
                            <td className='fw-bold'>{ben.name}</td>
                            <td>Not covered</td>
                            <td>Not covered</td>
                          </tr>
                        )
                      }
                      return (
                        <tr key={i}>
                          <td className='fw-bold'>
                            <span>{ben.name}</span>
                            {ben.explanation && (
                              <>
                                <span
                                  data-tooltip-id={`${i}-${ben.name.replaceAll(' ', '')}`}
                                  data-tooltip-content={ben.explanation}
                                >
                                  <i
                                    className='bi bi-info-circle-fill fs-5 ms-1'
                                    style={{color: '#5867dd'}}
                                  />
                                </span>
                                <Tooltip
                                  id={`${i}-${ben.name.replaceAll(' ', '')}`}
                                  place='right'
                                  style={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                                    opacity: 1,
                                  }}
                                />
                              </>
                            )}
                          </td>
                          <td>
                            {ben.cost_sharings
                              .filter((cost) => cost.network_tier !== 'Out-of-Network')
                              .map((cost, idx) => {
                                return (
                                  <p key={idx}>
                                    {cost.network_tier}: {cost.display_string}
                                    <br />
                                  </p>
                                )
                              })}
                          </td>
                          <td>
                            {ben.cost_sharings
                              .filter((cost) => cost.network_tier === 'Out-of-Network')
                              .map((cost, idx) => {
                                return (
                                  <p key={idx}>
                                    {cost.network_tier}: {cost.display_string}
                                    <br />
                                  </p>
                                )
                              })}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey={`${Object.keys(OBJ_BENEFITS).length + 2}`}
            style={{marginTop: '20px'}}
          >
            <Accordion.Header>
              <span className='me-3'>
                <i className='bi bi-star-fill fs-1' style={{color: 'inherit'}} />
              </span>{' '}
              <span className='fw-bold fs-2'>Medical management programs</span>
            </Accordion.Header>
            <Accordion.Body>
              <table className='table table-striped' style={{width: '100%', tableLayout: 'fixed'}}>
                <tbody>
                  {plan.disease_mgmt_programs.map((di, idx) => (
                    <tr key={idx}>
                      <td className='fw-bold'>{di}</td>
                      <td>Available</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
          {/* {plan.benefits.map((ben, idx) => (
            <Accordion.Item key={idx} eventKey={`${idx + 1}`}>
              <Accordion.Header>
                <span className='me-3'>
                  <i className='bi bi-box fs-1' style={{color: 'inherit'}} />
                </span>{' '}
                <span className='fw-bold fs-2'>{ben.name}</span>
              </Accordion.Header>
              <Accordion.Body>
                <table className='table table-striped' style={{tableLayout: 'fixed'}}>
                  <thead>
                    <tr>
                      <th className='py-1'>&nbsp;</th>
                      <th className='py-1 fw-bold'>In Network</th>
                      <th className='py-1 fw-bold'>Out of Network</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ben.cost_sharings.map((dedu, idx) => {
                      return (
                        <tr key={idx}>
                          <td className='fw-bold'></td>
                          <td>
                            {dedu.network_tier !== 'Out-of-Network' && (
                              <>
                                <span className='fw-bold'>{dedu.network_tier}:</span>
                                <br />
                                {dedu.display_string}
                              </>
                            )}
                          </td>
                          <td>
                            {dedu.network_tier === 'Out-of-Network' && (
                              <>
                                <span className='fw-bold'>{dedu.network_tier}:</span>
                                <br />
                                {dedu.display_string}
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          ))} */}
        </Accordion>
        <hr
          style={{
            margin: '20px 0',
            borderBottom: '1px solid #ebedf2',
          }}
        />
        <div className='section'>
          <div className='row'>
            <div className='col-12'>
              <p className='text-muted fw-bold mb-1'>English:</p>
              <p
                className='text-muted mb-2'
                style={{
                  fontSize: '.9rem',
                }}
              >
                This is not an application for health coverage. This tool is designed to preview
                available plans and prices. You should visit HealthCare.gov if you want to enroll
                members. You’ll know exactly what members will pay when you enroll them.
              </p>
              <p className='text-muted fw-bold mb-1'>Español:</p>
              <p
                className='text-muted mb-2'
                style={{
                  fontSize: '.9rem',
                }}
              >
                Esto no es una inscripción de cobertura de salud. Esta herramienta está diseñada
                para previsualizar los planes y precios disponibles. Debe visitar HealthCare.gov si
                desea inscribir a miembros. Sabrá exactamente lo que los miembros pagarán cuando se
                inscriban.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
        <Button variant='primary' onClick={onClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import {useLazyQuery} from '@apollo/client'
import {useParams} from 'react-router-dom'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {Card, Spinner} from 'react-bootstrap'
import {GET_ALL_WALLETS} from '../../../gql/queries/walletsQuery'
import {IResWallets} from '../../../types/wallets'
// import moment from 'moment'
import {ViewOneWallet} from './ViewOneWallet'
import {parseId} from '../../../helpers'

export const StatementsTab = ({refetchBalance}: {refetchBalance: () => void}) => {
  const [gqlGetWallets, {data, loading, error, refetch}] = useLazyQuery<IResWallets>(
    GET_ALL_WALLETS,
    {
      fetchPolicy: 'no-cache',
    }
  )

  // const [viewWallet, setViewWallet] = useState<{id: string; balance: string} | null>(null)

  const {id} = useParams()

  useEffect(() => {
    if (!id || !Number(id)) return
    gqlGetWallets({
      variables: {
        userId: id,
      },
    })
  }, [gqlGetWallets, id])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  const refetchData = () => {
    refetch()
    refetchBalance()
  }

  if (loading || !data) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Transactions</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className='d-flex flex-center' style={{height: '30vh'}}>
            <Spinner animation='border' />
          </div>
        </Card.Body>
      </Card>
    )
  }

  const node = data.allWallet.edges?.[0]?.node

  if (!node) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Transactions</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className='d-flex flex-center' style={{height: '30vh'}}>
            <h6>This user does not have wallet or transactions</h6>
          </div>
        </Card.Body>
      </Card>
    )
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Transactions</Card.Title>
      </Card.Header>
      <ViewOneWallet
        infoWallet={{balance: node.balance, id: `${parseId(node.id)}`}}
        refetchTransaction={refetchData}
      />
    </Card>
  )
}

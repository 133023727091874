import {useEffect, useState} from 'react'
import {Button, Collapse, Form, Spinner} from 'react-bootstrap'
import Select, {SingleValue} from 'react-select'
import {IValuesForm} from '../../soldTypes'
import {TFormKeys} from '../../../policies/policiesTypes'
import {FormikErrors, FormikTouched} from 'formik'
import {useSearchParams} from 'react-router-dom'
import {GET_MEMBERS_FILTER} from '../../../../gql/queries/memberPoliciesQuery'
import {IResMemberPolicyFilter} from '../../../../types/membersPolicy'
import {useLazyQuery} from '@apollo/client'
import {toast} from 'react-toastify'
import {useDebounce} from '../../../../../../_metronic/helpers'
import {EdgeMemberPolicy} from '../../../../types/policiesApi'
import {MembersEdit} from './MembersEdit'
import {MemberAdd} from './MemberAdd'

interface IFamiliMemsProps {
  onPrevStep: () => void
  onNextStep: () => void
  values: IValuesForm
  handleBlur: (field: TFormKeys) => void
  handleChange: (field: TFormKeys, value: string) => void
  handleSubmit: () => void
  onReturnInit: () => void
  errors: FormikErrors<IValuesForm>
  touched: FormikTouched<IValuesForm>
  loadingCreate: boolean
  members: EdgeMemberPolicy[]
  refetch: () => void
  isDisableEdit?: boolean
}

export const FamilyMembersTab: React.FC<IFamiliMemsProps> = ({
  onPrevStep,
  onNextStep,
  values,
  handleChange,
  handleSubmit,
  loadingCreate,
  onReturnInit,
  members,
  refetch,
  isDisableEdit = false,
}) => {
  const [searchParams] = useSearchParams()
  const lead = searchParams.get('lead')
  const policy = searchParams.get('policy')

  const [gqlGetMembers, {data, loading, error}] =
    useLazyQuery<IResMemberPolicyFilter>(GET_MEMBERS_FILTER)

  const [inputSelect, setInputSelect] = useState('')

  const valueDeb = useDebounce(inputSelect, 500)

  const [options, setOptions] = useState<{label: string; value: string}[]>([])
  const [arrMembers, setArrMembers] = useState<{open: boolean; id: number}[]>([])

  const [selectFindMembs, setSelectFindMembs] = useState<null | SingleValue<{
    label: string
    value: string
  }>>(null)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    setOptions(
      data.allMembersPolicy.edges.map(({node}) => ({
        label: `${node.firstName} ${node.lastName}`,
        value: `${node.id}***${node.firstName} ${node.lastName}`,
      }))
    )
  }, [data])

  useEffect(() => {
    if (!valueDeb) return
    gqlGetMembers({
      variables: {
        firstName_Icontains: valueDeb,
      },
    })
  }, [gqlGetMembers, valueDeb])

  useEffect(() => {
    console.log('options', options)
    if (!values.memberId) return

    if (options.length > 0) {
      const find = options.find(({value}) => value === values.memberId)
      setSelectFindMembs(find || null)
    } else {
      setSelectFindMembs({
        label: values.memberId.split('***')[1],
        value: values.memberId,
      })
      setOptions([
        {
          label: values.memberId.split('***')[1],
          value: values.memberId,
        },
      ])
    }
  }, [options, values.memberId])

  const handleAddPolicySubmit = () => {
    if (
      !values.policyName ||
      !values.metalLevel ||
      !values.carrier ||
      !values.effectiveDate ||
      !values.typePolicy ||
      !values.stateEU
    ) {
      return onReturnInit()
    }
    if (!values.applicants || !values.members || !values.dependents) return
    handleSubmit()
  }

  const deleteItem = (id: number) => {
    setArrMembers((p) => p.filter((m) => m.id !== id))
  }

  return (
    <div>
      <div className='kt-heading heading--md'>Applicants &amp; Dependents</div>
      {/* <Row>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <Form.Label style={{fontSize: '12px'}}>
              Number of applicants <span style={{color: 'red'}}>(required)</span>
            </Form.Label>
            <Form.Control
              placeholder='Insert number of applicants'
              onChange={(e) => handleChange('applicants', e.target.value)}
              value={values.applicants}
              type='number'
              min={0}
            />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <Form.Label style={{fontSize: '12px'}}>
              Number of members <span style={{color: 'red'}}>(required)</span>
            </Form.Label>
            <Form.Control
              placeholder='Insert number of members'
              onChange={(e) => handleChange('members', e.target.value)}
              value={values.members}
              type='number'
              min={0}
            />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <Form.Label style={{fontSize: '12px'}}>
              Number of dependents <span style={{color: 'red'}}>(required)</span>
            </Form.Label>
            <Form.Control
              placeholder='Insert number of dependents'
              onChange={(e) => handleChange('dependents', e.target.value)}
              value={values.dependents}
              type='number'
              min={0}
            />
          </Form.Group>
        </Col>
      </Row> */}
      {lead && Number(lead) ? (
        <div className='mt-6'>
          <Form.Group>
            <Form.Label>Find and select a contact</Form.Label>
            <Select
              isClearable
              isLoading={loading}
              loadingMessage={() => 'loading members'}
              placeholder='Search and select a member...'
              options={options}
              inputValue={inputSelect}
              onInputChange={setInputSelect}
              onChange={(e) => {
                setSelectFindMembs(e)
                handleChange('memberId', e ? e.value : '')
              }}
              value={selectFindMembs}
            />
            <Form.Text style={{fontSize: '12px'}}>
              Search by name, email address, phone number or last 4 digits of SSN
            </Form.Text>
          </Form.Group>
        </div>
      ) : (
        <>
          {members && members.length > 0 && (
            <div>
              <h4 className='mb-5 mt-5'>Members ({members.length})</h4>
              {members.map(({node}) => (
                <MembersEdit refetch={refetch} key={node.id} node={node} />
              ))}
            </div>
          )}
          <div className='mt-5 d-flex align-items-center justify-content-center'>
            <span
              style={{
                width: '100%',
                height: '2px',
                flex: 1,
                background: '#c7c7c7',
                display: 'inline-block',
              }}
            />
            <h5 className='ms-3 me-3 mt-0 mb-0' style={{fontSize: '14px'}}>
              Add below the information of each Member
            </h5>
            <span
              style={{
                width: '100%',
                height: '2px',
                flex: 1,
                background: '#c7c7c7',
                display: 'inline-block',
              }}
            />
          </div>

          <div>
            <Collapse in={arrMembers.length > 0}>
              <div>
                {arrMembers.map((mem) => (
                  <MemberAdd key={mem.id} mem={mem} deleteItem={deleteItem} refetch={refetch} />
                ))}
              </div>
            </Collapse>
          </div>

          {policy && Number(policy) && (
            <div className='mt-5 mb-5 pb-6'>
              <Button
                variant='success'
                disabled={isDisableEdit}
                size='sm'
                onClick={() => {
                  const i = new Date().getTime()
                  setArrMembers((p) => [...p, {id: i, open: true}])
                }}
              >
                <i className='bi bi-plus' />
                Add member
              </Button>
            </div>
          )}
        </>
      )}
      <div
        className='py-5 px-3 d-flex align-items-center justify-content-between'
        style={{borderTop: '1px solid #e1e1e1'}}
      >
        <Button variant='secondary' onClick={onPrevStep}>
          PREVIOUS
        </Button>
        {values.lead ? (
          Number(values.lead) ? (
            <Button onClick={handleAddPolicySubmit} disabled={loadingCreate}>
              {loadingCreate && <Spinner animation='border' size='sm' />}
              ADD POLICY
            </Button>
          ) : null
        ) : (
          <Button onClick={onNextStep}>NEXT STEP</Button>
        )}
      </div>
    </div>
  )
}

/*
<div key={mem.id}>
                    <Accordion key={mem.id} className='my-5'>
                      <Accordion.Item eventKey='0'>
                        <Accordion.Header>Personal information</Accordion.Header>
                        <Accordion.Body>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6 '>
                              <label htmlFor='applicant'>
                                Is this member an applicant?{' '}
                                <small className='kt-font-danger kt-font-bold'>(required)</small>
                              </label>
                              <Form.Select
                                // value={form.isApplicant}
                                name='isApplicant'
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                              >
                                <option value='yes'>Yes</option>
                                <option value='no'>No</option>
                              </Form.Select>
                            </div>
                            <div className='col-lg-6'>
                              <label htmlFor='preferred_language'>
                                Relation to primary{' '}
                                <small className='text-danger'>(required)</small>
                              </label>
                              <Select
                                // value={form.isApplicant}
                                name='isApplicant'
                                styles={{control: (base) => ({...base, padding: '3  px 3px'})}}
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                              />
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <label htmlFor='first_name'>
                                First name{' '}
                                <small className='kt-font-danger kt-font-bold'>(required)</small>
                              </label>
                              <input
                                type='text'
                                name='firstName'
                                // value={form.firstName}
                                id='first_name'
                                className='form-control '
                                required
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                              />
                            </div>
                            <div className='col-lg-6'>
                              <label htmlFor='middle_name'>Middle name</label>
                              <input
                                type='text'
                                name='middleName'
                                id='middle_name'
                                // value={form.middleName}
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                                placeholder='(optional)'
                                className='form-control '
                              />
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <label htmlFor='last_name'>
                                Last name{' '}
                                <small className='kt-font-danger kt-font-bold'>(required)</small>
                              </label>
                              <input
                                type='text'
                                name='lastName'
                                // value={form.lastName}
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                                id='last_name'
                                className='form-control '
                                required
                              />
                            </div>
                            <div className='col-lg-6'>
                              <label htmlFor='last_name2'>Second last name</label>
                              <input
                                type='text'
                                name='secondLastName'
                                // value={form.secondLastName}
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                                id='last_name2'
                                defaultValue=''
                                placeholder='(optional)'
                                className='form-control '
                              />
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <label
                                style={{width: '100%'}}
                                htmlFor='dob'
                                className='d-flex align-items-center'
                              >
                                <span>
                                  Date of birth{' '}
                                  <small className='kt-font-danger kt-font-bold'>(required)</small>{' '}
                                </span>
                                <span
                                  style={{display: 'inline-block', textAlign: 'right', flex: 1}}
                                >
                                  Age: 33
                                </span>
                              </label>
                              //  <InputGroup className='mb-3'>
                  // <InputGroup.Text id='basic-addon1'>
                    // <i className='la la-calendar-check-o' />
                  // </InputGroup.Text> 
                              <Form.Control
                                type='date'
                                id='dob'
                                name='dateBirth'
                                // value={form.dateBirth}
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                              />
                              // </InputGroup> 
                            </div>
                            <div className='col-lg-6'>
                              <label htmlFor='gender'>Gender</label>
                              <Form.Select
                                aria-label='Default select example'
                                name='gender'
                                // value={form.gender}
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                              >
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                                <option value='Other'>Other</option>
                              </Form.Select>
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <label htmlFor='email'>Email address</label>
                              <input
                                type='email'
                                name='email'
                                // value={form.email}
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                                id='email'
                                className='form-control '
                                autoComplete='off'
                              />
                            </div>
                            <div className='col-lg-6'>
                              <label htmlFor='phone'>Phone</label>
                              <input
                                type='tel'
                                name='phone'
                                // value={form.phone}
                                // onChange={(e) => onChange(e.target, 'policyholder')}
                                id='phone'
                                className='form-control  phone_mask'
                                im-insert='true'
                              />
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <label htmlFor='marital_status'>Marital status</label>
                              <Form.Select aria-label='Default select example'>
                                <option>Open this select menu</option>
                                <option value='1'>One</option>
                                <option value='2'>Two</option>
                                <option value='3'>Three</option>
                              </Form.Select>
                            </div>
                            <div className='col-lg-6'>
                              <label htmlFor='marital_status'>Country of birth</label>
                              <Select />
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <label htmlFor='weight'>Weight</label>
                              <input
                                type='text'
                                name='weight'
                                id='weight'
                                defaultValue=''
                                className='form-control  percent_mask'
                                im-insert='true'
                              />
                            </div>
                            <div className='col-lg-6'>
                              <label htmlFor='height'>Height</label>
                              <input
                                type='text'
                                name='height'
                                id='height'
                                defaultValue=''
                                className='form-control  height_mask'
                                im-insert='true'
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey='0'>
                        <Accordion.Header>Employment information</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col md={4} sm={12}>
                              <h4 className='mb-4'>Source of income #1</h4>
                              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label>Employer name / Self employed</Form.Label>
                                <Form.Control placeholder='' />
                              </Form.Group>
                              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label>Employer phone number</Form.Label>
                                <Form.Control defaultValue='' placeholder='' />
                              </Form.Group>
                              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label>Position/Occupation</Form.Label>
                                <Form.Control defaultValue='' placeholder='' />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Annual income</Form.Label>
                                <InputGroup className='mb-0'>
                                  <InputGroup.Text>$</InputGroup.Text>
                                  <Form.Control />
                                </InputGroup>
                              </Form.Group>
                              <small>Type digits only.</small>
                            </Col>
                            <Col md={4} sm={12}>
                              <h4 className='mb-4'>Source of income #2</h4>

                              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label>Employer name / Self employed</Form.Label>
                                <Form.Control placeholder='' />
                              </Form.Group>
                              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label>Employer phone number</Form.Label>
                                <Form.Control defaultValue='' placeholder='' />
                              </Form.Group>
                              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label>Position/Occupation</Form.Label>
                                <Form.Control defaultValue='' placeholder='' />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Annual income</Form.Label>
                                <InputGroup className='mb-0'>
                                  <InputGroup.Text>$</InputGroup.Text>
                                  <Form.Control />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                              <h4 className='mb-4'>Source of income #3</h4>

                              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label>Employer name / Self employed</Form.Label>
                                <Form.Control placeholder='' />
                              </Form.Group>
                              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label>Employer phone number</Form.Label>
                                <Form.Control defaultValue='' placeholder='' />
                              </Form.Group>
                              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label>Position/Occupation</Form.Label>
                                <Form.Control defaultValue='' placeholder='' />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Annual income</Form.Label>
                                <InputGroup className='mb-0'>
                                  <InputGroup.Text>$</InputGroup.Text>
                                  <Form.Control />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey='0'>
                        <Accordion.Header>Legal documents</Accordion.Header>
                        <Accordion.Body>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <Form.Group className=''>
                                <Form.Label>Immigration status</Form.Label>
                                <Form.Select aria-label='Default select example'>
                                  <option>Open this select menu</option>
                                  <option value='1'>One</option>
                                  <option value='2'>Two</option>
                                  <option value='3'>Three</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div className='col-lg-6'>
                              <Form.Group className=''>
                                <Form.Label>Immigration status category</Form.Label>
                                <Form.Control defaultValue='' placeholder='eg. C08' />
                              </Form.Group>
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <Form.Label>USCIS #</Form.Label>
                              <InputGroup className='mb-3'>
                                <Form.Control
                                  placeholder="Recipient's username"
                                  aria-label="Recipient's username"
                                  aria-describedby='basic-addon2'
                                />
                                <Button
                                  variant='outline-secondary'
                                  className='border'
                                  id='button-addon2'
                                >
                                  <i className='bi bi-eye-fill' />
                                </Button>
                              </InputGroup>
                            </div>
                            <div className='col-lg-6'>
                              <Form.Label>Passport #</Form.Label>
                              <InputGroup className='mb-3'>
                                <Form.Control
                                  placeholder="Recipient's username"
                                  aria-label="Recipient's username"
                                  aria-describedby='basic-addon2'
                                />
                                <Button
                                  variant='outline-secondary'
                                  className='border'
                                  id='button-addon2'
                                >
                                  <i className='bi bi-eye-fill' />
                                </Button>
                              </InputGroup>
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <Form.Label>Social Security Number (SSN)</Form.Label>
                              <InputGroup className='mb-3'>
                                <Form.Control
                                  placeholder="Recipient's username"
                                  aria-label="Recipient's username"
                                  aria-describedby='basic-addon2'
                                />
                                <Button
                                  variant='outline-secondary'
                                  className='border'
                                  id='button-addon2'
                                >
                                  <i className='bi bi-eye-fill' />
                                </Button>
                              </InputGroup>
                            </div>
                            <div className='col-lg-6'>
                              <Form.Group className=''>
                                <Form.Label>SSN issued date</Form.Label>
                                <Form.Control type='date' />
                              </Form.Group>
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <Form.Label>Green card #</Form.Label>
                              <InputGroup className='mb-3'>
                                <Form.Control
                                  placeholder="Recipient's username"
                                  aria-label="Recipient's username"
                                  aria-describedby='basic-addon2'
                                />
                                <Button
                                  variant='outline-secondary'
                                  className='border'
                                  id='button-addon2'
                                >
                                  <i className='bi bi-eye-fill' />
                                </Button>
                              </InputGroup>
                            </div>
                            <div className='col-lg-6'>
                              <Form.Group className=''>
                                <Form.Label>Green card issue date</Form.Label>
                                <Form.Control type='date' />
                              </Form.Group>
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'></div>
                            <div className='col-lg-6'>
                              <Form.Group className=''>
                                <Form.Label>Green card expiration date</Form.Label>
                                <Form.Control type='date' />
                              </Form.Group>
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <Form.Label>Work permit card #</Form.Label>
                              <InputGroup className='mb-3'>
                                <Form.Control
                                  placeholder="Recipient's username"
                                  aria-label="Recipient's username"
                                  aria-describedby='basic-addon2'
                                />
                                <Button
                                  variant='outline-secondary'
                                  className='border'
                                  id='button-addon2'
                                >
                                  <i className='bi bi-eye-fill' />
                                </Button>
                              </InputGroup>
                            </div>
                            <div className='col-lg-6'>
                              <Form.Group className=''>
                                <Form.Label>Work permit issue date</Form.Label>
                                <Form.Control type='date' />
                              </Form.Group>
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'></div>
                            <div className='col-lg-6'>
                              <Form.Group className=''>
                                <Form.Label>Work permit expiration date</Form.Label>
                                <Form.Control type='date' />
                              </Form.Group>
                            </div>
                          </div>
                          <div className='mb-3 form-group form-group-md row'>
                            <div className='col-lg-6'>
                              <Form.Label>Driver license #</Form.Label>
                              <InputGroup className='mb-3'>
                                <Form.Control
                                  placeholder="Recipient's username"
                                  aria-label="Recipient's username"
                                  aria-describedby='basic-addon2'
                                />
                                <Button
                                  variant='outline-secondary'
                                  className='border'
                                  id='button-addon2'
                                >
                                  <i className='bi bi-eye-fill' />
                                </Button>
                              </InputGroup>
                            </div>
                            <div className='col-lg-6'>
                              <Form.Group className=''>
                                <Form.Label>Driver license expiration date</Form.Label>
                                <Form.Control type='date' />
                              </Form.Group>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div>
                      <Button size='sm' className='me-3'>
                        Switch with the policy holder
                      </Button>
                      <Button
                        size='sm'
                        variant='danger'
                        onClick={() => {
                          setArrMembers((p) => p.filter((m) => m.id !== mem.id))
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
*/

import {Button, Col, Form, Modal, Row, Spinner} from 'react-bootstrap'
import Select from 'react-select'
import {
  EdgeMemberPolicy,
  INodeAttachments,
  IResAllTagsDocuments,
} from '../../../../types/policiesApi'
import {useFormik} from 'formik'
import {ChangeEvent, useEffect, useState} from 'react'
import {useQuery} from '@apollo/client'
import {GET_DOCUMENTS_POLICY} from '../../../../gql/queries/policiesApiQueries'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useUploadFile} from '../../../../hooks/useUploadFile'
import {EDIT_DOCUMENT_POLICY_STR} from '../../../../gql/mutations/policyMutation'
import {parseId} from '../../../../helpers'
import {RenderFilePreview} from './RenderFilePreview'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  /* attachments: Yup.mixed().test('fileType', 'Attachment must be a valid file', (value) => {
    return value instanceof File
  }), */
})

const INIT_FORM = {
  name: '',
  attachments: undefined as undefined | File,
  memberPolicyId: '',
  expirationDate: '',
  documentTagId: '',
  comments: '',
}

interface IResEditFilePolicy {
  updateAttachments: {success: boolean; errors?: {message: string}[]}
}

interface IModalProps {
  id: string
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  members: EdgeMemberPolicy[]
  infoDoc: INodeAttachments
}

export const ModalEditDoc: React.FC<IModalProps> = ({
  id,
  isOpen,
  members,
  onClose,
  refetch,
  infoDoc,
}) => {
  const [uploadFile, {data, error, loading}] = useUploadFile<IResEditFilePolicy>()
  const {
    data: dataTags,
    loading: loadingTags,
    error: errorTags,
  } = useQuery<IResAllTagsDocuments>(GET_DOCUMENTS_POLICY, {
    fetchPolicy: 'no-cache',
  })

  const [fileStr, setFileStr] = useState<null | string>(null)

  const [valueSelectMember, setValueSelectMember] = useState<null | {
    value: string
    label: string
  }>(null)
  const [valueSelectTag, setValueSelectTag] = useState<null | {
    value: string
    label: string
  }>(null)

  const formik = useFormik({
    initialValues: INIT_FORM,
    validationSchema,
    onSubmit: (v) => {
      const formdata = new FormData()
      if (v.attachments) {
        formdata.append('0', v.attachments, v.attachments.name)
        const map = {'0': ['variables.attachmentsData.attachments']}
        formdata.append('map', JSON.stringify(map))
      }
      const obj = {
        query: EDIT_DOCUMENT_POLICY_STR,
        variables: {
          id: parseId(infoDoc.id),
          attachmentsData: {
            name: v.name,
            attachments: v.attachments ? null : undefined,
            policyId: Number(id),
            memberPolicyId: v.memberPolicyId ? parseId(v.memberPolicyId) : undefined,
            expirationDate: v.expirationDate || undefined,
            documentTagId: v.documentTagId ? parseId(v.documentTagId) : undefined,
            comments: v.comments,
          },
        },
      }
      formdata.append('operations', JSON.stringify(obj))
      uploadFile(formdata)
    },
  })

  useEffect(() => {
    formik.setValues({
      attachments: undefined,
      comments: infoDoc.comments || '',
      expirationDate: infoDoc.expirationDate || '',
      name: infoDoc.name || '',
      documentTagId: infoDoc?.documentTag?.id || '',
      memberPolicyId: infoDoc?.memberPolicy?.id || '',
    })
    const cleanUrl = infoDoc.attachments.split('?')[0]
    const extension = cleanUrl.split('.')?.pop()?.toLowerCase() || ''
    setFileStr(`${infoDoc.name}.${extension}`)
    if (infoDoc.memberPolicy) {
      setValueSelectMember({
        label: infoDoc.memberPolicy.firstName + ' ' + infoDoc.memberPolicy.lastName,
        value: infoDoc.memberPolicy.id,
      })
    }
    if (infoDoc.documentTag) {
      setValueSelectTag({
        label: infoDoc.documentTag.tagName,
        value: infoDoc.documentTag.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoDoc])

  useEffect(() => {
    if (errorTags) toast.error(`Error: ${errorTags.message}`)
  }, [errorTags])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.toString()}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.updateAttachments.success) {
      onClose()
      refetch()
      toast.success('File edited successfully')
    } else if (data.updateAttachments.errors && data.updateAttachments.errors.length > 0) {
      toast.error(`Error: ${data.updateAttachments.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-items-center gap-2'>
          Edit Document {loading && <Spinner animation='border' size='sm' />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} id='formId'>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  isInvalid={Boolean(formik.errors.name && formik.touched.name)}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  name='name'
                  id='name'
                  placeholder='File name'
                />
                {formik.errors.name && formik.touched.name && (
                  <Form.Text className='text-danger'>{formik.errors.name}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Expiration</Form.Label>
                <Form.Control
                  isInvalid={Boolean(formik.errors.expirationDate && formik.touched.expirationDate)}
                  onBlur={formik.handleBlur}
                  value={formik.values.expirationDate}
                  onChange={formik.handleChange}
                  name='expirationDate'
                  id='expirationDate'
                  type='date'
                  placeholder='name@example.com'
                />
                {formik.errors.expirationDate && formik.touched.expirationDate && (
                  <Form.Text className='text-danger'>{formik.errors.expirationDate}</Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className='my-3'>
                <Form.Label>Member</Form.Label>
                <Select
                  name='memberPolicyId'
                  onBlur={formik.handleBlur}
                  isClearable
                  value={valueSelectMember}
                  onChange={(e) => {
                    setValueSelectMember(e)
                    if (e) {
                      formik.setFieldValue('memberPolicyId', e.value)
                    } else {
                      formik.setFieldValue('memberPolicyId', '')
                    }
                  }}
                  options={members.map(({node}) => ({
                    label: `${node.firstName} ${node.lastName}`,
                    value: node.id,
                  }))}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId='select2' className='my-3'>
                <Form.Label>Tag</Form.Label>
                <Select
                  isClearable
                  value={valueSelectTag}
                  onChange={(e) => {
                    setValueSelectTag(e)
                    if (e) {
                      formik.setFieldValue('documentTagId', e.value)
                    } else {
                      formik.setFieldValue('documentTagId', '')
                    }
                  }}
                  options={
                    dataTags?.allAttachmentsTags.edges.map(({node}) => ({
                      label: `${node.tagName}`,
                      value: node.id,
                    })) || []
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className='mt-3'>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              name='comments'
              onChange={formik.handleChange}
              value={formik.values.comments}
              as='textarea'
              rows={3}
            />
          </Form.Group>

          <Row>
            <Col md={12}>
              <Form.Group className='my-3'>
                <Form.Label>Document</Form.Label>
                <Form.Control
                  name='attachments'
                  isInvalid={Boolean(formik.errors.attachments && formik.touched.attachments)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'attachments',
                      (e as ChangeEvent<HTMLInputElement>)?.target?.files?.[0]
                    )
                    setFileStr(null)
                  }}
                  type='file'
                  required={fileStr == null}
                />
              </Form.Group>
            </Col>
            {fileStr && (
              <Col md={6}>
                <div
                  style={{
                    border: '1px dashed black',
                    borderRadius: '8px',
                    width: 'min-content',
                    position: 'relative',
                  }}
                  className='px-4 py-2 d-flex align-items-center gap-x-2'
                >
                  <button
                    type='button'
                    className='btn-reset position-absolute bg-primary p-0 d-flex flex-center'
                    style={{top: -5, right: -5, borderRadius: '50%'}}
                    onClick={() => setFileStr(null)}
                  >
                    <i className='bi bi-x fs-2 text-white' />
                  </button>
                  <RenderFilePreview file={infoDoc.attachments} />
                  <div>{fileStr}</div>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose} type='button'>
          Cancel
        </Button>
        <Button
          variant='primary'
          type='submit'
          form='formId'
          formAction='formId'
          disabled={loading || loadingTags}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

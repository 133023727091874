import {useMutation} from '@apollo/client'
import {Badge, Button, Offcanvas, Spinner} from 'react-bootstrap'
import {CALL_COUNTER} from '../../../../gql/mutations/leadMutations'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {parseId} from '../../../../helpers'
import {IResSummCallCounter} from '../../../../types/leads'

interface IFormSendCallProps {
  onBack: () => void
  refetch: () => void
  leadId: string
  leadName: string
  callCounter: number
  updateListLeads: () => void
}

export const FormSendCall: React.FC<IFormSendCallProps> = ({
  leadId,
  leadName,
  onBack,
  callCounter,
  refetch,
  updateListLeads,
}) => {
  const [gqlCall, {data, loading, error}] = useMutation<IResSummCallCounter>(CALL_COUNTER)

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.sumCallCounter?.success) {
      toast.success('Call made successfully')
      onBack()
      refetch()
      updateListLeads()
    } else if (data.sumCallCounter?.errors && data.sumCallCounter?.errors.length > 0) {
      toast.error(`Error: ${data.sumCallCounter?.errors[0]?.message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleSendCall = () => {
    gqlCall({
      variables: {
        leadId: parseId(leadId),
      },
    })
  }

  return (
    <>
      <Offcanvas.Header closeButton className='bg-light'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Offcanvas.Title className='d-flex align-items-center gap-4'>Sum Call</Offcanvas.Title>
          <div style={{flex: 0}}>
            <Badge bg='success' pill className='fs-2 d-flex align-items-center gap-1'>
              <i className='bi bi-telephone-plus' style={{color: 'inherit'}} />
              {callCounter <= 10 ? callCounter : '10+'}
            </Badge>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className='h-100'>
        <div className='d-flex flex-center h-100 flex-column'>
          <h2 className='fs-1'>Are you sure you want to call {leadName}?</h2>
          <div className='d-flex algin-items-center justify-content-end gap-3 mt-4'>
            <Button variant='light' onClick={onBack}>
              Cancel
            </Button>
            <Button variant='primary' disabled={loading} onClick={handleSendCall}>
              {loading && <Spinner size='sm' animation='border' />}
              Call
            </Button>
          </div>
        </div>
      </Offcanvas.Body>
    </>
  )
}

import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
// import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {
  RING_CENTRAL_APP_SERVER,
  RING_CENTRAL_CLIENT_ID,
  STORAGE_NAMES,
} from '../../app/tfi/helpers/consts'
// import {ToolbarWrapper} from './components/toolbar'

const MasterLayout = () => {
  const location = useLocation()

  useEffect(() => {
    const isRingIntegration = localStorage.getItem(STORAGE_NAMES.RING_CENTRAL)
    if (!isRingIntegration) return /* eslint-disable */
    ;(function () {
      var rcs = document.createElement('script')
      rcs.src =
        'https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/adapter.js?clientId=' +
        RING_CENTRAL_CLIENT_ID +
        '&appServer=' +
        RING_CENTRAL_APP_SERVER
      var rcs0 = document.getElementsByTagName('script')[0]
      rcs0?.parentNode?.insertBefore(rcs, rcs0)
    })()
    const script2 = document.createElement('script')
    script2.src = 'https://unpkg.com/ringcentral-c2d@1.0.0/build/index.js'

    // Agregar el script al body
    document.body.appendChild(script2)

    // Confirmar que el script fue cargado
    script2.onload = () => {
      var clickToDial = new (window as any).RingCentralC2D()
      clickToDial.on((window as any).RingCentralC2D.events.call, (phoneNumber) => {
        ;(window as any).RCAdapter.clickToCall(phoneNumber, true)
      })
      clickToDial.on((window as any).RingCentralC2D.events.text, (phoneNumber) => {
        ;(window as any).RCAdapter.clickToSMS(phoneNumber)
      })
    }

    script2.onerror = () => {
      console.log('Error al cargar el script.')
    }
  }, [])

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                {/* <ToolbarWrapper /> */}
                <Content>
                  <Outlet />
                </Content>
              </div>
              <FooterWrapper />
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}

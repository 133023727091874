import {useEffect, useState} from 'react'
import {Dropdown, DropdownButton, Form, Spinner} from 'react-bootstrap'
import {useDisclourse} from '../../hooks/useDisclourse'
import {DrawerAddLead} from './components/DrawerAddLead'
import {IFilterStage, IInfoLead} from './leadTypes'
import {DrawerEditLead} from './components/DrawerEditLead'
import {useQuery} from '@apollo/client'
import {GET_ALL_PIPELINES} from '../../gql/queries/pipelines'
import {IResPipelines} from '../../types/pipelines'
import {LoadingLeadsPage} from './components/LoadingLeadsPage'
import {toast} from 'react-toastify'
import {ContentDragDropLeads} from './components/ContentDragDropLeads'
import {useGetUser} from '../../store/userStore'
import {hasPermission} from '../../helpers'
import {ALL_PERMISSIONS} from '../../helpers/consts'

/**
 * Moves an item from one list to another list.
 */

export const LeadsPage = () => {
  const permissions = useGetUser((s) => s.user?.permissions)
  const {
    data: dataPipelines,
    loading: loadingPipelines,
    error: errorPipelines,
  } = useQuery<IResPipelines>(GET_ALL_PIPELINES, {fetchPolicy: 'no-cache'})

  const [pipelineId, setPipelineId] = useState('')
  const [flag, setFlag] = useState(0)

  const [isLoadingChangeState, setIsLoadingChangeState] = useState(false)

  const [infoLead, setInfoLead] = useState<null | IInfoLead>(null)
  const {isOpen: isOpenEdit, onClose: onCloseEdit, onOpen: onOpenEdit} = useDisclourse()
  const {isOpen: isOpenAdd, onClose: onCloseAdd, onOpen: onOpenAdd} = useDisclourse()

  const [filterStage, setFilterStage] = useState<IFilterStage>('others')

  useEffect(() => {
    if (errorPipelines) toast.error(`Error: ${errorPipelines.message}`)
  }, [errorPipelines])

  useEffect(() => {
    if (!dataPipelines) return
    if (dataPipelines.allPipelines.edges.length > 0) {
      setPipelineId(dataPipelines.allPipelines.edges[0].node.id)
    }
  }, [dataPipelines])

  const updateListLeads = () => {
    setFlag((p) => p + 1)
  }

  if (loadingPipelines || !dataPipelines || !permissions) {
    return <LoadingLeadsPage />
  }

  return (
    <>
      <div className='d-flex flex-column w-100'>
        <div className='w-100 mb-4 d-flex justify-content-between flex-column flex-md-row gap-4'>
          <div className='d-flex align-items-center gap-4'>
            <Form.Select
              aria-label='Default select example'
              value={pipelineId}
              onChange={(e) => {
                const {value} = e.target
                if (value) {
                  setPipelineId(value)
                }
              }}
            >
              <option value=''>Select Pipeline</option>
              {dataPipelines.allPipelines.edges.map(({node}) => (
                <option key={node.id} value={node.id}>
                  {node.name}
                </option>
              ))}
            </Form.Select>
            {isLoadingChangeState && (
              <span>
                <Spinner animation='border' size='sm' />
              </span>
            )}
            <Form.Select
              value={filterStage}
              aria-label='Search stages'
              onChange={(e) => setFilterStage(e.target.value as IFilterStage)}
            >
              <option value='all'>View all</option>
              <option value='soldDecline'>View Sold & Declined</option>
              <option value='others'>View Active Stages</option>
            </Form.Select>
          </div>
          <div>
            <DropdownButton id='dropdown-basic-button' title='+ Add'>
              {hasPermission(permissions, ALL_PERMISSIONS.PUEDE_CREAR_LEADS) && (
                <Dropdown.Item
                  className='px-4 mx-1 my-2 py-2 d-flex align-items-center gap-1'
                  onClick={onOpenAdd}
                >
                  <i className='bi bi-person-vcard' />
                  Add Lead
                </Dropdown.Item>
              )}
              <Dropdown.Item>Another action</Dropdown.Item>
              <Dropdown.Item>Something else</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <div
          className='d-flex gap-2 container-draggs'
          style={{maxWidth: '100%', overflowX: 'auto'}}
        >
          <ContentDragDropLeads
            pipelineId={pipelineId}
            pipelines={dataPipelines.allPipelines.edges}
            onOpenEdit={onOpenEdit}
            setInfoLead={setInfoLead}
            updateListLeads={updateListLeads}
            flagLeads={flag}
            setLoadingState={(b: boolean) => setIsLoadingChangeState(b)}
            filterStage={filterStage}
          />
        </div>
      </div>

      {isOpenAdd && (
        <DrawerAddLead isOpen={isOpenAdd} onClose={onCloseAdd} updateListLeads={updateListLeads} />
      )}
      {isOpenEdit && infoLead && (
        <DrawerEditLead
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          info={infoLead}
          updateListLeads={updateListLeads}
        />
      )}
    </>
  )
}

import {useState, useEffect} from 'react'
import {EVENT_SERVICE_WORKER_NAME} from '../helpers/consts'
// import * as serviceWorkerRegistration from '../serviceWorkerRegistration'
export const useServiceWorker = () => {
  const [isNewVersion, setIsNewVersion] = useState(false)

  useEffect(() => {
    const handleEventSW = (e) => {
      if (e.type === EVENT_SERVICE_WORKER_NAME) setIsNewVersion(true)
    }
    document.addEventListener(EVENT_SERVICE_WORKER_NAME, handleEventSW)

    return () => {
      document.removeEventListener(EVENT_SERVICE_WORKER_NAME, handleEventSW)
    }
  }, [])

  return {isNewVersion}
}

import {gql} from '@apollo/client'

export const GET_STATES_EU = gql`
  query allStates {
    allStates {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`

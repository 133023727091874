import Lottie from 'lottie-react'
import {Button, Offcanvas} from 'react-bootstrap'
import buildingJson from '../../../../assets/lotties/building.json'

export const FormSMS: React.FC<{onBack: () => void}> = ({onBack}) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
  }
  return (
    <>
      <Offcanvas.Header closeButton className='bg-light'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Offcanvas.Title className='d-flex align-items-center gap-4'>Send SMS</Offcanvas.Title>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form onSubmit={handleSubmit}>
          <div className='w-100 h-100'>
            <h4 className='text-center'>Comming Soon...</h4>
            <div className='d-flex align-items-center justify-content-center'>
              <Lottie animationData={buildingJson} />
            </div>

            <div className='d-flex algin-items-center justify-content-end gap-3 mt-4'>
              <Button type='button' variant='light' onClick={onBack}>
                Cancel
              </Button>
              <Button type='submit' variant='primary' disabled>
                Send
              </Button>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </>
  )
}

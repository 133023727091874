import {Button, Form, Modal, Spinner} from 'react-bootstrap'
// import Select from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {ADD_NOTE_POLICY, GET_NOTES_POLICY} from '../../../gql/mutations/policyMutation'
import {toast} from 'react-toastify'
import {ContentNotes} from './notes/ContentNotes'
import {IResNotePolicy} from '../../../types/policiesApi'

interface IResAddNotePolicy {
  createPolicyNote: {success: boolean; errors?: {message: string}[]}
}

interface IModalProps {
  isOpen: boolean
  onClose: () => void
  idPolicy: string
}

export const ModalNotes: React.FC<IModalProps> = ({isOpen, onClose, idPolicy}) => {
  const {
    data: dataNotes,
    loading: loadingNotes,
    error: errorNotes,
    refetch,
  } = useQuery<IResNotePolicy>(GET_NOTES_POLICY, {
    variables: {policyId: idPolicy},
    fetchPolicy: 'no-cache',
  })
  const [gqlAddNote, {data, loading, error}] = useMutation<IResAddNotePolicy>(ADD_NOTE_POLICY)

  const [value, setValue] = useState('')
  const [urgent, setUrgent] = useState(false)
  // const [newStatus, setNewStatus] = useState('')

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])
  useEffect(() => {
    if (errorNotes) toast.error(`Error: ${errorNotes.message}`)
  }, [errorNotes])

  useEffect(() => {
    if (!data) return
    if (data.createPolicyNote.success) {
      refetch()
      toast.success('File uploaded successfully')
      setValue('')
      setUrgent(false)
    } else if (data.createPolicyNote.errors && data.createPolicyNote.errors.length > 0) {
      toast.error(`Error: ${data.createPolicyNote.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleComment = () => {
    if (!value || !idPolicy) return
    const policyNoteData = {
      comments: value,
      policyId: idPolicy,
      urgent,
    }
    console.log('policyNoteData', policyNoteData)
    gqlAddNote({
      variables: {
        policyNoteData,
      },
    })
  }
  return (
    <Modal show={isOpen} onHide={onClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-items-center gap-x-2'>
          Policy notes{' '}
          {loadingNotes || !dataNotes ? (
            <Spinner animation='border' size='sm' />
          ) : (
            `(${dataNotes.policyNotesByPolicy.length})`
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '380px',
              maxHeight: '380px',
              overflowY: 'auto',
              backgroundColor: '#f8f8fa',
            }}
            className='px-3 py-2 border'
          >
            {loading || !dataNotes ? (
              <Spinner animation='border' />
            ) : (
              <ContentNotes notes={dataNotes.policyNotesByPolicy} />
            )}
          </div>
          {/* <Row>
            <Col md={6}>
              <div>
                <label>Who should be notified?</label>
                <Select />
                <span className='text-muted'>
                  If you leave this field empty, no notifications will be sent.
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label>Change the policy status: (optional)</label>
                <Select />
              </div>
            </Col>
          </Row> */}
          <div className='mb-3 mt-4'>
            <ReactQuill
              theme='snow'
              placeholder='Type your comments here...'
              value={value}
              className='h-100 h-full-quill'
              onChange={setValue}
              style={{minHeight: '120px'}}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  ['link', 'formula'],

                  [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}],
                  [{indent: '-1'}, {indent: '+1'}], // outdent/indent

                  [{header: [1, 2, 3, 4, 5, 6, false]}],

                  [{color: []}, {background: []}], // dropdown with defaults from theme
                  [{font: []}],
                  [{align: []}],

                  ['clean'], // remove formatting button
                ],
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-between'>
        <Form.Check
          checked={urgent}
          onChange={(e) => {
            setUrgent(e.target.checked)
          }}
          type={'checkbox'}
          id={`default-${'checkbox'}`}
          label={<span style={{color: 'black'}}>Is this an urgent note?</span>}
        />
        <div className='d-flex align-items-center gap-1'>
          <Button variant='secondary' onClick={onClose}>
            Close
          </Button>
          <Button
            variant='primary'
            onClick={handleComment}
            disabled={loading || !value || loadingNotes}
          >
            Save Changes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

import {create} from 'zustand'
import {IResLogin} from '../types/auth'
import {STORAGE_NAMES} from '../helpers/consts'

type State = {
  user: IResLogin['tokenAuth'] | null
  status: 'no-user' | 'user' | 'loading'
}

type Actions = {
  login: (res: IResLogin) => void
  logout: () => void
  setLoading: () => void
}

export const useGetUser = create<State & Actions>((set) => ({
  user: null,
  status: 'loading',
  login: (res) => {
    if (res?.tokenAuth) {
      set(() => ({user: res.tokenAuth, status: 'user'}))
      window.localStorage.setItem(STORAGE_NAMES.TOKEN, res.tokenAuth.token)
      const {settings} = res.tokenAuth
      if (settings && settings.length > 0) {
        try {
          const jsSettings = JSON.parse(settings[0]) as {id: number; organization_id: number}
          if (jsSettings?.id) {
            window.localStorage.setItem(STORAGE_NAMES.ID_USER, `${jsSettings.id}`)
          }
        } catch (error) {
          console.log('error', error)
        }
      }
      window.localStorage.setItem(STORAGE_NAMES.USER, JSON.stringify(res.tokenAuth))
    }
  },
  logout: () => {
    set(() => ({user: null, status: 'no-user'}))
    window.localStorage.removeItem(STORAGE_NAMES.TOKEN)
    window.localStorage.removeItem(STORAGE_NAMES.USER)
    window.localStorage.removeItem(STORAGE_NAMES.ID_USER)
  },
  setLoading: () => set(() => ({status: 'loading'})),
}))

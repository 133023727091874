import {gql} from '@apollo/client'

export const ADD_EDIT_TAG = gql`
  mutation tagInsuranceCreateupdateMutation($input: tag_Insurance_createUpdate_mutationInput!) {
    tagInsuranceCreateupdateMutation(input: $input) {
      tagInsurance {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

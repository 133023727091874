import {Accordion, Button, Card, Col, Form, Pagination, Row, Spinner} from 'react-bootstrap'

export const LoadingOneAddQuote = ({
  arrOffset,
  currentPage,
}: {
  arrOffset: number[]
  currentPage: number
}) => {
  return (
    <div>
      <Accordion>
        <Accordion.Item eventKey='0'>
          <Accordion.Header className='text-muted'>
            Household Form <Spinner animation='border' size='sm' className='ms-2' />
          </Accordion.Header>
        </Accordion.Item>
      </Accordion>
      <div className='mt-6'>
        <div className='d-flex algin-items-md-center justify-content-between flex-column flex-md-row align-items-start gap-2'>
          <Pagination size='sm'>
            {arrOffset.map((n) => (
              <Pagination.Item key={n} active={n === currentPage}>
                {n}
              </Pagination.Item>
            ))}
            <Pagination.Next />
          </Pagination>
          <div className='d-flex align-items-center gap-1'>
            <Form.Select aria-label='Default select example' disabled>
              <option>Open this select menu</option>
              <option value='1'>One</option>
              <option value='2'>Two</option>
              <option value='3'>Three</option>
            </Form.Select>
            <Form.Select aria-label='Default select example' disabled>
              <option>Open this select menu</option>
              <option value='1'>One</option>
              <option value='2'>Two</option>
              <option value='3'>Three</option>
            </Form.Select>
          </div>
        </div>
      </div>

      <div className='mt-4'>
        <Card className='rounded-1 mb-4'>
          <Card.Header className='align-items-center'>
            <Card.Title className='placeholder-glow w-50'>
              <span className='placeholder col-9' />
            </Card.Title>
            <div>
              <Button variant='secondary' size='sm'>
                <i className='bi bi-search' />
                PLAN DETAILS
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <h4 className='text-info fs-3 placeholder-glow'>
              <span className='placeholder col-6' />
            </h4>
            <div className='d-flex align-items-center mb-1 flex-wrap' style={{fontSize: '11px'}}>
              <span className='placeholder-glow' style={{width: '48px'}}>
                <span className='placeholder col-11' />
              </span>
              <span>
                <i className='bi bi-dot fs-1' />
              </span>
              <span className='placeholder-glow' style={{width: '48px'}}>
                <span className='placeholder col-11' />
              </span>
              <span>
                <i className='bi bi-dot fs-1' />
              </span>
              <span>
                <span className='fw-bold'>Plan ID:</span>{' '}
                <span className='placeholder-glow' style={{display: 'inline-block', width: '80px'}}>
                  <span className='placeholder col-12' />
                </span>
              </span>
              <span>
                <i className='bi bi-dot fs-1' />
              </span>
              <span>
                <span className='fw-bold'>Rating:</span>{' '}
                {[1, 2, 3, 4, 5].map((n) => (
                  <i
                    className='bi bi-star-fill'
                    key={n}
                    style={{
                      color: '#565454',
                    }}
                  />
                ))}
              </span>
              <span>
                <i className='bi bi-dot fs-1' />
              </span>
              <span className='d-flex align-items-center'>
                <span className='fw-bold'>Dental adults:</span>{' '}
                <span>
                  <Spinner animation='grow' size='sm' />
                </span>
              </span>
              <span>
                <i className='bi bi-dot fs-1' />
              </span>
              <span className='d-flex align-items-center'>
                <span className='fw-bold'>Dental children:</span>{' '}
                <Spinner animation='grow' size='sm' />
              </span>
            </div>
            <div className='d-flex  gap-1 flex-wrap flex-column flex-lg-row'>
              <Card style={{backgroundColor: '#f1f3ff', flex: 1}}>
                <Card.Body>
                  <h6>Monthly premium:</h6>
                  <div>
                    <h3 className='fs-2 fw-bold'>
                      <small>
                        <sup>$</sup>
                      </small>
                      <span className='placeholder-glow'>
                        <span className='placeholder col-4' />
                      </span>
                      <small>
                        <sup>
                          .
                          <span className='placeholder-glow'>
                            <span className='placeholder col-2' />
                          </span>
                        </sup>
                      </small>
                    </h3>
                  </div>
                  <p className='m-0 p-0 text-muted'>
                    Savings total:{' '}
                    <span
                      className='text-success fw-bold'
                      style={{display: 'inline-block', width: '110px'}}
                    >
                      <span className='placeholder-glow'>
                        <span className='placeholder col-12' />
                      </span>
                    </span>
                  </p>
                  <p className='m-0 p-0 text-muted'>
                    Plan was:{' '}
                    <span className='text-danger fw-bold'>
                      <span className='placeholder-glow'>
                        <span className='placeholder col-4' />
                      </span>
                    </span>
                  </p>
                </Card.Body>
              </Card>
              <Card style={{backgroundColor: '#f1f3ff', flex: 1}}>
                <Card.Body>
                  <h6>Deductible:</h6>
                  <div>
                    <span className='fs-2 fw-bold'>
                      <span className='placeholder-glow'>
                        <span className='placeholder col-7' />
                      </span>
                    </span>
                  </div>

                  <p className='m-0 p-0 text-muted'>
                    Individual total ($
                    <span
                      className='placeholder-glow'
                      style={{display: 'inline-block', width: '90px'}}
                    >
                      <span className='placeholder col-12' />
                    </span>{' '}
                    per person)
                  </p>

                  <p className='m-0 p-0 text-muted'>
                    {' '}
                    <span className='placeholder-glow'>
                      <span className='placeholder col-12' />
                    </span>
                  </p>
                </Card.Body>
              </Card>
              <Card style={{backgroundColor: '#f1f3ff', flex: 1}}>
                <Card.Body>
                  <h6>Out-of-pocket max:</h6>
                  <div>
                    <span className='fs-2 fw-bold'>
                      <span className='placeholder-glow'>
                        <span className='placeholder col-12' />
                      </span>
                    </span>
                  </div>

                  <p className='m-0 p-0 text-muted'>Individual total</p>

                  <p className='m-0 p-0 text-muted'>
                    Maximum for Medical and Drug{' '}
                    <span className='placeholder-glow'>
                      <span className='placeholder col-12' />
                    </span>
                  </p>
                </Card.Body>
              </Card>
            </div>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                <h5>Primary Doctor visits</h5>
                <p>
                  <span className='placeholder-glow'>
                    <span className='placeholder col-12' />
                  </span>
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                <h5>Specialist Visits</h5>
                <p>
                  <span className='placeholder-glow'>
                    <span className='placeholder col-12' />
                  </span>
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                <h5>Urgent care</h5>
                <p>
                  <span className='placeholder-glow'>
                    <span className='placeholder col-8' />
                  </span>
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                <h5>Emergencies</h5>
                <p>
                  <span className='placeholder-glow'>
                    <span className='placeholder col-8' />
                  </span>
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                <h5>Mental health</h5>
                <p>
                  <span className='placeholder-glow'>
                    <span className='placeholder col-8' />
                  </span>
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={2}>
                <h5>Generic drugs</h5>
                <p>
                  <span className='placeholder-glow'>
                    <span className='placeholder col-8' />
                  </span>
                </p>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </div>
    </div>
  )
}

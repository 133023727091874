import Lottie from 'lottie-react'
import {ListGroup, Modal, Spinner} from 'react-bootstrap'
import searchLottie from '../../assets/lotties/search-leads.json'
import {IResAllLeadStage} from '../../pages/leads/leadTypes'
import {Link} from 'react-router-dom'
import {parseId} from '../../helpers'
import {CSSProperties} from 'react'

export const ModalBodySearchHeader: React.FC<{
  data: undefined | IResAllLeadStage
  loading: boolean
  keyword: string
  onClose: () => void
}> = ({data, loading, onClose, keyword}) => {
  const highlightKeyword = (text: string, color: CSSProperties['color'] = 'yellow') => {
    // Usamos una expresión regular para encontrar todas las ocurrencias de la palabra clave
    const regex = new RegExp(`(${keyword})`, 'gi')
    // Reemplazamos las ocurrencias con la palabra clave envuelta en un span con un estilo de fondo amarillo
    return text.replace(regex, `<span style="background-color: ${color};">$1</span>`)
  }

  if (loading) {
    return (
      <Modal.Body style={{minHeight: '300px'}}>
        <div className='d-flex align-items-center justify-content-center w-100 h-100'>
          <Spinner animation='border' />
        </div>
      </Modal.Body>
    )
  }
  if (!data) {
    return (
      <Modal.Body
        style={{minHeight: '300px'}}
        className='d-flex align-items-center justify-content-center gap-5 flex-column'
      >
        You can find your Leads by entering phone number or name
        <Lottie animationData={searchLottie} autoPlay />
      </Modal.Body>
    )
  }
  return (
    <Modal.Body style={{minHeight: '300px'}}>
      <ListGroup>
        {data.allLeads.edges.map(({node}) => (
          <ListGroup.Item
            as={Link}
            to={`/lead/${parseId(node.id)}`}
            key={node.id}
            onClick={onClose}
            className='d-flex align-items-center justify-content-between py-4'
            style={{color: ''}}
          >
            <span dangerouslySetInnerHTML={{__html: highlightKeyword(node.name)}} />{' '}
            <span>{node.phone}</span>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Modal.Body>
  )
}

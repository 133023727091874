import {useCallback, useState} from 'react'
import {URL} from '../gql/client'
import {STORAGE_NAMES} from '../helpers/consts'

export const useUploadFile: <T>() => [
  (body: FormData) => void,
  {data: T | null; loading: boolean; error: null | string}
] = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const getFetch = useCallback((body: FormData) => {
    const token = window.localStorage.getItem(STORAGE_NAMES.TOKEN)
    if (!token) return setError('Signature expired')
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append(
      'Cookie',
      'csrftoken=le5GvRfK7iZkorIAyYzckwQ7m3ux3RztbFZqjr3Rvv4KAcVBn52gQGazZPqaWm5u'
    )
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow',
    }
    setLoading(true)
    fetch(`${URL}/api`, requestOptions)
      .then((response) => {
        if (!response.ok) throw new Error('Something happened')
        return response.json()
      })
      .then((result) => {
        setData(result.data)
        if (result?.data.errors?.length > 0) {
          throw new Error(result.data?.errors?.message || 'Algo ocurrio')
        }
        if (result?.errors?.length > 0) {
          throw new Error(result?.errors?.[0]?.message || 'Algo ocurrio')
        }
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => setLoading(false))
  }, [])

  return [getFetch, {data, loading, error}]
}

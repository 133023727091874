interface IRouteInfo {
  id: number
  name: string
  route: string
  side: 'left' | 'right'
  title: null
  isChildren: boolean
}

interface ITitleInfo {
  id: number
  name: null
  route: null
  side: 'left' | 'right'
  title: string
  isChildren: boolean
}

export type TRoutesType = IRouteInfo | ITitleInfo

export const ROUTES_SETTINGS: TRoutesType[] = [
  {
    id: 0,
    name: null,
    route: null,
    side: 'left',
    title: 'Manage',
    isChildren: false,
  },
  /* {
    id: 1,
    name: 'Integration',
    route: '/integration',
    side: 'left',
    title: null,
    isChildren: false,
  }, */
  {
    id: 2,
    name: 'Pipelines',
    route: '/pipelines',
    side: 'left',
    title: null,
    isChildren: false,
  },
  {
    id: 3,
    name: 'Assignment Groups',
    route: '/assignment-groups',
    side: 'left',
    title: null,
    isChildren: false,
  },
  {
    id: 4,
    name: 'Carriers',
    route: '/carriers',
    side: 'left',
    title: null,
    isChildren: false,
  },
  {
    id: 5,
    name: 'Lead Sources',
    route: '/lead-sources',
    side: 'left',
    title: null,
    isChildren: false,
  },
  {
    id: 6,
    name: 'Lead Loss Reasons',
    route: '/lead-loss-reasons',
    side: 'left',
    title: null,
    isChildren: false,
  },
  /* {
    id: 7,
    name: 'Life & Health Products',
    route: '/life-health-products',
    side: 'left',
    title: null,
    isChildren: false,
  },
  {
    id: 8,
    name: 'Manage Custom Fields',
    route: '/manage-custom-fields',
    side: 'left',
    title: null,
    isChildren: false,
  }, */
  {
    id: 9,
    name: 'Policy Type Setup',
    route: '/policy-type-setup',
    side: 'left',
    title: null,
    isChildren: false,
  },
  /* {
    id: 10,
    name: 'Revenue Tracking Setup',
    route: '/revenue-tTracking-setup',
    side: 'left',
    title: null,
    isChildren: false,
  },
  {
    id: 11,
    name: 'Sales Goal',
    route: '/sales-goal',
    side: 'left',
    title: null,
    isChildren: false,
  }, */
  {
    id: 12,
    name: 'Tag Manager',
    route: '/tag-manager',
    side: 'left',
    title: null,
    isChildren: false,
  },
  {
    id: 13,
    name: null,
    route: null,
    side: 'right',
    title: 'My Agency',
    isChildren: false,
  },
  /* {
    id: 14,
    name: 'All About my agency',
    route: '/my-agency',
    side: 'right',
    title: null,
    isChildren: false,
  }, */
  /* {
    id: 15,
    name: 'All Members',
    route: '/members',
    side: 'right',
    title: null,
    isChildren: false,
  }, */
  /* {
    id: 16,
    name: '+ Add team member',
    route: '/members?open=true',
    side: 'right',
    title: null,
    isChildren: true,
  }, */
  /* {
    id: 17,
    name: '+ Add location',
    route: '/add-location',
    side: 'right',
    title: null,
    isChildren: true,
  },
  {
    id: 18,
    name: 'My Account',
    route: '/my-account',
    side: 'right',
    title: null,
    isChildren: false,
  },
  {
    id: 19,
    name: null,
    route: null,
    side: 'right',
    title: 'Compensation',
    isChildren: false,
  },
  {
    id: 20,
    name: 'Set Up Comp',
    route: '/set-up-comp',
    side: 'right',
    title: null,
    isChildren: false,
  },
  {
    id: 21,
    name: 'Run Comp',
    route: '/run-comp',
    side: 'right',
    title: null,
    isChildren: false,
  },
  {
    id: 22,
    name: 'Wiew Comp',
    route: '/wiew-comp',
    side: 'right',
    title: null,
    isChildren: false,
  },
  {
    id: 23,
    name: null,
    route: null,
    side: 'right',
    title: 'Customer retention',
    isChildren: false,
  },
  {
    id: 24,
    name: 'Thank You Cover Letter',
    route: '/thanks-cover-letter',
    side: 'right',
    title: null,
    isChildren: false,
  },
  {
    id: 25,
    name: 'Break-Up Letter',
    route: '/break-up-letter',
    side: 'right',
    title: null,
    isChildren: false,
  }, */
]

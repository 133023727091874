import {useLazyQuery, useMutation} from '@apollo/client'
import {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Button, Card, Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import moment from 'moment'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'

import {GET_ONE_PIPELINE} from '../../gql/queries/pipelines'
import {IResOrderStages, IResPipelines, IStagePipelineSet} from '../../types/pipelines'
import {useDisclourse} from '../../hooks/useDisclourse'
import {ModalAddPipeline} from '../pipelines/components/ModalAddPipeline'
import {ModalAddStage} from './ModalAddStage'
import {parseId} from '../../helpers'
import {UPDATE_ORDER_STAGES} from '../../gql/mutations/pipelineMutations'
import {ModalDeleteStage} from './components/ModalDeleteStage'

const grid = 8

const getItemStyle: any = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  ...draggableStyle,
  userSelect: 'none',
  minHeight: '340px',
  width: '278px',
})

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#f3f3f3' : '#fcfcfc',
  padding: grid,
  overflow: 'auto',
  gap: '1.1em',

  // width: 250,
})

const reorder = (arr: IStagePipelineSet[], start: number, end: number): IStagePipelineSet[] => {
  const result = arr.map(({node}) => ({node: {...node}}))
  if (start === end) return result

  const one = arr[start].node
  const two = arr[end].node

  result[start].node = two
  result[end].node = one

  return result
}

export const StagePage = () => {
  const [gqlOrder, {data: dataOrder, loading: loadingOrder, error: errorOrder}] =
    useMutation<IResOrderStages>(UPDATE_ORDER_STAGES)
  const {id} = useParams()
  const navigate = useNavigate()
  const [gqlGetPipeline, {data, loading, error, refetch}] = useLazyQuery<IResPipelines>(
    GET_ONE_PIPELINE,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const {isOpen: isOpenPipe, onClose: onClosePipe, onOpen: onOpenPipe} = useDisclourse()
  const {isOpen: isOpenStage, onClose: onCloseStage, onOpen: onOpenStage} = useDisclourse()
  const {isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete} = useDisclourse()

  const [dataArr, setDataArr] = useState<IStagePipelineSet[]>([])

  const [infoPipeline, setInfoPipeline] = useState<null | {name: string; id: string}>(null)
  const [infoStage, setInfoStage] = useState<null | {name: string; id: string; order: number}>(null)
  const [maxOrder, setMaxOrder] = useState(0)

  useEffect(() => {
    if (!id) return
    if (!Number(id)) {
      return navigate('/not-found')
    }
    gqlGetPipeline({variables: {id}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (error) {
      toast.error('Error: ' + error.message)
    }
  }, [error])
  useEffect(() => {
    if (errorOrder) toast.error('Error: ' + errorOrder.message)
  }, [errorOrder])

  useEffect(() => {
    if (!data) return
    const {edges} = data.allPipelines
    const info = edges?.[0]
    if (!info) return
    const maxOrderInfo =
      Math.max(...info.node.stagePipelineSet.edges.map(({node}) => node.order)) || 0
    setMaxOrder(maxOrderInfo >= 0 ? maxOrderInfo : 0)
    const arrD = info.node.stagePipelineSet?.edges || []
    // const infoOrders = arrD.map(({node}) => ({id: Number(parseId(node.id)), order: node.order}))
    // setOrderStage(infoOrders.sort((a, b) => a.order - b.order))
    setDataArr(arrD.sort((a, b) => a.node.order - b.node.order))
  }, [data])

  useEffect(() => {
    if (!dataOrder) return
    if (dataOrder.stageOrderPipelineMutation.success) {
      toast.success('Correctly organized stages')
      refetch()
    } else if (
      dataOrder.stageOrderPipelineMutation.errors &&
      dataOrder.stageOrderPipelineMutation.errors.length > 0
    ) {
      toast.error(`Error: ${dataOrder.stageOrderPipelineMutation.errors[0]?.message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOrder])

  const onDragEnd = (results: DropResult) => {
    if (!results?.destination) return
    const newArr = reorder(dataArr, results.source.index, results.destination.index)
    const preOrder = dataArr.map(({node}, i) => ({
      stageId: Number(parseId(node.id)),
      order: node.order,
    }))
    setDataArr(newArr)
    const newArrOrder = newArr.map(({node}) => ({
      stageId: Number(parseId(node.id)),
      order: node.order,
    }))
    const desorder = [] as {
      stageId: number
      order: number
    }[]

    newArrOrder.forEach(({order, stageId}, i) => {
      if (order !== preOrder[i]?.order) {
        desorder.push({order, stageId})
      }
    })
    if (desorder.length === 0) return
    const [init, last] = desorder
    const findI = newArrOrder.findIndex(({stageId}) => init.stageId === stageId)
    const findL = newArrOrder.findIndex(({stageId}) => last.stageId === stageId)
    if (findI < 0 || findL < 0) return

    newArrOrder[findI].order = last.order
    newArrOrder[findL].order = init.order
    // newArrOrder[findI].order =
    // .sort((a, b) => a.order - b.order)
    gqlOrder({
      variables: {
        stageOrderData: newArrOrder,
      },
    })
  }

  if (!data || loading) return <div>Cargando...</div>

  const info = data.allPipelines.edges[0]?.node

  if (!info) return <div>Pipeline not found</div>

  return (
    <>
      <section>
        <header className='d-flex align-items-center justify-content-between flex-column flex-md-row gap-2 mt-2 pb-4'>
          <div className='d-flex align-items-center gap-1'>
            <Link to='/pipelines' title='Back pipelines'>
              <i className='bi bi-arrow-left fs-2 text-info me-4' />
            </Link>
            <h2 className='d-flex align-items-center gap-2 fs-3 mb-0 pb-0'>
              {info.name}
              <button
                className='bg-transparent border-0 m-0'
                onClick={() => {
                  setInfoPipeline({id: info.id, name: info.name})
                  onOpenPipe()
                }}
              >
                <i className='bi bi-pencil text-info' />
              </button>
              {loadingOrder && <Spinner size='sm' className='ms-3' animation='border' />}
            </h2>
          </div>
          {/* <div className='d-flex align-items-center gap-2'>
          <Link to='/pipelines' className='btn btn-dark'>
            Cancel
          </Link>
          <Button>Save</Button>
        </div> */}
        </header>

        <main className='d-flex gap-2 w-100 mt-4'>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable' direction='horizontal'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className='d-flex w-100'
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {dataArr.map(({node}, idx) => (
                    <Draggable
                      key={Number(parseId(node.id))}
                      draggableId={`${parseId(node.id)}`}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                          }}
                        >
                          <Card
                            style={{minHeight: '340px', width: '276px'}}
                            className='d-flex flex-column h-100'
                          >
                            <Card.Header className='card-header d-flex gap-2 py-4 align-items-center justify-content-between w-100'>
                              <div>
                                <h5 className='card-title'>{node.name}</h5>
                              </div>
                              <div>
                                <button
                                  className='bg-transparent border-0'
                                  onClick={() => {
                                    setInfoStage({
                                      id: node.id,
                                      name: node.name,
                                      order: node.order,
                                    })
                                    onOpenStage()
                                  }}
                                >
                                  <i className='bi bi-pencil text-primary fs-2' />
                                </button>
                              </div>
                            </Card.Header>
                            <Card.Body className='p-3 h-100' style={{flex: 1}}>
                              <div className='d-flex flex-column justify-content-between h-100'>
                                <div className='card mb-3 bg-light'>
                                  <div className='card-body p-3'>
                                    <p>
                                      <span className='fw-bold d-inline-flex align-items-center gap-2'>
                                        {' '}
                                        <i className='bi bi-clock text-dark fs-4' />
                                        Created:{' '}
                                      </span>{' '}
                                      {moment(node.created).format('DD MMM YYYY')}
                                    </p>
                                    <p>
                                      <span className='fw-bold d-inline-flex align-items-center gap-2'>
                                        <i className='bi bi-clock-history text-dark fs-4' />
                                        Last Updated:{' '}
                                      </span>{' '}
                                      <span>{moment(node.updated).fromNow()}</span>
                                    </p>
                                  </div>
                                </div>

                                <Button
                                  className='w-100'
                                  variant='danger'
                                  size='lg'
                                  onClick={() => {
                                    setInfoStage({id: node.id, name: node.name, order: node.order})
                                    onOpenDelete()
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div>
                    <Card
                      style={{minHeight: '340px', width: '276px', border: '1px dashed gray'}}
                      className='d-flex flex-column h-100'
                    >
                      <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                        <div className='px-4'>
                          <h4 className='text-center'>Add New Stage</h4>
                          <p className='text-muted text-center'>
                            Pipeline stages represent the steps in your sales process
                          </p>
                          <div className='w-100 d-flex align-items-center justify-content-center'>
                            <Button
                              className='d-flex align-items-center gap-2'
                              onClick={() => {
                                setInfoStage(null)
                                onOpenStage()
                              }}
                            >
                              <i className='bi bi-plus fs-1' />
                              New Stage
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </main>
      </section>
      {infoPipeline && isOpenPipe && (
        <ModalAddPipeline
          isOpen={isOpenPipe}
          onClose={onClosePipe}
          infoEdit={infoPipeline}
          refetch={refetch}
        />
      )}
      {isOpenStage && id && (
        <ModalAddStage
          isOpen={isOpenStage}
          onClose={onCloseStage}
          infoEdit={infoStage}
          refetch={refetch}
          pipelineId={id}
          maxOrder={maxOrder}
        />
      )}
      {isOpenDelete && infoStage && (
        <ModalDeleteStage
          isOpen={isOpenDelete}
          onClose={onCloseDelete}
          infoEdit={infoStage}
          refetch={refetch}
        />
      )}
    </>
  )
}

import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {DragDropContext, DraggableLocation, Droppable, DropResult} from 'react-beautiful-dnd'
import {Card, Spinner} from 'react-bootstrap'
import {AllLeadStageEdge, IFilterStage, IInfoLead, IResChangeState} from '../leadTypes'
import {EdgePipelines, IStagePipelineSet} from '../../../types/pipelines'
import Lottie from 'lottie-react'
import noDataLottie from '../../../assets/lotties/no-data.json'
import {parseId} from '../../../helpers'
import {BodyLeadsDragDrop} from './BodyLeadsDragDrop'
import {useMutation} from '@apollo/client'
import {CHANGE_STATE_LEAD} from '../../../gql/mutations/leadMutations'
import {toast} from 'react-toastify'

/* const getItems = (count: number, offset = 0) =>
  Array.from({length: count}, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`,
  }))
*/

const STATUS_LEADS = {
  Declined: 'Declined',
  Sold: 'Sold',
}

const reorder = (list: AllLeadStageEdge[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#4C86CD' : 'white',
  padding: grid,
  width: 250,
})

const move = (
  source: AllLeadStageEdge[],
  destination: AllLeadStageEdge[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  /* const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone */

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  }
}

interface ILeadsArr extends IStagePipelineSet {
  leads: AllLeadStageEdge[]
  total: null | number
}

interface IContentDragProps {
  setInfoLead: Dispatch<SetStateAction<IInfoLead | null>>
  onOpenEdit: () => void
  pipelines: EdgePipelines[]
  pipelineId: string
  updateListLeads: () => void
  flagLeads: number
  setLoadingState: (b: boolean) => void
  filterStage: IFilterStage
}

export const ContentDragDropLeads: React.FC<IContentDragProps> = ({
  onOpenEdit,
  setInfoLead,
  pipelineId,
  pipelines,
  flagLeads,
  updateListLeads,
  setLoadingState,
  filterStage,
}) => {
  const [gqlChange, {data: dataChange, loading: loadingChange, error: errorChange}] =
    useMutation<IResChangeState>(CHANGE_STATE_LEAD)

  const [stagesLeadsArr, setStagesLeadsArr] = useState<ILeadsArr[]>([])

  useEffect(() => {
    updateListLeads()
    if (errorChange) {
      toast.error(`Error: ${errorChange.message}`)
      updateListLeads()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorChange])

  useEffect(() => {
    if (!dataChange) return
    if (dataChange.changeLeadStage.success) {
      toast.success('Lead has changed state successfully')
    } else if (dataChange.changeLeadStage.errors && dataChange.changeLeadStage.errors.length > 0) {
      toast.error(`Error: ${dataChange.changeLeadStage.errors[0].message}`)
      updateListLeads()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChange])

  useEffect(() => {
    if (!pipelines) return
    const pipeFound = pipelines.find(({node}) => node.id === pipelineId)
    if (!pipeFound) return
    const stateP = pipeFound.node.stagePipelineSet.edges
    if (stateP.length === 0) {
      setStagesLeadsArr([])
      return
    }
    setStagesLeadsArr(stateP.map(({node}) => ({node, leads: [], total: null})))
  }, [pipelines, pipelineId])

  useEffect(() => {
    setLoadingState(loadingChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingChange])

  const updateOneLead = ({idStage, leads = []}: {idStage: string; leads: AllLeadStageEdge[]}) => {
    setStagesLeadsArr((p) => {
      const nArr = [...p]
      const findI = nArr.findIndex(({node}) => node.id === idStage)
      if (findI < 0) return nArr
      nArr[findI].leads = leads
      return nArr
    })
  }

  const initTotal = ({idStage, total}: {idStage: string; total: number}) => {
    setStagesLeadsArr((p) => {
      const nArr = [...p]
      const findI = nArr.findIndex(({node}) => node.id === idStage)
      if (findI < 0) return nArr
      nArr[findI].total = total
      return nArr
    })
  }

  const onDragEnd = (result: DropResult) => {
    const {destination, source, draggableId} = result

    if (!destination) return

    const sInd = +source.droppableId
    const dInd = +destination.droppableId
    if (sInd === dInd) {
      const arr = [...stagesLeadsArr]
      const find = arr.find(({node}) => parseId(node.id) === source.droppableId)
      if (!find) return
      const nLeads = reorder(find.leads, source.index, destination.index)
      console.log('nLeads', nLeads)
      find.leads = nLeads
      setStagesLeadsArr(arr)
      return
    }
    const arr = [...stagesLeadsArr]
    const findFrom = arr.find(({node}) => parseId(node.id) === source.droppableId)
    const findTo = arr.find(({node}) => parseId(node.id) === destination.droppableId)

    if (!findFrom || !findTo) return

    const resMove = move(findFrom.leads, findTo.leads, source, destination)
    findFrom.leads = resMove[sInd]
    if (findFrom.total != null) findFrom.total -= 1
    findTo.leads = resMove[dInd]
    if (findTo.total != null) findTo.total += 1
    setStagesLeadsArr(arr)
    console.log('result', result)
    gqlChange({
      variables: {
        leadId: draggableId,
        stageId: dInd,
      },
    })

    /* if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index)
      const newState = [...state]
      newState[sInd] = items
      setState(newState)
    } else {
      const result = move(state[sInd], state[dInd], source, destination)
      const newState = [...state]
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]

      setState(newState.filter((group) => group.length))
    } */
  }

  const pipeFound = pipelines.find(({node}) => node.id === pipelineId)

  if (!pipeFound) return null

  const stateP = pipeFound.node.stagePipelineSet.edges

  if (
    stateP.length === 0 ||
    stagesLeadsArr.filter(({node}) => {
      return filterStage === 'all'
        ? true
        : filterStage === 'others'
        ? node.name !== STATUS_LEADS.Sold && node.name !== STATUS_LEADS.Declined
        : node.name === STATUS_LEADS.Sold || node.name === STATUS_LEADS.Declined
    }).length === 0
  ) {
    return (
      <div
        className='d-flex align-items-center justify-content-center w-100 flex-column'
        style={{height: '100%', minHeight: '400px'}}
      >
        <h4>This pipeline has no stages</h4>
        <div>
          <Lottie animationData={noDataLottie} autoplay style={{maxWidth: '400px'}} />
        </div>
      </div>
    )
  }

  console.log('stagesLeadsArr', stagesLeadsArr)

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {/* {loadingChange && (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            position: 'absolute',
            inset: 0,
            backgroundColor: '#000',
            opacity: 0.62,
            zIndex: 9999999,
          }}
        >
          <Spinner animation='border' style={{color: 'white'}} />
        </div>
      )} */}
      {stagesLeadsArr
        .sort((a, b) => a.node.order - b.node.order)
        .filter(({node}) => {
          return filterStage === 'all'
            ? true
            : filterStage === 'others'
            ? node.name !== STATUS_LEADS.Sold && node.name !== STATUS_LEADS.Declined
            : node.name === STATUS_LEADS.Sold || node.name === STATUS_LEADS.Declined
        })
        .map(({node, leads, total}) => (
          <Droppable key={node.id} droppableId={`${parseId(node.id)}`}>
            {(provided, snapshot) => (
              <Card
                ref={provided.innerRef}
                style={{...getListStyle(snapshot.isDraggingOver), flex: '1 0 250px'}}
                {...provided.droppableProps}
              >
                <div
                  style={{borderBottom: '1px solid #f1f1f2'}}
                  className='px-3 py-2 d-flex align-items-center justify-content-between'
                >
                  <Card.Title>{node.name}</Card.Title>
                  <div>
                    <span
                      className='d-flex align-items-center justify-content-center px-3 py-1 text-primary'
                      style={{
                        borderRadius: '20px',
                        backgroundColor: '#ddf1ff',
                        fontWeight: 'bold',
                      }}
                    >
                      {total ?? <Spinner animation='grow' size='sm' />}
                    </span>
                  </div>
                </div>

                <BodyLeadsDragDrop
                  onOpenEdit={onOpenEdit}
                  providedParent={provided}
                  setInfoLead={setInfoLead}
                  stageId={node.id}
                  flagLeads={flagLeads}
                  leads={leads}
                  updateOneLead={updateOneLead}
                  initTotal={initTotal}
                  total={total}
                />
              </Card>
            )}
          </Droppable>
        ))}
    </DragDropContext>
  )
}

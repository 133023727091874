import {Link} from 'react-router-dom'
import {ListGroup} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {ROUTES_SETTINGS, TRoutesType} from './routesSetting'

const ROUTES_LEFT = ROUTES_SETTINGS.filter(({side}) => side === 'left')
const ROUTES_RIGHT = ROUTES_SETTINGS.filter(({side}) => side === 'right')

const ContentSide = ({routeInfo}: {routeInfo: TRoutesType}) => {
  if (routeInfo.title || !routeInfo.route) {
    return null
  }
  return (
    <ListGroup.Item
      as={Link}
      to={routeInfo.route}
      style={{
        border: '0',
        color: routeInfo.isChildren ? '#3e97ff' : '#133159',
        borderBottom: routeInfo.name === 'Pipelines' ? '1px solid #e1e1e2 ' : undefined,
      }}
      className={`${routeInfo.name === 'Pipelines' ? '' : 'border-0'} ${
        routeInfo.isChildren ? 'ms-3' : ''
      }`}
    >
      {routeInfo.name}
    </ListGroup.Item>
  )
}

export const NavSettingsDesktop = () => {
  return (
    <div className='my-0 menu-item d-none d-md-block'>
      <button
        type='button'
        className='btn w-100 btn-sm btn-icon btn-color-light menu-link without-sub'
        data-kt-menu-trigger="{sm: 'click', md: 'hover',lg: 'hover'}"
        data-kt-menu-placement='right-end'
        data-kt-menu-flip='right-end'
      >
        <KTIcon iconName='category' className='fs-2' />{' '}
        <span className='menu-title ms-2'>My Agency</span>
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown w-300px w-md-350px'
        data-kt-menu='true'
        style={{minHeight: '460px'}}
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>
            <div className='avatar me-2'>TF</div>
            The Future Insurance
          </div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='d-flex h-100' style={{flex: 1}}>
          <div className='w-100 h-100' style={{borderRight: '1px solid #f1f1f2'}}>
            <h6 className='p-2' style={{color: '#a4a4a4'}}>
              MANAGE
            </h6>
            <ListGroup style={{border: 0}}>
              {ROUTES_LEFT.map((routeInfo) => (
                <ContentSide key={routeInfo.id} routeInfo={routeInfo} />
              ))}
            </ListGroup>
          </div>
          <div className='w-100 h-100'>
            <h6 className='p-2' style={{color: '#a4a4a4'}}>
              MY AGENCY
            </h6>
            <ListGroup style={{border: 0}}>
              {ROUTES_RIGHT.slice(0, 5).map((info) => (
                <ContentSide routeInfo={info} key={info.id} />
              ))}
            </ListGroup>
            {/* <h6 className='p-2' style={{color: '#a4a4a4'}}>
              COMPENSATION
            </h6> */}
            <ListGroup style={{border: 0}}>
              {ROUTES_RIGHT.slice(6, 9).map((info) => (
                <ContentSide routeInfo={info} key={info.id} />
              ))}
            </ListGroup>
            {/* <h6 className='p-2' style={{color: '#a4a4a4'}}>
              CUSTOMER RETENTION
            </h6> */}
            <ListGroup style={{border: 0}}>
              {ROUTES_RIGHT.slice(10).map((info) => (
                <ContentSide routeInfo={info} key={info.id} />
              ))}
            </ListGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

import {gql} from '@apollo/client'

export const ADD_CARRIER = gql`
  mutation createInsuranceOperator($insuranceOperatorData: insurance_operator_input!) {
    createInsuranceOperator(insuranceOperatorData: $insuranceOperatorData) {
      insuranceOperator {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const ADD_COMMISSION = gql`
  mutation createCommission($commissionData: commission_input!) {
    createCommission(commissionData: $commissionData) {
      insuranceOperator {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const EDIT_COMMISSION = gql`
  mutation updateCommission($commissionDate: commission2_input!, $id: Int!) {
    updateCommission(commissionDate: $commissionDate, id: $id) {
      commission {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const DELETE_COMMISION = gql`
  mutation deleteCommission($id: Int!) {
    deleteCommission(id: $id) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const UPLOAD_EXCEL_STR = `
  mutation UpdateCommissionMutation($excelFile: Upload!) {
    UpdateCommissionMutation(excelFile: $excelFile) {
      success
      message
    }
  }
`

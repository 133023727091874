import {useMutation} from '@apollo/client'
import {Button, Form, Modal, Spinner} from 'react-bootstrap'
import {CREATE_TRANSACTION} from '../../../gql/mutations/transactionMutation'
import {FormEvent, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {IResAddTransaction} from '../../../types/transactions'
import {InputCurrency} from '../../../components/InputCurrency'

interface ModalAddT {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  info: string
  refetchTransaction: () => void
}

const TYPES = ['Ingreso', 'Egreso'] as const

interface IForm {
  type: '' | typeof TYPES[number]
  value: string
}

export const ModalAddTransaction: React.FC<ModalAddT> = ({
  info,
  isOpen,
  onClose,
  refetch,
  refetchTransaction,
}) => {
  const [gqlCreate, {data, loading, error}] = useMutation<IResAddTransaction>(CREATE_TRANSACTION)

  const [form, setForm] = useState<IForm>({type: '', value: ''})

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data?.createTransaction?.transaction?.id) {
      toast.success('Transaction added successfully')
      onClose()
      refetch()
      refetchTransaction()
    } else if (data.createTransaction?.errors?.length > 0) {
      toast.error(`Error: ${data.createTransaction.errors?.[0]?.message || 'Something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!form.type || !form.value) return
    const transactionData = {
      ...form,
      walletId: info,
    }
    gqlCreate({
      variables: {
        transactionData,
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Transaction</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <Form.Label className='d-flex align-items-center gap-2'>
              Type
              {form.type && (
                <i
                  className={`fs-2 bi bi-${form.type === 'Ingreso' ? 'arrow-left' : 'arrow-right'}`}
                  style={{
                    color: form.type === 'Ingreso' ? 'green' : 'red',
                  }}
                />
              )}
            </Form.Label>
            <Form.Select
              required
              value={form.type}
              onChange={(e) => setForm((p) => ({...p, type: e.target.value as IForm['type']}))}
            >
              <option value=''>Select type</option>
              {TYPES.map((t) => (
                <option key={t} value={t}>
                  {t === 'Ingreso' ? 'Income' : 'Expense'}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <Form.Label>Value</Form.Label>
            <InputCurrency
              required
              value={form.value}
              onValueChange={(v) => setForm((p) => ({...p, value: v || ''}))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' type='button' onClick={onClose}>
            Close
          </Button>
          <Button variant='primary' type='submit' disabled={loading}>
            {loading && <Spinner animation='border' size='sm' className='me-2' />}
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

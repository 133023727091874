import {Accordion, Button, Col, Form, InputGroup, Row, Spinner} from 'react-bootstrap'
import Select, {SingleValue} from 'react-select'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {COUNTRIES} from '../../../../helpers/consts'
import {useMutation} from '@apollo/client'
import {ADD_MEMBER_POLICY} from '../../../../gql/mutations/membersPolicyMutations'
import {IResAddMembPolicy} from '../../../../types/membersPolicy'
import {toast} from 'react-toastify'
import moment from 'moment'
import {useSearchParams} from 'react-router-dom'

const IMMIGRATION_OPTIONS = [
  'Citizen',
  'Resident',
  'Asylum Seeker',
  'Employment Authorization Document (EAD)',
  'Temporary Protected Status (TPS)',
  'Humanitarian Parole',
  'Other',
]

const MATRIAL_STATUS = ['Married', 'Head of Household', 'Single', 'Other']

const INIT_FORM_MEMBERS = {
  applicant: '',
  rol: '',
  firstName: '',
  middleName: '',
  lastName: '',
  secondLastName: '',
  preferredLenguage: '',
  dateBirth: '',
  gender: '',
  emailAddress: '',
  phone: '',
  maritalStatus: '',
  countryBirth: '',
  weight: '',
  height: '',
  immigrationStatus: '',
  immigrationStatusCode: '',
  uscis: '',
  passport: '',
  ssn: '',
  ssnIssuedDate: '',
  greenCard: '',
  greenCardIssuedDate: '',
  greenCardExpirationDate: '',
  workPermitCard: '',
  workPermitIssuedDate: '',
  workPermitExpirationDate: '',
  driverLicense: '',
  driverLicenseExpirationDate: '',
  physicalAddress: '',
  mailingAddress: '',
  sourceOfIncome1: '',
  sourceOfIncome2: '',
  sourceOfIncome3: '',
  policy: '',
  id: '',
}

interface IMembersAddProps {
  deleteItem: (id: number) => void
  mem: {
    id: number
    open: boolean
  }
  refetch: () => void
}

export const MemberAdd: React.FC<IMembersAddProps> = ({deleteItem, mem, refetch}) => {
  const [gqlAddMemb, {data, loading, error}] = useMutation<IResAddMembPolicy>(ADD_MEMBER_POLICY)

  const [searchParams] = useSearchParams()
  const policy = searchParams.get('policy')

  const [selectValueCountry, setSelectValueCountry] = useState<null | SingleValue<{
    label: string
    value: string
  }>>(null)

  const formik = useFormik({
    initialValues: INIT_FORM_MEMBERS,
    onSubmit: (v) => {
      if (!policy || !Number(policy)) return
      const input = {
        ...v,
        policy,
        applicant: v.applicant === 'yes' ? true : v.applicant === 'no' ? false : undefined,
      }
      gqlAddMemb({
        variables: {
          input,
        },
      })
    },
  })

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.memberPolicyCreateupdateMutation?.memberPolicy?.id) {
      toast.success('Member added successfully')
      refetch()
      deleteItem(mem.id)
    } else if (
      data.memberPolicyCreateupdateMutation?.errors &&
      data.memberPolicyCreateupdateMutation?.errors?.length > 0
    ) {
      toast.error(
        `Error: ${
          data.memberPolicyCreateupdateMutation.errors?.[0]?.messages?.[0] || 'something went wrong'
        }`
      )
    }
  }, [data])

  const handleSubmit = () => {
    formik.handleSubmit()
  }

  return (
    <div>
      <Accordion className='my-5'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>Personal information</Accordion.Header>
          <Accordion.Body>
            <Row className='mb-3'>
              <Col className='mb-4' md={6}>
                <Form.Group>
                  <Form.Label>
                    Is this member an applicant?{' '}
                    <small className='kt-font-danger kt-font-bold'>(required)</small>
                  </Form.Label>
                  <Form.Select
                    value={formik.values.applicant}
                    name='applicant'
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select option</option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col className='mb-4' md={6}>
                <Form.Group>
                  <Form.Label>
                    Preferred language <small className='text-danger'>(required)</small>
                  </Form.Label>
                  <Form.Select
                    value={formik.values.preferredLenguage}
                    name='preferredLenguage'
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select one</option>
                    <option value='spanish'>spanish</option>
                    <option value='english'>english</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    First name <small className='kt-font-danger kt-font-bold'>(required)</small>
                  </Form.Label>
                  <Form.Control
                    name='firstName'
                    id='firstName'
                    required
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='middleName'>Middle name</Form.Label>
                  <Form.Control
                    name='middleName'
                    id='middleName'
                    value={formik.values.middleName}
                    onChange={formik.handleChange}
                    placeholder='(optional)'
                    // onChange={(e) => onChange(e.target, 'policyholder')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='mb-3 form-group form-group-md row'>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='lastName'>
                    Last name <small className='kt-font-danger kt-font-bold'>(required)</small>
                  </Form.Label>
                  <Form.Control
                    name='lastName'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    id='lastName'
                    className='form-control '
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='secondLastName'>Second last name</Form.Label>
                  <Form.Control
                    name='secondLastName'
                    value={formik.values.secondLastName}
                    onChange={formik.handleChange}
                    id='secondLastName'
                    placeholder='(optional)'
                  />
                </Form.Group>
              </Col>
            </div>
            <Row className='mb-3'>
              <Col md={12} className='mb-4'>
                <Form.Group>
                  <Form.Label htmlFor='rol'>
                    Rol <small className='text-danger'>(required)</small>
                  </Form.Label>
                  <Form.Select
                    value={formik.values.rol}
                    name='rol'
                    id='rol'
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select one</option>
                    <option value='HOLDER'>Holder</option>
                    <option value='FAMILY'>Family</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className='mb-4'>
                <Form.Group>
                  <Form.Label htmlFor='physicalAddress'>Physical Address</Form.Label>
                  <Form.Control
                    value={formik.values.physicalAddress}
                    name='physicalAddress'
                    id='physicalAddress'
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className='mb-4'>
                <Form.Group>
                  <Form.Label htmlFor='mailingAddress'>Mailing Address</Form.Label>
                  <Form.Control
                    value={formik.values.mailingAddress}
                    name='mailingAddress'
                    id='mailingAddress'
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    style={{width: '100%'}}
                    htmlFor='dateBirth'
                    className='d-flex align-items-center'
                  >
                    <span>
                      Date of birth{' '}
                      <small className='kt-font-danger kt-font-bold'>(required)</small>{' '}
                    </span>
                    <span
                      className='text-info'
                      style={{display: 'inline-block', textAlign: 'right', flex: 1}}
                    >
                      {formik.values.dateBirth
                        ? `Age: ${moment().diff(moment(formik.values.dateBirth), 'years')}`
                        : 'Age: -'}
                    </span>
                  </Form.Label>
                  {/* <InputGroup className='mb-3'>
                  <InputGroup.Text id='basic-addon1'>
                  <i className='la la-calendar-check-o' />
                  </InputGroup.Text> */}
                  <Form.Control
                    type='date'
                    id='dateBirth'
                    name='dateBirth'
                    value={formik.values.dateBirth}
                    onChange={formik.handleChange}
                    // onChange={(e) => onChange(e.target, 'policyholder')}
                  />
                  {/* </InputGroup> */}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='gender'>Gender</Form.Label>
                  <Form.Select
                    aria-label='Default select example'
                    name='gender'
                    id='gender'
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    // onChange={(e) => onChange(e.target, 'policyholder')}
                  >
                    <option value=''>Select one</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Other'>Other</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='emailAddress'>Email address</Form.Label>
                  <Form.Control
                    type='email'
                    name='emailAddress'
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    // onChange={(e) => onChange(e.target, 'policyholder')}
                    id='emailAddress'
                    autoComplete='off'
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label htmlFor='phone'>Phone</Form.Label>
                  <Form.Control
                    type='tel'
                    name='phone'
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    // onChange={(e) => onChange(e.target, 'policyholder')}
                    id='phone'
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'>
                <label htmlFor='maritalStatus'>Marital status</label>
                <Form.Select
                  name='maritalStatus'
                  id='maritalStatus'
                  value={formik.values.maritalStatus}
                  onChange={formik.handleChange}
                  aria-label='Default select example'
                >
                  <option value=''>Select one</option>
                  {MATRIAL_STATUS.map((mari) => (
                    <option key={mari} value={mari}>
                      {mari}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className='col-lg-6'>
                <label htmlFor='countryBirth'>Country of birth</label>
                <Select
                  id='countryBirth'
                  value={selectValueCountry}
                  onChange={(e) => {
                    setSelectValueCountry(e)
                    formik.setFieldValue('countryBirth', e?.value || '')
                  }}
                  options={COUNTRIES}
                  styles={{control: (base) => ({...base, padding: '3px 0'})}}
                />
              </div>
            </div>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'>
                <label htmlFor='weight'>Weight</label>
                <input
                  type='text'
                  name='weight'
                  id='weight'
                  value={formik.values.weight}
                  onChange={(e) => {
                    const {value} = e.target
                    if (value === '') return formik.setFieldValue('weight', '')
                    if (Number(value)) {
                      formik.setFieldValue('weight', value)
                    }
                  }}
                  className='form-control  percent_mask'
                />
              </div>
              <div className='col-lg-6'>
                <label htmlFor='height'>Height</label>
                <input
                  type='text'
                  name='height'
                  id='height'
                  value={formik.values.height}
                  onChange={(e) => {
                    const {value} = e.target
                    if (value === '') return formik.setFieldValue('height', '')
                    if (Number(value)) {
                      formik.setFieldValue('height', value)
                    }
                  }}
                  className='form-control  height_mask'
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>Employment information</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={4} sm={12}>
                <h4 className='mb-4'>Source of income #1</h4>
                <Form.Group className='mb-3'>
                  {/* <Form.Label>Employer name / Self employed</Form.Label> */}
                  <Form.Control
                    placeholder=''
                    onChange={formik.handleChange}
                    name='sourceOfIncome1'
                    id='sourceOfIncome1'
                    value={formik.values.sourceOfIncome1}
                  />
                </Form.Group>
                {/* <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label>Employer phone number</Form.Label>
                  <Form.Control defaultValue='' placeholder='' />
                </Form.Group>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label>Position/Occupation</Form.Label>
                  <Form.Control defaultValue='' placeholder='' />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Annual income</Form.Label>
                  <InputGroup className='mb-0'>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control />
                  </InputGroup>
                </Form.Group>
                <small>Type digits only.</small> */}
              </Col>
              <Col md={4} sm={12}>
                <h4 className='mb-4'>Source of income #2</h4>

                <Form.Group className='mb-3'>
                  {/* <Form.Label>Employer name / Self employed</Form.Label> */}
                  <Form.Control
                    placeholder=''
                    name='sourceOfIncome2'
                    id='sourceOfIncome2'
                    value={formik.values.sourceOfIncome2}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
                {/* <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label>Employer phone number</Form.Label>
                  <Form.Control defaultValue='' placeholder='' />
                </Form.Group>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label>Position/Occupation</Form.Label>
                  <Form.Control defaultValue='' placeholder='' />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Annual income</Form.Label>
                  <InputGroup className='mb-0'>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control />
                  </InputGroup>
                </Form.Group> */}
              </Col>
              <Col md={4} sm={12}>
                <h4 className='mb-4'>Source of income #3</h4>

                <Form.Group className='mb-3'>
                  {/* <Form.Label>Employer name / Self employed</Form.Label> */}
                  <Form.Control
                    placeholder=''
                    name='sourceOfIncome3'
                    id='sourceOfIncome3'
                    value={formik.values.sourceOfIncome3}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
                {/* <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label>Employer phone number</Form.Label>
                  <Form.Control defaultValue='' placeholder='' />
                </Form.Group>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label>Position/Occupation</Form.Label>
                  <Form.Control defaultValue='' placeholder='' />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Annual income</Form.Label>
                  <InputGroup className='mb-0'>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control />
                  </InputGroup>
                </Form.Group> */}
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>Legal documents</Accordion.Header>
          <Accordion.Body>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'>
                <Form.Group className=''>
                  <Form.Label htmlFor='immigrationStatus'>Immigration status</Form.Label>
                  <Form.Select
                    id='immigrationStatus'
                    name='immigrationStatus'
                    value={formik.values.immigrationStatus}
                    onChange={formik.handleChange}
                    aria-label='Default select example'
                  >
                    <option value=''>Open this select menu</option>
                    {IMMIGRATION_OPTIONS.map((st) => (
                      <option value={st} key={st}>
                        {st}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className='col-lg-6'>
                <Form.Group className=''>
                  <Form.Label htmlFor='immigrationStatusCode'>
                    Immigration status category
                  </Form.Label>
                  <Form.Control
                    id='immigrationStatusCode'
                    name='immigrationStatusCode'
                    value={formik.values.immigrationStatusCode}
                    onChange={formik.handleChange}
                    placeholder='eg. C08'
                  />
                </Form.Group>
              </div>
            </div>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'>
                <Form.Label htmlFor='uscis'>USCIS #</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    id='uscis'
                    name='uscis'
                    value={formik.values.uscis}
                    onChange={formik.handleChange}
                  />
                </InputGroup>
              </div>
              <div className='col-lg-6'>
                <Form.Label htmlFor='passport'>Passport #</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    id='passport'
                    name='passport'
                    onChange={formik.handleChange}
                    value={formik.values.passport}
                  />
                </InputGroup>
              </div>
            </div>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'>
                <Form.Label htmlFor='ssn'>Social Security Number (SSN)</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    name='ssn'
                    id='ssn'
                    onChange={formik.handleChange}
                    value={formik.values.ssn}
                  />
                  <Button variant='outline-secondary' className='border' id='button-addon2'>
                    <i className='bi bi-eye-fill' />
                  </Button>
                </InputGroup>
              </div>
              <div className='col-lg-6'>
                <Form.Group className=''>
                  <Form.Label htmlFor='ssnIssuedDate'>SSN issued date</Form.Label>
                  <Form.Control
                    name='ssnIssuedDate'
                    id='ssnIssuedDate'
                    onChange={formik.handleChange}
                    value={formik.values.ssnIssuedDate}
                    type='date'
                  />
                </Form.Group>
              </div>
            </div>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'>
                <Form.Label htmlFor='greenCard'>Green card #</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    name='greenCard'
                    id='greenCard'
                    onChange={formik.handleChange}
                    value={formik.values.greenCard}
                  />
                </InputGroup>
              </div>
              <div className='col-lg-6'>
                <Form.Group className=''>
                  <Form.Label htmlFor='greenCardIssuedDate'>Green card issue date</Form.Label>
                  <Form.Control
                    type='date'
                    name='greenCardIssuedDate'
                    id='greenCardIssuedDate'
                    onChange={formik.handleChange}
                    value={formik.values.greenCardIssuedDate}
                  />
                </Form.Group>
              </div>
            </div>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'></div>
              <div className='col-lg-6'>
                <Form.Group className=''>
                  <Form.Label htmlFor='greenCardExpirationDate'>
                    Green card expiration date
                  </Form.Label>
                  <Form.Control
                    type='date'
                    name='greenCardExpirationDate'
                    id='greenCardExpirationDate'
                    onChange={formik.handleChange}
                    value={formik.values.greenCardExpirationDate}
                  />
                </Form.Group>
              </div>
            </div>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'>
                <Form.Label htmlFor='workPermitCard'>Work permit card #</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    name='workPermitCard'
                    id='workPermitCard'
                    onChange={formik.handleChange}
                    value={formik.values.workPermitCard}
                  />
                </InputGroup>
              </div>
              <div className='col-lg-6'>
                <Form.Group className=''>
                  <Form.Label htmlFor='workPermitIssuedDate'>Work permit issue date</Form.Label>
                  <Form.Control
                    type='date'
                    name='workPermitIssuedDate'
                    id='workPermitIssuedDate'
                    onChange={formik.handleChange}
                    value={formik.values.workPermitIssuedDate}
                  />
                </Form.Group>
              </div>
            </div>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'></div>
              <div className='col-lg-6'>
                <Form.Group className=''>
                  <Form.Label htmlFor='workPermitExpirationDate'>
                    Work permit expiration date
                  </Form.Label>
                  <Form.Control
                    type='date'
                    name='workPermitExpirationDate'
                    id='workPermitExpirationDate'
                    onChange={formik.handleChange}
                    value={formik.values.workPermitExpirationDate}
                  />
                </Form.Group>
              </div>
            </div>
            <div className='mb-3 form-group form-group-md row'>
              <div className='col-lg-6'>
                <Form.Label htmlFor='driverLicense'>Driver license #</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    name='driverLicense'
                    id='driverLicense'
                    onChange={formik.handleChange}
                    value={formik.values.driverLicense}
                  />
                </InputGroup>
              </div>
              <div className='col-lg-6'>
                <Form.Group className=''>
                  <Form.Label htmlFor='driverLicenseExpirationDate'>
                    Driver license expiration date
                  </Form.Label>
                  <Form.Control
                    type='date'
                    name='driverLicenseExpirationDate'
                    id='driverLicenseExpirationDate'
                    onChange={formik.handleChange}
                    value={formik.values.driverLicenseExpirationDate}
                  />
                </Form.Group>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div>
        <Button size='sm' className='me-3' disabled={loading} onClick={handleSubmit}>
          {loading && <Spinner animation='border' size='sm' />}
          Save changes
        </Button>
        <Button
          size='sm'
          variant='danger'
          onClick={() => {
            // setArrMembers((p) => p.filter((m) => m.id !== mem.id))
            deleteItem(mem.id)
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  )
}

import {Button, Col, Form, Modal, Row, Spinner} from 'react-bootstrap'
import {InputCurrency} from '../../../components/InputCurrency'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useMutation, useQuery} from '@apollo/client'
import {GET_ALL_POLICIES_TYPES} from '../../../gql/queries/policiesQueries'
import {IResPoliciesType} from '../../../types/policies'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {GET_STATES_EU} from '../../../gql/queries/statesEUQuery'
import {IResStates} from '../../../types/statesEU'
import Select, {SingleValue} from 'react-select'
import {ADD_COMMISSION} from '../../../gql/mutations/carriersMutations'
import {IResCreateCommission} from '../../../types/carriers'
import {parseId} from '../../../helpers'

interface IModalAddCommissionProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  info: {
    id: string
  }
}

const INIT_VALUES = {
  insuranceOperator: '',
  policyType: '',
  percentage: '',
  nominal: '',
  state: '',
}

const validationSchema = Yup.object().shape({
  state: Yup.string().required('Is required'),
  policyType: Yup.string().required('Is required'),
})

export const ModalAddCommission: React.FC<IModalAddCommissionProps> = ({
  isOpen,
  onClose,
  info,
  refetch,
}) => {
  const [gqlCreate, {data, loading, error}] = useMutation<IResCreateCommission>(ADD_COMMISSION)

  const {
    data: dataPolicies,
    loading: loadingPolicies,
    error: errorPolicies,
  } = useQuery<IResPoliciesType>(GET_ALL_POLICIES_TYPES, {
    fetchPolicy: 'no-cache',
    variables: {first: 40},
  })
  const {
    data: dataStates,
    loading: loadingStates,
    error: errorStates,
  } = useQuery<IResStates>(GET_STATES_EU, {
    fetchPolicy: 'no-cache',
    variables: {first: 40},
  })

  const [stateValue, setStateValue] = useState<SingleValue<{label: string; value: string}>>()

  const formik = useFormik({
    initialValues: INIT_VALUES,
    validationSchema,
    onSubmit: (v) => {
      console.log('v', v)
      if (!v.percentage && !v.nominal) {
        formik.setFieldError('percentage', 'Enter percentage or nominal value')
        return
      }
      const commissionData = {
        ...v,
        insuranceOperator: parseId(info.id),
        policyType: parseId(v.policyType),
        percentage: v.percentage ? Number(v.percentage) : 0,
        nominal: v.nominal ? Number(v.nominal) : 0,
      }
      gqlCreate({
        variables: {
          commissionData,
        },
      })
    },
  })

  useEffect(() => {
    if (errorPolicies) toast.error(`Error ${errorPolicies.message}`)
  }, [errorPolicies])
  useEffect(() => {
    if (errorStates) toast.error(`Error ${errorStates.message}`)
  }, [errorStates])
  useEffect(() => {
    if (error) toast.error(`Error ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.createCommission?.insuranceOperator?.id) {
      toast.success('Commission added successfully')
      onClose()
      refetch()
    } else if (data.createCommission.errors && data.createCommission.errors.length > 0) {
      toast.error(`Error: ${data.createCommission.errors[0].message || 'something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add commission</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col sm={12} md={12}>
              <Form.Group className='mb-3'>
                <Form.Label>State </Form.Label>
                <Select
                  placeholder='Select one'
                  // value={formik.values.state}
                  name='state'
                  onChange={(e) => {
                    setStateValue(e)
                    formik.setFieldValue('state', e?.value || '')
                  }}
                  value={stateValue}
                  onBlur={() => formik.setFieldTouched('state')}
                  isDisabled={loadingStates}
                  options={
                    dataStates?.allStates.edges.map(({node}) => ({
                      label: node.name,
                      value: node.code,
                    })) || []
                  }
                  classNames={{
                    control: () => 'h-100 py-1',
                    container: () => 'h-100',
                  }}
                  styles={{
                    control: (sty) => ({
                      ...sty,
                      border:
                        formik.errors.state && formik.touched.state ? '1px solid red' : sty.border,
                    }),
                  }}
                />
                {formik.errors.state && formik.touched.state && (
                  <small className='text-danger'>{formik.errors.state}</small>
                )}
              </Form.Group>
            </Col>
            <Col sm={12} md={12}>
              <Form.Group className='mb-3'>
                <Form.Label>Policy Type</Form.Label>
                <Form.Select
                  placeholder='Policy Type'
                  value={formik.values.policyType}
                  name='policyType'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isInvalid={Boolean(formik.errors.policyType && formik.touched.policyType)}
                  disabled={loadingPolicies}
                >
                  <option value=''>Select One</option>
                  {dataPolicies?.allPoliciesType.edges.map(({node}) => (
                    <option key={node.id} value={node.id}>
                      {node.typePolicy}
                    </option>
                  ))}
                </Form.Select>
                {formik.errors.policyType && formik.touched.policyType && (
                  <small className='text-danger'>{formik.errors.policyType}</small>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className='mb-3'>
            <Form.Label>Nominal Value</Form.Label>
            <InputCurrency
              placeholder='0'
              value={formik.values.nominal}
              name='nominal'
              onValueChange={(v) => {
                formik.setFieldValue('nominal', v || '')
                formik.setFieldValue('percentage', '')
              }}
              onBlur={formik.handleBlur}
            />
            {formik.errors.nominal && formik.touched.nominal && (
              <small className='text-danger'>{formik.errors.nominal}</small>
            )}
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Percentage Value</Form.Label>
            <Form.Control
              placeholder='0'
              value={formik.values.percentage}
              type='number'
              name='percentage'
              min={0}
              max={100}
              onChange={(v) => {
                const {value} = v.target
                formik.setFieldValue('percentage', value || '')
                formik.setFieldValue('nominal', '')
              }}
              onBlur={formik.handleBlur}
            />
            {formik.errors.percentage && formik.touched.percentage && (
              <small className='text-danger'>{formik.errors.percentage}</small>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose} type='button'>
            Close
          </Button>
          <Button variant='primary' type='submit' disabled={loading}>
            {loading && <Spinner animation='border' size='sm' className='me-2' />}
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

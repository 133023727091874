import {Badge, Button, Card, Dropdown, SplitButton} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Tooltip} from 'react-tooltip'
import ReactQuill from 'react-quill'
import {useState} from 'react'
import 'react-quill/dist/quill.snow.css'

interface Item {
  id: number
  name: string
  urlImg: string
  title: string
  lastDate: string
  badge:
    | null
    | {
        text: string
        color: string
      }[]
}

interface IOneMsg {
  infoMsg: Item
  ButtonMenu: JSX.Element
  onBack: () => void
}

export const OneMessage: React.FC<IOneMsg> = ({infoMsg, ButtonMenu, onBack}) => {
  const [value, setValue] = useState('')
  return (
    <>
      <Card.Header>
        <div className='card-title justify-content-between w-100 flex-wrap'>
          <div className='d-flex align-items-center gap-2'>
            <Button variant='link' onClick={onBack}>
              <i className='p-0 bi bi-arrow-left' />
            </Button>
            <Button variant='secondary' size='sm' className='d-flex flex-center px-3 py-2'>
              <i className='p-0 bi bi-envelope fs-6' style={{color: 'inherit'}} />
            </Button>
            <Button variant='secondary' size='sm' className='d-flex flex-center px-3 py-2'>
              <i className='p-0 bi bi-patch-exclamation fs-6' style={{color: 'inherit'}} />
            </Button>
            <Button variant='secondary' size='sm' className='d-flex flex-center px-3 py-2'>
              <i className='p-0 bi bi-trash-fill fs-6' style={{color: 'inherit'}} />
            </Button>
            <Button variant='secondary' size='sm' className='d-flex flex-center px-3 py-2'>
              <i className='p-0 bi bi-file-earmark-check fs-6' style={{color: 'inherit'}} />
            </Button>
          </div>
          <div>
            <div className='d-flex align-items-center'>
              <small className='text-muted'>{infoMsg.id} - 50 of 235</small>
              <div className='d-flex algin-items-center'>
                <Button size='sm' className='ms-2 px-2 py-1 flex flex-center' variant='secondary'>
                  <i className='p-0 bi bi-chevron-left' />
                </Button>
                <Button size='sm' className='ms-2 px-2 py-1 flex flex-center' variant='secondary'>
                  <i className='p-0 bi bi-chevron-right' />
                </Button>
                {ButtonMenu}
              </div>
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Title>
          {infoMsg.title}

          {infoMsg.badge && (
            <div className='d-inline-flex ms-4 gap-3'>
              {infoMsg.badge.map((bad) => (
                <Badge bg={bad.color}>{bad.text}</Badge>
              ))}
            </div>
          )}
        </Card.Title>
        <div className='d-flex align-items-center flex-grow-1'>
          <div className='symbol symbol-45px me-5'>
            <img src={toAbsoluteUrl(infoMsg.urlImg)} alt='' />
          </div>

          <div className='d-flex flex-column'>
            <div className='d-flex align-items-center gap-2'>
              <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>{infoMsg.name}</span>
              <span>
                <i className='bi bi-clock-fill' style={{color: 'green'}} />
              </span>
              <span className='text-muted fw-bold'>1 day ago</span>
            </div>
            <span data-tooltip-id='tootlpitpes' className='text-gray-400 fw-semibold'>
              To me
            </span>
            <Tooltip
              id='tootlpitpes'
              place='bottom'
              style={{
                backgroundColor: 'white',
                color: 'black',
                boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                opacity: 1,
              }}
            >
              <table className='table mb-0'>
                <tbody>
                  <tr>
                    <td className='w-75px text-muted'>From</td>
                    <td>Emma Bold</td>
                  </tr>
                  <tr>
                    <td className='text-muted'>Date</td>
                    <td>05 May 2024, 10:10 pm</td>
                  </tr>
                  <tr>
                    <td className='text-muted'>Subject</td>
                    <td>Trip Reminder. Thank you for flying with us!</td>
                  </tr>
                  <tr>
                    <td className='text-muted'>Reply-to</td>
                    <td>emma@intenso.com</td>
                  </tr>
                </tbody>
              </table>
            </Tooltip>
          </div>
        </div>
        <div className='mt-6 d-flex align-items-center flex-wrap gap-2'>
          <span className='fw-semibold text-muted text-end me-3'>20 Dec 2024, 9:23 pm</span>
          <div className='d-flex gap-4'>
            <Button variant='secondary' size='sm' className='d-flex flex-center px-3 py-2'>
              <i className='p-0 bi bi-star fs-6' style={{color: 'inherit'}} />
            </Button>
            <Button variant='secondary' size='sm' className='d-flex flex-center px-3 py-2'>
              <i className='p-0 bi bi-bookmarks-fill fs-6' style={{color: 'inherit'}} />
            </Button>
            <Button variant='secondary' size='sm' className='d-flex flex-center px-3 py-2'>
              <i className='p-0 bi bi-pencil-square fs-6' style={{color: 'inherit'}} />
            </Button>
            <Button variant='secondary' size='sm' className='d-flex flex-center px-3 py-2'>
              <i className='p-0 bi bi-three-dots-vertical fs-6' style={{color: 'inherit'}} />
            </Button>
          </div>
        </div>

        <div className='py-5'>
          <div>
            <p>Hi Bob,</p>
            <p>
              With resrpect, i must disagree with Mr.Zinsser. We all know the most part of important
              part of any article is the title.Without a compelleing title, your reader won't even
              get to the first sentence.After the title, however, the first few sentences of your
              article are certainly the most important part.
            </p>
            <p>
              Jornalists call this critical, introductory section the "Lede," and when bridge
              properly executed, it's the that carries your reader from an headine try at
              attention-grabbing to the body of your blog post, if you want to get it right on of
              these 10 clever ways to omen your next blog posr with a bang
            </p>
            <p>Best regards,</p>
            <p className='mb-0'>Jason Muller</p>
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
          To:
          <div className='tagify form-control border-0 tagify--focus'>
            <div className='tagify__tag tagify--noAnim'>
              <div className='d-flex align-items-center'>
                <img
                  src={toAbsoluteUrl(infoMsg.urlImg)}
                  alt='img'
                  className='rounded-circle w-25px me-2'
                />
                <span className='tagify__tag-text'>{infoMsg.name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>Cc:</div>
        <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>Bcc:</div>
        <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
          <input type='text' placeholder='Subject' className='w-100 h-100' style={{border: 0}} />
        </div>
        <ReactQuill
          theme='snow'
          value={value}
          onChange={setValue}
          className='w-100'
          style={{border: 0}}
        />
        <div className='d-flex align-items-center justify-content-between mt-3'>
          <div className='d-flex align-items-center gap-4'>
            <SplitButton id={`dropdown-split-variants-Primary`} variant={'primary'} title='Send'>
              <Dropdown.Item eventKey='1'>Action</Dropdown.Item>
              <Dropdown.Item eventKey='2'>Another action</Dropdown.Item>
              <Dropdown.Item eventKey='3' active>
                Active Item
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey='4'>Separated link</Dropdown.Item>
            </SplitButton>
            <button style={{backgroundColor: 'transparent', border: 0}}>
              <span>
                <i className='bi bi-paperclip fs-2' />
              </span>
            </button>
          </div>
          <div>
            <button style={{backgroundColor: 'transparent', border: 0}}>
              <i className='bi bi-trash fs-2' />
            </button>
          </div>
        </div>
      </Card.Footer>
    </>
  )
}

import {Dropdown} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {TTabsOneUser} from './oneUserTypes'
import {OverviewTab} from './components/OverviewTab'
import {SettingsTab} from './components/SettingsTab'
import {StatementsTab} from './components/StatementsTab'
import {useParams} from 'react-router-dom'
import {useLazyQuery} from '@apollo/client'
import {toast} from 'react-toastify'
import {GET_ONE_MEMBER_USER} from '../../gql/queries/membersQuery'
import {IResAllMembers} from '../../types/members'
import {LoadingOneUserPage} from './components/LoadingOneUserPage'
import {parseId} from '../../helpers'
import {InfoBalance} from './components/InfoBalance'

export const OneUserPage = () => {
  const [gqlGetUser, {data, loading, error, refetch}] = useLazyQuery<IResAllMembers>(
    GET_ONE_MEMBER_USER,
    {
      fetchPolicy: 'no-cache',
    }
  )
  const {id} = useParams()

  const [flagBalance, setFlagBalance] = useState(0)

  useEffect(() => {
    if (Number(id)) {
      gqlGetUser({
        variables: {
          id,
        },
      })
    }
  }, [gqlGetUser, id])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  const [tabs, setTabs] = useState<TTabsOneUser>('Overview')

  const refetchBalance = () => {
    setFlagBalance((p) => p + 1)
  }

  if (loading || !data) {
    return <LoadingOneUserPage />
  }

  if (data.allUsers.edges.length === 0) {
    return <div>Not found</div>
  }

  const node = data.allUsers.edges[0].node

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/tfi/logo-icoN.png')} alt={node.firstName} />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='/#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {node.firstName} {node.lastName}
                    </a>
                    <a href='/#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='/#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                      Developer
                    </a>
                    <a
                      href='/#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      SF, Bay Area
                    </a>
                    <a
                      href='/#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {node.email || 'N/R'}
                    </a>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <button className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTIcon iconName='check' className='fs-3 d-none' />

                    <span className='indicator-label'>Follow</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                  <button className='btn btn-sm btn-primary me-3'>Hire Me</button>
                  <div className='me-0'>
                    <Dropdown>
                      <Dropdown.Toggle size='sm' variant='success' id='dropdown-basic'>
                        <i className='bi bi-three-dots fs-3'></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                        <Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
                        <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <InfoBalance id={`${parseId(node.id)}`} flagBalance={flagBalance} />

                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                        <div className='fs-2 fw-bolder'>75</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>60%</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                    </div> */}
                  </div>
                </div>

                {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                    <span className='fw-bolder fs-6'>50%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{width: '50%'}}
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {(['Overview', 'Edit user', 'Transactions'] as const).map((tb) => (
                <li className='nav-item'>
                  <button
                    className={`nav-link text-active-primary me-6 ${tb === tabs ? 'active' : ''}`}
                    onClick={() => setTabs(tb)}
                  >
                    {tb}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {tabs === 'Overview' && <OverviewTab node={node} />}
      {tabs === 'Edit user' && <SettingsTab node={node} refetch={refetch} />}
      {tabs === 'Transactions' && <StatementsTab refetchBalance={refetchBalance} />}
    </div>
  )
}

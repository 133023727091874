import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const NotFoundPage = () => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center w-100'>
      <div className='card card-flush  w-100 '>
        <div className='card-body  d-flex flex-column justify-content-center align-items-center'>
          {/* begin::Title */}
          <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='fw-semibold fs-6 text-gray-500 mb-7'>We can't find that page.</div>
          {/* end::Text */}

          {/* begin::Illustration */}
          <div className='mb-3'>
            <img
              src={toAbsoluteUrl('/media/auth/404-error.png')}
              className='mw-100 mh-300px theme-light-show'
              alt=''
            />
            <img
              src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
              className='mw-100 mh-300px theme-dark-show'
              alt=''
            />
          </div>
          {/* end::Illustration */}

          {/* begin::Link */}
          <div className='mb-0'>
            <Link to='/' className='btn btn-sm btn-primary'>
              Return Home
            </Link>
          </div>
          {/* end::Link */}
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage

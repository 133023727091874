import {Card, Col, FloatingLabel, Form, Row} from 'react-bootstrap'
import {CommercialLead, PersonalLeadOneLead} from '../../leads/leadTypes'
import {useState} from 'react'

interface ICardInfoProps {
  leadId: string
  commercial: CommercialLead | null
  personal: PersonalLeadOneLead | null
}

export const CardInfoPersonalCommercial: React.FC<ICardInfoProps> = ({commercial, personal}) => {
  const [typeLead, setTypeLead] = useState<'commercial' | 'personal'>(() => {
    const t = commercial && personal ? 'commercial' : commercial ? 'commercial' : 'personal'
    return t
  })
  return (
    <Card>
      <Card.Header className='align-items-center'>
        <Card.Title>Lead type information</Card.Title>
        <div>
          <div
            style={{
              borderBottom: 0,
              display: 'flex',
              background: '#f6f7fb',
              padding: '6px',
              borderRadius: '60px',
            }}
            className='d-flex align-items-center'
          >
            <button
              style={{
                flex: 1,
                borderRadius: '20px',
                color: typeLead === 'personal' ? 'white' : undefined,
              }}
              className={`border-0 ${typeLead === 'personal' ? 'bg-primary' : 'bg-transparent'}`}
              onClick={() => {
                setTypeLead('personal')
              }}
            >
              Personal
            </button>
            <button
              style={{
                flex: 1,
                borderRadius: '20px',
                color: typeLead === 'commercial' ? 'white' : undefined,
              }}
              className={`border-0 ${typeLead === 'commercial' ? 'bg-primary' : 'bg-transparent'}`}
              onClick={() => {
                setTypeLead('commercial')
              }}
            >
              Commercial
            </button>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          {typeLead === 'commercial' && (
            <>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='dba' label='DBA'>
                  <Form.Control
                    name='dba'
                    type='text'
                    placeholder='DBA'
                    defaultValue={commercial?.dba || 'N/R'}
                    style={{color: commercial?.dba ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='businessEntity' label='Business Entity'>
                  <Form.Control
                    name='businessEntity'
                    type='text'
                    placeholder='Business Entity'
                    defaultValue={commercial?.businessEntity || 'N/R'}
                    style={{color: commercial?.businessEntity ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              {/* Grupo 2 */}
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='businessClassification' label='Business Classification'>
                  <Form.Control
                    name='businessClassification'
                    type='text'
                    placeholder='Business Classification'
                    defaultValue={commercial?.businessClassification || 'N/R'}
                    style={{color: commercial?.businessClassification ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='federalEin' label='Federal EIN'>
                  <Form.Control
                    name='federalEin'
                    type='text'
                    placeholder='Federal EIN'
                    defaultValue={commercial?.federalEin || 'N/R'}
                    style={{color: commercial?.federalEin ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              {/* Grupo 3 */}
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='businessStarted' label='Business Started'>
                  <Form.Control
                    name='businessStarted'
                    type='text'
                    placeholder='Business Started'
                    defaultValue={commercial?.businessStarted || 'N/R'}
                    style={{color: commercial?.businessStarted ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='numberEmployees' label='Number of Employees'>
                  <Form.Control
                    name='numberEmployees'
                    type={commercial?.numberEmployees ? 'number' : 'text'}
                    placeholder='Number of Employees'
                    defaultValue={commercial?.numberEmployees || 'N/R'}
                    style={{color: commercial?.numberEmployees ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              {/* Grupo 4 */}
              {/* <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='annualRevenue' label='Annual Revenue'>
                  <Form.Control
                    name='annualRevenue'
                    type='text'
                    placeholder='Annual Revenue'
                    defaultValue={'N/R'}
                    readOnly
                  />
                </FloatingLabel>
              </Col> */}
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='totalPayroll' label='Total Payroll'>
                  <Form.Control
                    name='totalPayroll'
                    type='text'
                    placeholder='Total Payroll'
                    defaultValue={commercial?.totalPayroll || 'N/R'}
                    style={{color: commercial?.totalPayroll ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              {/* Grupo 5 */}
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='secondPhone' label='Second Phone Comercial'>
                  <Form.Control
                    name='secondPhone'
                    type='text'
                    placeholder='Second Phone Comercial'
                    defaultValue={commercial?.secondPhone || 'N/R'}
                    style={{color: commercial?.secondPhone ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='secondEmail' label='Second Email Comercial'>
                  <Form.Control
                    name='secondEmail'
                    type='email'
                    placeholder='Second Email Comercial'
                    defaultValue={commercial?.secondEmail || 'N/R'}
                    style={{color: commercial?.secondEmail ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              {/* Grupo 6 */}
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='contactFirstname' label='Contact First Name'>
                  <Form.Control
                    name='contactFirstname'
                    type='text'
                    placeholder='Contact First Name'
                    defaultValue={commercial?.contactFirstname || 'N/R'}
                    style={{color: commercial?.contactFirstname ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='contactMiddlename' label='Contact Middle Name'>
                  <Form.Control
                    name='contactMiddlename'
                    type='text'
                    placeholder='Contact Middle Name'
                    defaultValue={commercial?.contactMiddlename || 'N/R'}
                    style={{color: commercial?.contactMiddlename ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              {/* Grupo 7 */}
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='contactLastname' label='Contact Last Name'>
                  <Form.Control
                    name='contactLastname'
                    type='text'
                    placeholder='Contact Last Name'
                    defaultValue={commercial?.contactLastname || 'N/R'}
                    style={{color: commercial?.contactLastname ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </>
          )}
          {typeLead === 'personal' && (
            <>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='birthDay' label='Date of Birth'>
                  <Form.Control
                    name='birthDay'
                    type='date'
                    placeholder='Date of Birth'
                    defaultValue={personal?.birthDay || undefined}
                    style={{color: personal?.birthDay ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='civilStatus' label='Civil Status'>
                  <Form.Control
                    name='civilStatus'
                    type='text'
                    placeholder='Civil Status'
                    defaultValue={personal?.civilStatus || 'N/R'}
                    readOnly
                    style={{color: personal?.civilStatus ? undefined : '#d6d6d6'}}
                  />
                </FloatingLabel>
              </Col>

              {/* Grupo 9 */}
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='secondPhone' label='Second Phone'>
                  <Form.Control
                    type='text'
                    name='secondPhone'
                    placeholder='Second Phone'
                    defaultValue={personal?.secondPhone || 'N/R'}
                    readOnly
                    style={{color: personal?.secondPhone ? undefined : '#d6d6d6'}}
                  />
                </FloatingLabel>
              </Col>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='streetAddress' label='Street Address'>
                  <Form.Control
                    name='streetAddress'
                    type='text'
                    placeholder='Street Address'
                    defaultValue={personal?.streetAddress || 'N/R'}
                    style={{color: personal?.streetAddress ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              {/* Grupo 10 */}
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='city' label='City'>
                  <Form.Control
                    name='city'
                    type='text'
                    placeholder='City'
                    defaultValue={personal?.city || 'N/R'}
                    readOnly
                    style={{color: personal?.city ? undefined : '#d6d6d6'}}
                  />
                </FloatingLabel>
              </Col>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='country' label='Country'>
                  <Form.Control
                    name='country'
                    type='text'
                    placeholder='Country'
                    defaultValue={personal?.country || 'N/R'}
                    style={{color: personal?.country ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              {/* Grupo 11 */}
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='state' label='State'>
                  <Form.Control
                    type='text'
                    name='state'
                    placeholder='State'
                    defaultValue={personal?.state || 'N/R'}
                    style={{color: personal?.state ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className='col-lg-6 col-md-6 col-12 mb-4'>
                <FloatingLabel controlId='postalCode' label='Postal Code'>
                  <Form.Control
                    name='postalCode'
                    type='text'
                    placeholder='Postal Code'
                    defaultValue={personal?.postalCode || 'N/R'}
                    style={{color: personal?.postalCode ? undefined : '#d6d6d6'}}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  )
}

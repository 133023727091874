import {Fragment, useState} from 'react'
import {Tooltip} from 'react-tooltip'
import {IEdgesStagePipe} from '../../leads/leadTypes'
import {ModalChangeStateWizard} from './ModalChangeStateWizard'
import {useDisclourse} from '../../../hooks/useDisclourse'
import {hasPermission} from '../../../helpers'
import {ALL_PERMISSIONS} from '../../../helpers/consts'
import {useGetUser} from '../../../store/userStore'

export const StagesWizard: React.FC<{
  stages: IEdgesStagePipe[]
  stagePipelineId: string
  refetch: () => void
  leadId: string
}> = ({stages, stagePipelineId, refetch, leadId}) => {
  const permissions = useGetUser((s) => s.user?.permissions)
  const [info, setInfo] = useState<{id: string; name: string} | null>(null)
  const {isOpen, onClose, onOpen} = useDisclourse()
  return (
    <>
      <div className='w-100' style={{overflowX: 'auto'}}>
        <div className='d-flex algin-items-center justify-content-start w-100'>
          {/* <div style={{position: 'absolute', marginLeft: ' 20px'}}>
          <p className='text-primary'>Pipelines</p>
          <p>Stages</p>
        </div> */}
          <div className='wizard-form py-4 my-2 w-100' style={{minWidth: '600px'}}>
            <ul
              id='progressBar'
              className='progressbar px-lg-5 px-0 w-100 d-flex align-items-center justify-content-center'
            >
              {stages
                ?.sort((a, b) => a.node.order - b.node.order)
                .map(({node}, i) => {
                  const idx = stages.findIndex(
                    ({node: nodeInside}) => nodeInside.id === stagePipelineId
                  )
                  const isActive = idx >= i
                  return (
                    <Fragment key={node.id}>
                      <li
                        role='button'
                        data-tooltip-id={`my-tooltip-1-${i}`}
                        data-tooltip-content={node.name}
                        onClick={() => {
                          if (node.id === stagePipelineId) return
                          const hasP =
                            permissions &&
                            hasPermission(permissions, ALL_PERMISSIONS.PUEDE_EDITAR_LEADS)
                          if (!hasP) return
                          onOpen()
                          setInfo({id: node.id, name: node.name})
                        }}
                        id='progressList-1'
                        className={`d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list ${
                          isActive ? 'active' : ''
                        }`}
                      >
                        <span
                          className='info-idx'
                          style={{
                            boxShadow: !isActive
                              ? '1px 1px 3px #606060'
                              : '0 0 0 7.5px rgb(62 151 255 / 11%)',
                            backgroundColor: isActive ? '#3e97ff' : '#f1f1f1',
                            color: isActive ? '#fff' : '#111',
                          }}
                        >
                          {i + 1}
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            maxWidth: '12ch',
                            textOverflow: 'ellipsis',
                            overflowX: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {node.name}
                        </span>
                      </li>
                      <Tooltip
                        id={`my-tooltip-1-${i}`}
                        place='bottom'
                        className=''
                        style={{zIndex: 999999}}
                      />
                    </Fragment>
                  )
                })}
              {/* <li
              id='progressList-1'
              className='d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list active'
            >
              <span
                className='info-idx'
                style={{
                  boxShadow: '1px 1px 3px #606060' || '0 0 0 7.5px rgb(176 60 70 / 11%)',
                  backgroundColor: '#f1f1f1' || '#dc3545',
                }}
              >
                1
              </span>
              Paso 1
            </li>
            <li
              id='progressList-2'
              className='d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list active'
            >
              <span className='info-idx'>2</span>
              Paso 2
            </li>
            <li
              id='progressList-3'
              className='d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list'
            >
              <span className='info-idx'>3</span>
              Paso 3
            </li>
            <li
              id='progressList-4'
              className='d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list'
            >
              <span className='info-idx'>4</span>
              Comencemos
            </li> */}
            </ul>
          </div>
        </div>
      </div>

      {info && (
        <ModalChangeStateWizard
          leadId={leadId}
          info={info}
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
        />
      )}
    </>
  )
}

import {Col, Form, Row, Spinner} from 'react-bootstrap'
import {GET_ALL_PIPELINES} from '../../gql/queries/pipelines'
import {IResPipelines} from '../../types/pipelines'
import {useQuery} from '@apollo/client'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import DatePicker from 'react-multi-date-picker'
import {isMobileDevice} from '../../../../_metronic/assets/ts/_utils'
import {ConversionPercen} from './components/ConversionPercen'
import {SourcePecentage} from './components/SourcePecentage'
import {LossReasonPercent} from './components/LossReasonPercent'
import {TagPercent} from './components/TagPercent'
import {UserMetrics} from './components/UserMetrics'

export const HoomeReportPage = () => {
  const {
    data: dataPipelines,
    loading: loadingPipelines,
    error: errorPipelines,
  } = useQuery<IResPipelines>(GET_ALL_PIPELINES, {fetchPolicy: 'no-cache'})
  const isMobile = isMobileDevice()

  const [pipelineId, setPipelineId] = useState('')

  const [dates, setDates] = useState([
    new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)),
    new Date(),
  ])

  useEffect(() => {
    if (!dataPipelines) return
    if (dataPipelines.allPipelines.edges.length > 0) {
      setPipelineId(dataPipelines.allPipelines.edges[0].node.id)
    }
  }, [dataPipelines])

  useEffect(() => {
    if (errorPipelines) toast.error(`Error: ${errorPipelines.message}`)
  }, [errorPipelines])

  return (
    <div>
      <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'>
        <h1 className='d-flex align-items-center'>
          REPORTS
          {loadingPipelines && <Spinner animation='border' size='sm' className='ms-1' />}
        </h1>
        <div className='d-flex flex-column flex-sm-row align-items-start gap-3 w-100 w-md-auto'>
          <DatePicker
            style={{
              padding: '1.55em 1em',
              textAlign: 'center',
              flex: '1',
              width: '100%',
              minWidth: '215px',
            }}
            containerStyle={{width: '100%'}}
            value={dates}
            onChange={(d) => {
              if (d.length <= 1) return
              const [d1, d2] = d
              const date1 = d1.toDate()
              const date2 = d2.toDate()
              setDates([date1, date2])
            }}
            range
            numberOfMonths={isMobile ? 1 : 2}
            format='DD-MMM-YYYY'
          />
          <Form.Select
            className='d-flex align-items-center'
            aria-label='Default select example'
            value={pipelineId}
            onChange={(e) => {
              const {value} = e.target
              if (value) {
                setPipelineId(value)
              }
            }}
            disabled={loadingPipelines}
          >
            <option value=''>Select Pipeline</option>
            {dataPipelines?.allPipelines.edges.map(({node}) => (
              <option key={node.id} value={node.id}>
                {node.name}
              </option>
            )) || []}
          </Form.Select>
        </div>
      </div>

      <div>
        <ConversionPercen dates={dates} pipelineId={pipelineId} />
      </div>
      <div className='mt-5 container'>
        <div className='card'>
          <div className='card-body'>
            <Row>
              <Col sm={12} md={6}>
                <SourcePecentage dates={dates} pipelineId={pipelineId} />
              </Col>
              <Col sm={12} md={6}>
                <LossReasonPercent dates={dates} pipelineId={pipelineId} />
              </Col>
              <Col className='mt-4'>
                <div className='d-flex flex-center'>
                  <TagPercent dates={dates} pipelineId={pipelineId} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className='my-5 container'>
        <div className='card'>
          <div className='card-body'>
            <UserMetrics dates={dates} pipelineId={pipelineId} />
          </div>
        </div>
      </div>
    </div>
  )
}

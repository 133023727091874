import {useMutation} from '@apollo/client'
import {FormEvent, useEffect} from 'react'
import {Button, Form, Modal, Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {ADD_MEMBER_ID_POLICY} from '../../../gql/mutations/policyMutation'
import {IResAddMemberIDPolicy} from '../soldTypes'

interface IModalProps {
  id: string
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  memberId: string
}
export const ModalMemberIdPolicy: React.FC<IModalProps> = ({
  id,
  isOpen,
  onClose,
  refetch,
  memberId,
}) => {
  const [gqlAdd, {data, loading, error}] = useMutation<IResAddMemberIDPolicy>(ADD_MEMBER_ID_POLICY)
  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return

    if (data && data.assignMemberIdToPolicy.success) {
      toast.success('Member ID added successfully')
      refetch()
      onClose()
    } else if (
      data.assignMemberIdToPolicy.errors &&
      data.assignMemberIdToPolicy.errors.length > 0
    ) {
      toast.error(`Error ${data.assignMemberIdToPolicy.errors[0].message}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const {memberId} = Object.fromEntries(new FormData(e.currentTarget))

    gqlAdd({
      variables: {
        id,
        memberId,
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{memberId ? 'Edit' : 'Add'} Member ID</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id='formId' onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>Memeber ID</Form.Label>
            <Form.Control
              defaultValue={memberId || ''}
              name='memberId'
              id='memberId'
              placeholder='Insert Member ID'
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='primary'
          type='submit'
          form='formId'
          formAction='formId'
          disabled={loading}
        >
          {loading && <Spinner animation='border' size='sm' className='me-2' />}
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import {useLazyQuery} from '@apollo/client'
import {Col, Row} from 'react-bootstrap'
import {CONVERSION_SALES, DECLINE_SALES} from '../../../gql/queries/reportsQuery'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {parseId} from '../../../helpers'

interface ConversionProps {
  dates: Date[]
  pipelineId: string
}

export const ConversionPercen: React.FC<ConversionProps> = ({dates, pipelineId}) => {
  const [
    gqlGetConversionSales,
    {data: dataConvSales, loading: loadingConvSales, error: errorConvSales},
  ] = useLazyQuery<{leadsByConversionPercentage: number}>(CONVERSION_SALES, {
    fetchPolicy: 'no-cache',
  })

  const [
    gqlGetDeclineSales,
    {data: dataDeclineSales, loading: loadingDeclineSales, error: errorDeclineSales},
  ] = useLazyQuery<{leadsByConversionPercentage: number}>(DECLINE_SALES, {fetchPolicy: 'no-cache'})

  useEffect(() => {
    if (!pipelineId || !dates || dates.length <= 1) return
    const variables = {
      pipelineId: parseId(pipelineId),
      startDate: dates[0].toISOString().split('T')[0],
      endDate: dates[1].toISOString().split('T')[0],
    }
    gqlGetConversionSales({
      variables: {...variables, stageName: 'Sold'},
    })
    gqlGetDeclineSales({
      variables: {...variables, stageName: 'Declined'},
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, pipelineId])

  useEffect(() => {
    if (errorConvSales) toast.error(`Error: ${errorConvSales.message}`)
    if (errorDeclineSales) toast.error(`Error: ${errorDeclineSales.message}`)
  }, [errorConvSales, errorDeclineSales])

  if (loadingConvSales || loadingDeclineSales || !dataConvSales || !dataDeclineSales) {
    return (
      <div className='container mt-5 pt-5'>
        <Row>
          <Col sm={12} md={6}>
            <div className='card card-animate'>
              <div className='card-body'>
                <div className='d-flex align-items-center'>
                  <div className='flex-grow-1 overflow-hidden'>
                    <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                      Sales Conversion Rate
                    </p>
                  </div>
                </div>
                <div className='d-flex align-items-end justify-content-between mt-4'>
                  <div>
                    <h4 className='fs-22 fw-semibold ff-secondary mb-4' style={{width: '180px'}}>
                      <p className='card-text placeholder-glow'>
                        <span className='placeholder col-7'></span>
                      </p>
                    </h4>
                  </div>
                  <div className='flex-shrink-0' style={{height: '3rem', width: '3rem'}}>
                    <span className='avatar-title bg-success-subtle rounded fs-3'>
                      <i className='bi bi-check2-circle fs-1 text-success'></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className='card card-animate'>
              <div className='card-body'>
                <div className='d-flex align-items-center'>
                  <div className='flex-grow-1 overflow-hidden'>
                    <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                      Rate of Unclosed or Declined deals
                    </p>
                  </div>
                </div>
                <div className='d-flex align-items-end justify-content-between mt-4'>
                  <div>
                    <h4 className='fs-22 fw-semibold ff-secondary mb-4' style={{width: '180px'}}>
                      <p className='card-text placeholder-glow'>
                        <span className='placeholder col-7'></span>
                      </p>
                    </h4>
                  </div>
                  <div className='flex-shrink-0' style={{height: '3rem', width: '3rem'}}>
                    <span className='avatar-title bg-danger-subtle rounded fs-3'>
                      <i className='bi bi-slash-circle fs-1 text-danger'></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className='container mt-5 pt-5'>
      <Row>
        <Col sm={12} md={6}>
          <div className='card card-animate'>
            <div className='card-body'>
              <div className='d-flex align-items-center'>
                <div className='flex-grow-1 overflow-hidden'>
                  <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                    Sales Conversion Rate
                  </p>
                </div>
              </div>
              <div className='d-flex align-items-end justify-content-between mt-4'>
                <div>
                  <h4 className='fs-1 fw-semibold ff-secondary mb-4'>
                    <span className='counter-value' data-target='36894'>
                      {Number(dataConvSales.leadsByConversionPercentage.toFixed(2))}%
                    </span>
                  </h4>
                </div>
                <div className='flex-shrink-0' style={{height: '3rem', width: '3rem'}}>
                  <span className='avatar-title bg-success-subtle rounded fs-3'>
                    <i className='bi bi-check2-circle fs-1 text-success'></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className='card card-animate'>
            <div className='card-body'>
              <div className='d-flex align-items-center'>
                <div className='flex-grow-1 overflow-hidden'>
                  <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                    Rate of Unclosed or Declined deals
                  </p>
                </div>
              </div>
              <div className='d-flex align-items-end justify-content-between mt-4'>
                <div>
                  <h4 className='fs-1 fw-semibold ff-secondary mb-4'>
                    <span className='counter-value' data-target='36894'>
                      {Number(dataDeclineSales.leadsByConversionPercentage.toFixed(2))}%
                    </span>
                  </h4>
                </div>
                <div className='flex-shrink-0' style={{height: '3rem', width: '3rem'}}>
                  <span className='avatar-title bg-danger-subtle rounded fs-3'>
                    <i className='bi bi-slash-circle fs-1 text-danger'></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

import CurrencyInput, {CurrencyInputProps} from 'react-currency-input-field'

export const InputCurrency: React.FC<CurrencyInputProps> = ({className, intlConfig, ...props}) => {
  const intlConfigInside = intlConfig || {locale: 'en-US', currency: 'USD'}
  return (
    <CurrencyInput
      className={`form-control ${className}`}
      intlConfig={intlConfigInside}
      {...props}
    />
  )
}

import * as Yup from 'yup'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useMutation} from '@apollo/client'
import {IResLogin} from '../../types/auth'
import {LOGIN} from '../../gql/mutations/authMutations'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useGetUser} from '../../store/userStore'
import {STORAGE_NAMES, VERION_APP} from '../../helpers/consts'
import {useLocation, useNavigate} from 'react-router-dom'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

export const LoginPage = () => {
  const [gqlLogin, {data, loading, error}] = useMutation<IResLogin>(LOGIN)
  const login = useGetUser((s) => s.login)
  const navigate = useNavigate()
  const location = useLocation()

  const [showPass, setShowPass] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values, {setStatus}) => {
      setStatus(null)
      gqlLogin({
        variables: {
          ...values,
        },
      })
    },
  })

  useEffect(() => {
    const $div = document.querySelector('#rc-widget') as HTMLDivElement
    if (!$div) return
    const str = localStorage.getItem(STORAGE_NAMES.RING_CENTRAL)
    if (str) {
      $div.style.opacity = '0'
      $div.style.pointerEvents = 'none'
    }

    return () => {
      if ($div) {
        $div.style.opacity = '1'
        $div.style.pointerEvents = 'initial'
      }
    }
  }, [])

  useEffect(() => {
    if (error) {
      toast.error('Error ' + error.message)
      formik.setStatus('Error ' + error.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.tokenAuth) {
      login(data)
      const url = (location?.state as {prevUrl: string})?.prevUrl || '/'
      navigate(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              {/* begin::Heading */}
              <div className='text-center mb-11'>
                <img src='/media/tfi/logoNColorP.png' width={200} alt='logo' />
                <h1 className='text-dark fw-bolder mb-3'>WELCOME</h1>
                <div className='text-gray-500 fw-semibold fs-6'>Sign In</div>
              </div>
              {/* begin::Heading */}

              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              {/* begin::Form group */}
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.email}
                    </span>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='fv-row mb-3 position-relative'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <input
                  type={showPass ? 'text' : 'password'}
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <button
                  type='button'
                  onClick={() => setShowPass((p) => !p)}
                  style={{
                    color: '#111',
                    border: 0,
                    backgroundColor: 'transparent',
                    margin: 0,
                    padding: 0,
                    position: 'absolute',
                    right:
                      formik.touched.password && formik.errors.password
                        ? '36px'
                        : formik.touched.password
                        ? '36px'
                        : '10px',
                    bottom: formik.touched.password && formik.errors.password ? '35px' : '10px',
                  }}
                >
                  {showPass ? (
                    <i className='bi bi-eye-slash fs-4' style={{color: 'inherit'}} />
                  ) : (
                    <i className='bi bi-eye fs-4' style={{color: 'inherit'}} />
                  )}
                </button>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Wrapper */}
              {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div />

                <Link to='/auth/forgot-password' className='link-primary'>
                  Forgot Password ?
                </Link>
              </div> */}
              {/* end::Wrapper */}

              {/* begin::Action */}
              <div className='d-grid mb-10'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary mt-4'
                  // style={{backgroundColor: '#181836', color: 'white'}}
                  disabled={formik.isSubmitting || !formik.isValid || loading}
                >
                  {!loading && <span className='indicator-label'>Continue</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Action */}

              {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
                Not a Member yet?{' '}
                <Link to='/auth/registration' className='link-primary'>
                  Sign up
                </Link>
              </div> */}
            </form>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          <div>
            <span>{VERION_APP}</span>
          </div>
          {/* begin::Links */}
          {/* <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div> */}
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <span className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/tfi/logoN.png')} className='h-75px' />
          </span>
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='mx-auto w-275px w-md-60 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/tfi/login.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Fast, Efficient and Productive
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-white fs-base text-center'>
            In this kind of post,{' '}
            <span className='opacity-75-hover text-warning fw-bold me-1'>the blogger</span>
            introduces a person they’ve interviewed <br /> and provides some background information
            about
            <span className='opacity-75-hover text-warning fw-bold me-1'>the interviewee</span>
            and their <br /> work following this is a transcript of the interview.
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

import {gql} from '@apollo/client'

export const GET_CAMPUS_FILTER = gql`
  query allCampus($offset: Int, $id: Float) {
    allCampus(offset: $offset, id: $id) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

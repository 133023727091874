import {useLazyQuery} from '@apollo/client'
import {KTIcon} from '../../../../../_metronic/helpers'
import {IResWallets} from '../../../types/wallets'
import {GET_ALL_WALLETS_BALANCE} from '../../../gql/queries/walletsQuery'
import {parsePrice} from '../../../helpers'
import {useEffect} from 'react'

export const InfoBalance = ({id, flagBalance}: {id: string; flagBalance: number}) => {
  const [gqlGetWallets, {data, loading, refetch}] = useLazyQuery<IResWallets>(
    GET_ALL_WALLETS_BALANCE,
    {
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    if (!id || !Number(id)) return
    gqlGetWallets({
      variables: {
        userId: id,
      },
    })
  }, [gqlGetWallets, id])

  useEffect(() => {
    if (flagBalance > 0) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagBalance])

  if (!data || loading) {
    return (
      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
        <div className='d-flex align-items-center'>
          <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
          <div className='fs-2 fw-bolder placeholder-glow' style={{width: '120px'}}>
            <span className='placeholder col-12'></span>
          </div>
        </div>

        <div className='fw-bold fs-6 text-gray-400'>Balance</div>
      </div>
    )
  }

  const balance = data.allWallet?.edges?.[0]?.node?.balance

  if (!balance) {
    return (
      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
        <div className='d-flex align-items-center'>
          <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
          <div className='fs-2 fw-bolder'>N/R</div>
        </div>

        <div className='fw-bold fs-6 text-gray-400'>Balance</div>
      </div>
    )
  }

  return (
    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
      <div className='d-flex align-items-center'>
        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
        <div className='fs-2 fw-bolder'>{parsePrice(balance)}</div>
      </div>

      <div className='fw-bold fs-6 text-gray-400'>Balance</div>
    </div>
  )
}

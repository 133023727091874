import {useMutation, useQuery} from '@apollo/client'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Button, Col, Form, InputGroup, Row, Spinner} from 'react-bootstrap'
import {useSearchParams} from 'react-router-dom'
import {Tooltip} from 'react-tooltip'
import {IResStates} from '../../../../types/statesEU'
import {GET_STATES_EU} from '../../../../gql/queries/statesEUQuery'
import {toast} from 'react-toastify'
import {parseId} from '../../../../helpers'
import {ADD_PAYMENT_POLICY} from '../../../../gql/mutations/membersPolicyMutations'
import {IResAddPaymentPolicy} from '../../../../types/membersPolicy'
import {IPaymentNode} from '../../../../types/policiesApi'

const MONTHS = [
  {val: 1, label: 'Enero'},
  {val: 2, label: 'Febrero'},
  {val: 3, label: 'Marzo'},
  {val: 4, label: 'Abril'},
  {val: 5, label: 'Mayo'},
  {val: 6, label: 'Junio'},
  {val: 7, label: 'Julio'},
  {val: 8, label: 'Agosto'},
  {val: 9, label: 'Septiembre'},
  {val: 10, label: 'Octubre'},
  {val: 11, label: 'Noviembre'},
  {val: 12, label: 'Diciembre'},
]

const INIT_FORM = {
  recurrentPayment: '',
  typeCard: '',
  cardCompany: '',
  cardNumber: '',
  expirationMonth: '',
  expirationDay: '',
  cvvCsv: '',
  firstPaymentDate: '',
  bankAccount: '',
  bankName: '',
  ABARouting: '',
  accountNumber: '',
  preferredPayment: '',
  billingAddress: '',
  paymentHolder: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  policy: '',
  id: '',
}

interface IInsurancePayment {
  onPrevStep: () => void
  onNextStep: () => void
  refetch: () => void
  node: IPaymentNode | null
}

export const InsurancePaymentsTab: React.FC<IInsurancePayment> = ({
  onPrevStep,
  onNextStep,
  node,
  refetch,
}) => {
  const [gqlAddPayment, {data, loading, error}] =
    useMutation<IResAddPaymentPolicy>(ADD_PAYMENT_POLICY)

  const [searchParams] = useSearchParams()
  const policy = searchParams.get('policy')

  const {data: dataStates, error: errorStates} = useQuery<IResStates>(GET_STATES_EU)

  const formik = useFormik({
    initialValues: INIT_FORM,
    onSubmit: (v) => {
      const input = {
        typeCard: v.typeCard || '',
        cardCompany: v.cardCompany || '',
        cardNumber: v.cardNumber || '',
        expirationMonth: v.expirationMonth || '',
        expirationDay: v.expirationDay || '',
        cvvCsv: v.cvvCsv || '',
        firstPaymentDate: v.firstPaymentDate || '',
        bankName: v.bankName || '',
        ABARouting: v.ABARouting || '',
        accountNumber: v.accountNumber || '',
        preferredPayment: v.preferredPayment || '',
        billingAddress: v.billingAddress || '',
        paymentHolder: v.paymentHolder || '',
        address1: v.address1 || '',
        address2: v.address2 || '',
        city: v.city || '',
        zipCode: v.zipCode || '',
        policy,
        bankAccount: Boolean(v.bankAccount),
        recurrentPayment:
          v.recurrentPayment === 'yes' ? true : v.recurrentPayment === 'no' ? false : undefined,
        state: v.state ? parseId(v.state) : undefined,
        id: node && node.id ? parseId(node.id) : undefined,
      }
      gqlAddPayment({
        variables: {input},
      })
    },
  })

  const [showCardNum, setShowCardNum] = useState(false)
  const [showCvvCsv, setShowCvvCsv] = useState(false)
  const [showABA, setShowABA] = useState(false)
  const [showAccountNum, setShowAccountNum] = useState(false)

  useEffect(() => {
    if (errorStates) toast.error(`Error: ${errorStates.message}`)
  }, [errorStates])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!node || !node?.id) return
    formik.setValues({
      cardCompany: node.cardCompany || '',
      cardNumber: node.cardNumber || '',
      expirationMonth: node.expirationMonth || '',
      expirationDay: node.expirationDay || '',
      cvvCsv: node.cvvCsv || '',
      firstPaymentDate: node.firstPaymentDate || '',
      bankAccount: node.bankAccount || '',
      bankName: node.bankName || '',
      ABARouting: node.ABARouting || '',
      accountNumber: node.accountNumber || '',
      preferredPayment: node.preferredPayment || '',
      billingAddress: node.billingAddress || '',
      paymentHolder: node.paymentHolder || '',
      address1: node.address1 || '',
      address2: node.address2 || '',
      city: node.city || '',
      state: node.state || '',
      zipCode: node.zipCode || '',
      id: node.id || '',
      recurrentPayment: node.recurrentPayment ? 'yes' : '',
      policy: policy || '',
      typeCard:
        node.typeCard === 'CREDIT_CARD'
          ? 'CREDIT CARD'
          : node.typeCard === 'DEBIT_CARD'
          ? 'DEBIT CARD'
          : '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])

  useEffect(() => {
    if (!data) return
    if (data.paymentCreateupdateMutation?.payment?.id) {
      toast.success(`Payment ${node && node.id ? 'updated' : 'added'} successfully`)
      refetch()
    } else if (
      data.paymentCreateupdateMutation?.errors &&
      data.paymentCreateupdateMutation?.errors?.length > 0
    ) {
      toast.error(
        `Error: ${data.paymentCreateupdateMutation?.errors[0].messages?.[0] || 'Something wrong!'}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const toggleCardNum = () => setShowCardNum((p) => !p)
  const toggleCvvCsv = () => setShowCvvCsv((p) => !p)
  const toggleABA = () => setShowABA((p) => !p)
  const toggleAccountNum = () => setShowAccountNum((p) => !p)

  return (
    <div>
      <div className='kt-heading kt-heading--md custom-border-brand mt-5'>Payment information</div>
      <Row>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label style={{fontSize: '12px'}}>Recurrent payment?</Form.Label>
            <div className='d-flex gap-5 mt-3'>
              <Form.Check
                type='radio'
                name='recurrentPayment'
                label='Yes'
                value='yes'
                onChange={formik.handleChange}
                checked={formik.values.recurrentPayment === 'yes'}
                onBlur={formik.handleBlur}
              />
              <Form.Check
                type='radio'
                name='recurrentPayment'
                label='No'
                value='no'
                onChange={formik.handleChange}
                checked={formik.values.recurrentPayment === 'no'}
                onBlur={formik.handleBlur}
              />
            </div>
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='firstPaymentDate' style={{fontSize: '12px'}}>
              First payment date
            </Form.Label>
            <Form.Control
              placeholder=''
              id='firstPaymentDate'
              name='firstPaymentDate'
              value={formik.values.firstPaymentDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type='date'
            />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='preferredPayment' style={{fontSize: '12px'}}>
              Preferred payment day each month
            </Form.Label>
            <Form.Select
              aria-label='Default select example'
              id='preferredPayment'
              name='preferredPayment'
              value={formik.values.preferredPayment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value=''>Open this select menu</option>
              {Array.from({length: 31}).map((_, n) => (
                <option value={n + 1} key={n}>
                  {n + 1}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className='mt-5 pb-4'>
        <Col md={4} sm={12}>
          <h5 className='font-bold py-2 fs-4' style={{borderBottom: '1px solid lightgray'}}>
            Credit/Debit Card
          </h5>
          <Form.Group className='mb-3'>
            <div className='d-flex gap-2 mt-3'>
              <Form.Check
                type='radio'
                name='typeCard'
                value='CREDIT CARD'
                checked={formik.values.typeCard === 'CREDIT CARD'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label='Credit Card'
              />
              <Form.Check
                type='radio'
                name='typeCard'
                value='DEBIT CARD'
                checked={formik.values.typeCard === 'DEBIT CARD'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label='Debit Card'
              />
            </div>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label style={{fontSize: '12px'}}>Card company</Form.Label>
            <Form.Select
              value={formik.values.cardCompany}
              id='cardCompany'
              name='cardCompany'
              onChange={formik.handleChange}
              aria-label='Card company'
            >
              <option value=''>Open this select menu</option>
              {['Visa', 'Master Card', 'Discover', 'Amex'].map((st) => (
                <option value={st} key={st}>
                  {st}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <div className='mb-3'>
            <Form.Label>Card number</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Control
                placeholder='Only numbers allowed'
                aria-label='Only numbers allowed'
                aria-describedby='Card number'
                type={showCardNum ? 'text' : 'password'}
                id='cardNumber'
                name='cardNumber'
                value={formik.values.cardNumber}
                onChange={(e) => {
                  const {value} = e.target
                  if (value === '') return formik.setFieldValue('cardNumber', '')
                  else if (Number(value)) formik.setFieldValue('cardNumber', value)
                }}
              />
              <Button
                variant='outline-secondary'
                onClick={toggleCardNum}
                className='border'
                id='btn-card-num'
              >
                {showCardNum ? (
                  <i className='bi bi-eye-slash-fill' />
                ) : (
                  <i className='bi bi-eye-fill' />
                )}
              </Button>
            </InputGroup>
          </div>
          <Row className='mb-3'>
            <Col md={6} sm={12}>
              <Form.Group className='mb-3'>
                <Form.Label style={{fontSize: '12px'}}>Expiration month</Form.Label>
                <Form.Select
                  aria-label='Expiration month'
                  id='expirationMonth'
                  name='expirationMonth'
                  value={formik.values.expirationMonth}
                  onChange={formik.handleChange}
                >
                  <option value=''>Open this select menu</option>
                  {MONTHS.map(({val, label}) => (
                    <option value={val} key={label}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} sm={12}>
              <Form.Group className='mb-3'>
                <Form.Label style={{fontSize: '12px'}}>Expiration year</Form.Label>
                <Form.Select
                  aria-label='Expiration year'
                  id='expirationDay'
                  name='expirationDay'
                  value={formik.values.expirationDay}
                  onChange={formik.handleChange}
                >
                  <option value=''>Open this select menu</option>
                  {Array.from({length: 11})
                    .map((_, n) => n)
                    .map((n) => (
                      <option key={n} value={2024 + n}>
                        {2024 + n}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <div className='mb-3'>
            <Form.Label>CVV / CSV</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Control
                placeholder='3 or 4 digits security code'
                aria-label='CVV / CSV'
                id='cvvCsv'
                name='cvvCsv'
                value={formik.values.cvvCsv}
                onChange={(e) => {
                  const {value} = e.target
                  if (value === '') return formik.setFieldValue('cvvCsv', '')
                  else if (Number(value)) formik.setFieldValue('cvvCsv', value)
                }}
                type={showCvvCsv ? 'text' : 'password'}
                maxLength={4}
              />
              <Button
                variant='outline-secondary'
                onClick={toggleCvvCsv}
                className='border'
                id='button-addon2'
              >
                {showCardNum ? (
                  <i className='bi bi-eye-slash-fill' />
                ) : (
                  <i className='bi bi-eye-fill' />
                )}
              </Button>
            </InputGroup>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <h5 className='font-bold py-2 fs-4' style={{borderBottom: '1px solid lightgray'}}>
            Bank Account
          </h5>
          <Form.Group className='mb-3'>
            <div className='d-flex gap-2 mt-3'>
              <Form.Check
                type='checkbox'
                name='bankAccount'
                checked={Boolean(formik.values.bankAccount)}
                onChange={(e) => {
                  const {checked} = e.target
                  formik.setFieldValue('bankAccount', checked ? 'yes' : '')
                }}
                label='Bank Account'
              />
            </div>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='bankName' style={{fontSize: '12px'}}>
              Bank name
            </Form.Label>
            <Form.Control
              id='bankName'
              name='bankName'
              value={formik.values.bankName}
              onChange={formik.handleChange}
              placeholder=''
            />
          </Form.Group>
          <div className='mb-3'>
            <Form.Label>ABA / Routing</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Control
                placeholder='Only numbers allowed'
                aria-label='ABA / Routinge'
                aria-describedby='ABA / Routing'
                id='ABARouting'
                name='ABARouting'
                type={showABA ? 'text' : 'password'}
                value={formik.values.ABARouting}
                onChange={(e) => {
                  const {value} = e.target
                  if (value === '') return formik.setFieldValue('ABARouting', '')
                  else if (Number(value)) formik.setFieldValue('ABARouting', value)
                }}
              />
              <Button
                variant='outline-secondary'
                className='border'
                id='button-addon2'
                onClick={toggleABA}
              >
                {showABA ? (
                  <i className='bi bi-eye-slash-fill' />
                ) : (
                  <i className='bi bi-eye-fill' />
                )}
              </Button>
            </InputGroup>
          </div>
          <div className='mb-3'>
            <Form.Label>Account Number</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Control
                type={showAccountNum ? 'text' : 'password'}
                placeholder='Only numbers allowed'
                aria-label='Account Number'
                aria-describedby='Account Number'
                id='accountNumber'
                name='accountNumber'
                value={formik.values.accountNumber}
                onChange={(e) => {
                  const {value} = e.target
                  if (value === '') return formik.setFieldValue('accountNumber', '')
                  else if (Number(value)) formik.setFieldValue('accountNumber', value)
                }}
              />
              <Button
                variant='outline-secondary'
                onClick={toggleAccountNum}
                className='border'
                id='show-account-number'
              >
                {showAccountNum ? (
                  <i className='bi bi-eye-slash-fill' />
                ) : (
                  <i className='bi bi-eye-fill' />
                )}
              </Button>
            </InputGroup>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <h5 className='font-bold py-2 fs-4' style={{borderBottom: '1px solid lightgray'}}>
            Billing Address{' '}
            <span className='ms-2 ' data-tooltip-id='my-tooltip-info-11'>
              <i className='bi bi-question-circle-fill text-primary' />
            </span>
            <Tooltip
              id='my-tooltip-info-11'
              place='bottom'
              style={{
                zIndex: 99999,
                backgroundColor: 'white',
                color: 'black',
                boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
              }}
            >
              <div className='tooltip-tfi'>
                <h6 className='text-center'>English</h6>
                <p>A billing address is the address connected to a specific form of payment.</p>
                <h6 className='text-center'>Español</h6>
                <p>
                  Una dirección de facturación es la dirección relacionada con una forma de pago
                  específica.
                </p>
              </div>
            </Tooltip>
          </h5>
          {/* <Form.Group className='mb-3' controlId='exampleForm.d'>
            <div className='d-flex gap-2 mt-3'>
              <Form.Check type='checkbox' name='equal33' label='Same as physical address' />
            </div>
          </Form.Group> */}
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='paymentHolder' style={{fontSize: '12px'}}>
              Payment method holder
              <span className='ms-2 ' data-tooltip-id='my-tooltip-info-15'>
                <i className='bi bi-question-circle-fill text-primary' />
              </span>
              <Tooltip
                id='my-tooltip-info-15'
                place='bottom'
                style={{
                  zIndex: 99999,
                  backgroundColor: 'white',
                  color: 'black',
                  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                }}
              >
                <div className='tooltip-tfi'>
                  <h6 className='text-center'>English</h6>
                  <p>Name of the person that owns the payment method.</p>
                  <h6 className='text-center'>Español</h6>
                  <p>Nombre de la persona propietaria del método de pago.</p>
                </div>
              </Tooltip>
            </Form.Label>
            <Form.Control
              placeholder=''
              id='paymentHolder'
              name='paymentHolder'
              value={formik.values.paymentHolder}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='billingAddress'>Billing Address</Form.Label>
            <Form.Control
              id='billingAddress'
              name='billingAddress'
              value={formik.values.billingAddress}
              onChange={formik.handleChange}
              placeholder=''
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='address1'>Address line #1</Form.Label>
            <Form.Control
              id='address1'
              name='address1'
              value={formik.values.address1}
              onChange={formik.handleChange}
              placeholder=''
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='address2'>Address line #2</Form.Label>
            <Form.Control
              id='address2'
              name='address2'
              value={formik.values.address2}
              onChange={formik.handleChange}
              placeholder='OPTIONAL'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='city'>City</Form.Label>
            <Form.Control
              id='city'
              name='city'
              value={formik.values.city}
              onChange={formik.handleChange}
              placeholder=''
            />
          </Form.Group>
          <div className='mb-3'>
            <Form.Group>
              <Form.Label htmlFor='state'>State</Form.Label>
              <Form.Select
                id='state'
                name='state'
                onChange={formik.handleChange}
                value={formik.values.state}
                onBlur={formik.handleBlur}
                // isInvalid={Boolean(errors.stateEU && touched.stateEU)}
              >
                <option value=''>Select one</option>
                {dataStates?.allStates?.edges.map(({node}) => (
                  <option key={node.id} value={node.id}>
                    {node.name} ({node.code})
                  </option>
                ))}
                {/* {errors.stateEU && touched.stateEU && (
                  <Form.Control.Feedback type='invalid'>{errors.stateEU}</Form.Control.Feedback>
                )} */}
              </Form.Select>
            </Form.Group>
          </div>
          <Form.Group className='mb-3'>
            <Form.Label htmlFor='zipCode'>Zip code</Form.Label>
            <Form.Control
              value={formik.values.zipCode}
              name='zipCode'
              id='zipCode'
              onChange={formik.handleChange}
              placeholder=''
            />
          </Form.Group>
        </Col>
      </Row>
      <div className='my-5'>
        <Button
          size='sm'
          variant='info'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
        >
          {loading && <Spinner animation='border' size='sm' />}
          {node ? 'Save payment' : 'Add payment'}
        </Button>
      </div>
      <div
        className='py-5 px-3 d-flex align-items-center justify-content-between'
        style={{borderTop: '1px solid #e1e1e1'}}
      >
        <Button variant='secondary' onClick={onPrevStep}>
          PREVIOUS
        </Button>
        <Button onClick={onNextStep}>NEXT STEP</Button>
      </div>
    </div>
  )
}

/*
<div className='kt-heading kt-heading--md custom-border-brand'>Insurance information</div>
      <Row>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>FFM used in marketplace</Form.Label>
            <Form.Control placeholder='' />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>NPN used in marketplace</Form.Label>
            <Form.Control placeholder='' />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>Type of sale </Form.Label>
            <Form.Select aria-label='Default select example'>
              <option>Open this select menu</option>
              <option value='1'>One</option>
              <option value='2'>Two</option>
              <option value='3'>Three</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>
              Effective date <span style={{color: 'red'}}>(required)</span>
            </Form.Label>
            <Form.Control type='date' placeholder='' />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>Marketplace ID</Form.Label>
            <Form.Control placeholder='(optional)' />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>Member ID </Form.Label>
            <Form.Control placeholder='(optional)' />
          </Form.Group>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>CMS Plan ID</Form.Label>
            <Form.Control placeholder='(optional)' />
          </Form.Group>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>
              Carrier <span style={{color: 'red'}}>(required)</span>
            </Form.Label>
            <Select
              styles={{
                control: (base) => ({...base, padding: '3px 2px'}),
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>Plan name </Form.Label>
            <Form.Control placeholder='' />
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>Metal level </Form.Label>
            <Form.Select aria-label='Default select example'>
              <option>Open this select menu</option>
              <option value='1'>One</option>
              <option value='2'>Two</option>
              <option value='3'>Three</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>
              CMS Plan ID
              <span className='ms-2 ' data-tooltip-id='my-tooltip-info-1'>
                <i className='bi bi-question-circle-fill text-primary' />
              </span>
              <Tooltip
                id='my-tooltip-info-1'
                place='bottom'
                style={{
                  zIndex: 99999,
                  backgroundColor: 'white',
                  color: 'black',
                  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                }}
              >
                <div className='tooltip-tfi'>
                  <h6 className='text-center'>English</h6>
                  <p>
                    The policy total cost is the amount of money an individual or business pays for
                    an insurance policy.
                  </p>
                  <h6 className='text-center'>Español</h6>
                  <p>
                    El costo total de la póliza es la cantidad de dinero que un individuo o negocio
                    paga por una póliza de seguros.
                  </p>
                </div>
              </Tooltip>
            </Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control aria-label='Amount (to the nearest dollar)' />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>
              Tax Credit / Subsidy
              <span className='ms-2 ' data-tooltip-id='my-tooltip-info-2'>
                <i className='bi bi-question-circle-fill text-primary' />
              </span>
              <Tooltip
                id='my-tooltip-info-2'
                place='bottom'
                style={{
                  zIndex: 99999,
                  backgroundColor: 'white',
                  color: 'black',
                  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                }}
              >
                <div className='tooltip-tfi'>
                  <h6 className='text-center'>English</h6>
                  <p>
                    The subsidy is the amount of money the client was approved to get as financial
                    assistance.
                  </p>
                  <h6 className='text-center'>Español</h6>
                  <p>
                    El subsidio es la cantidad de dinero que se aprobó para que el cliente reciba
                    como ayuda financiera.
                  </p>
                </div>
              </Tooltip>
            </Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control aria-label='Amount (to the nearest dollar)' />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={4} sm={12}>
          <Form.Group className='mb-3' >
            <Form.Label style={{fontSize: '12px'}}>
              Premium (monthly payment)
              <span className='ms-2 ' data-tooltip-id='my-tooltip-info-3'>
                <i className='bi bi-question-circle-fill text-primary' />
              </span>
              <Tooltip
                id='my-tooltip-info-3'
                place='bottom'
                style={{
                  zIndex: 99999,
                  backgroundColor: 'white',
                  color: 'black',
                  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                }}
              >
                <div className='tooltip-tfi'>
                  <h6 className='text-center'>English</h6>
                  <p>
                    The premium is the amount of money the client will pay each month while the
                    policy remain active.
                  </p>
                  <h6 className='text-center'>Español</h6>
                  <p>
                    La prima es la cantidad de dinero que el cliente pagará cada mes mientras la
                    póliza permanezca activa.
                  </p>
                </div>
              </Tooltip>
            </Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control aria-label='Amount (to the nearest dollar)' />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
*/

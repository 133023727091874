import {gql} from '@apollo/client'

export const GET_ALL_GROUPS = gql`
  query allGroupAssignments(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $name: String
    $name_Icontains: String
    $name_Istartswith: String
  ) {
    allGroupAssignments(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      name: $name
      name_Icontains: $name_Icontains
      name_Istartswith: $name_Istartswith
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          created
          name
          user {
            edges {
              node {
                id
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`

import {useMutation} from '@apollo/client'
import React, {useEffect} from 'react'
import {Button, Form, Modal, Spinner} from 'react-bootstrap'
import {ADD_EDIT_PIPELINE} from '../../../gql/mutations/pipelineMutations'
import {IResAddPipe} from '../../../types/pipelines'
import {toast} from 'react-toastify'
import {parseId} from '../../../helpers'

interface IModalAddPipelines {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  infoEdit?: null | {id: string; name: string}
}

export const ModalAddPipeline: React.FC<IModalAddPipelines> = ({
  isOpen,
  onClose,
  refetch,
  infoEdit,
}) => {
  const [gqlAddEditPipeline, {data, loading, error}] = useMutation<IResAddPipe>(ADD_EDIT_PIPELINE)

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.pipelineCreateupdateMutation?.pipeline?.id) {
      toast.success(`Success: pipeline ${infoEdit ? 'edited correctly' : 'successfully added'}`)
      onClose()
      refetch()
    } else if (data.pipelineCreateupdateMutation?.errors?.length > 0) {
      toast.error(
        `Error: ${
          data.pipelineCreateupdateMutation?.errors?.[0]?.messages?.[0] || 'something happened'
        }`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const {name} = Object.fromEntries(new FormData(e.currentTarget))
    gqlAddEditPipeline({
      variables: {
        input: {
          id: infoEdit ? parseId(infoEdit.id) : undefined,
          name,
        },
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{infoEdit ? 'Edit' : 'Add'} Pipeline</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <Form.Label>Pipeline name</Form.Label>
            <Form.Control
              type='name'
              name='name'
              defaultValue={infoEdit ? infoEdit.name : ''}
              required
              placeholder='Enter name'
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' variant='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            variant='primary'
            className='d-flex align-items-center'
            disabled={loading}
          >
            {loading && <Spinner animation='border' size='sm' />}
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

import React, {Dispatch, SetStateAction, useEffect} from 'react'
import {Card, Spinner} from 'react-bootstrap'
import {IResNotesList, NodeNotes} from '../../leads/components/sectionsTopNav/sectionNavTypes'
import {GET_ALL_NOTES_BY_LEAD} from '../../../gql/queries/leadsQuery'
import {toast} from 'react-toastify'
import {useQuery} from '@apollo/client'
import moment from 'moment'
import Lottie from 'lottie-react'
import noNotesLottie from '../../../assets/lotties/no-notes.json'

export const NotesLead: React.FC<{
  setInfoEditNote: Dispatch<SetStateAction<Omit<NodeNotes, 'date' | 'user' | 'created'> | null>>
  onOpenEdit: () => void
  refetchRefNote: number
  leadId: string
}> = ({leadId, onOpenEdit, setInfoEditNote, refetchRefNote}) => {
  const {data, loading, error, refetch} = useQuery<IResNotesList>(GET_ALL_NOTES_BY_LEAD, {
    variables: {
      id: leadId,
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (refetchRefNote === 0) return
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchRefNote])

  if (loading || !data) {
    return (
      <div>
        <Spinner animation='border' />
      </div>
    )
  }

  if (!data.leadById) {
    return (
      <div className='mt-6'>
        <h3>Error loading notes</h3>
      </div>
    )
  }
  if (data.leadById.noteSet.edges.length === 0) {
    return (
      <div className='mt-6'>
        <h4 className='text-center mt-8'>There are no notes recorded for this Lead</h4>
        <div style={{margin: '0 auto'}}>
          <Lottie
            animationData={noNotesLottie}
            autoplay
            style={{maxWidth: '40%', margin: '30px auto'}}
          />
        </div>
      </div>
    )
  }

  return (
    <div className='d-flex flex-column gap-4'>
      {data.leadById.noteSet.edges.map(({node}) => (
        <Card key={node.id}>
          <Card.Header className='d-flex align-items-center justify-content-between bg-warning bg-gradient'>
            <Card.Title>
              <span className='me-4'>
                <i className='bi bi-sticky fs-1 text-primary' />
              </span>{' '}
              {node.tittle}
            </Card.Title>
            <span>{moment(node.created).format('DD MMM YYYY, hh:mm A')}</span>
          </Card.Header>
          <Card.Body>{node.description}</Card.Body>
          <Card.Footer className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center gap-2'>
              <button
                className='btn  btn-sm'
                onClick={() => {
                  setInfoEditNote({
                    description: node.description,
                    id: node.id,
                    tittle: node.tittle,
                  })
                  onOpenEdit()
                }}
              >
                <i className='bi bi-pencil fs-3' style={{color: 'blue'}} />
              </button>
              <button className='btn  btn-sm'>
                <i className='bi bi-trash fs-3' style={{color: 'red'}} />
              </button>
            </div>
            <div>
              <span
                style={{
                  display: 'inline-block',
                  padding: '2px 6px',
                  border: '1px solid purple',
                  color: 'purple',
                }}
                className='text-uppercase me-3 rounded-circle'
              >
                {node.user.firstName?.[0] || 'N'}
              </span>
              {node.user.firstName || 'N/'} {node.user.lastName || 'A'}
            </div>
          </Card.Footer>
        </Card>
      ))}
    </div>
  )
}

import {Breadcrumb, Col, Dropdown, Row, Spinner} from 'react-bootstrap'

export const LoadingOnePolicy = () => {
  return (
    <div>
      <header
        className='py-4 d-flex align-items-center justify-content-between'
        style={{width: '100%', marginTop: '-10px'}}
      >
        <Breadcrumb>
          <Breadcrumb.Item className='d-blok d-md-none'>
            <span>
              <i className='bi bi-list fs-1' />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Quote</Breadcrumb.Item>
          <Breadcrumb.Item active>
            Quote <Spinner animation='border' size='sm' className='ms-3' />
          </Breadcrumb.Item>
        </Breadcrumb>

        <Dropdown>
          <Dropdown.Toggle variant='primary' size='sm' id='dropdown-basic' disabled>
            Options
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </header>
      <div className='mt-4'>
        <Row>
          <Col md={4} lg={4} sm={0} xl={3} className='d-none d-md-block'>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
                backgroundColor: '#fff',
                marginBottom: '20px',
                borderRadius: '4px',
                padding: '8px 10px',
              }}
            >
              <div className='kt-widget__content pt-0'>
                <div className='kt-heading kt-heading--sm custom-border-brand my-3'>
                  Policy Summary
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Agent:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Assigned to:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Internal code:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>State:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Carrier:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Effective:</span>
                  <span className='kt-widget__data' id='effective_label'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>No. of applicants:</span>
                  <span className='kt-widget__data' id='no_applicants'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Status:</span>
                  <span className='kt-widget__data' id='policy_status_label'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Documents status:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Payment status:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Last update:</span>
                  <span className='kt-widget__data' id='last_update_label'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-heading kt-heading--sm custom-border-brand my-3'>
                  Household Information
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Holder:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Date of birth:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Phone</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Location</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Annual Income</span>
                  <span className='kt-widget__data' id='household_income_label'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
                <div className='kt-widget__info'>
                  <span className='kt-widget__label'>Member ID:</span>
                  <span className='kt-widget__data'>
                    <span
                      className='placeholder-glow'
                      style={{
                        width: '120px',
                        display: 'inline-block',
                      }}
                    >
                      <span className='placeholder col-12' />
                    </span>
                  </span>
                </div>
              </div>

              <div>
                <ul className='nav flex-column nav-pills w-100'>
                  {[
                    {name: 'Policy Form', icon: 'person-fill'},
                    {name: 'Documents', icon: 'folder-fill'},
                    {name: 'Notes', icon: 'pencil-square'},
                    {name: 'Reminders', icon: 'arrow-repeat'},
                  ].map((tabl) => (
                    <li className='nav-item w-100 py-2' key={tabl.name}>
                      <button
                        style={{textAlign: 'left', opacity: 0.5}}
                        className={`nav-link w-100 d-flex align-items-center gap-2`}
                        aria-current='page'
                        disabled
                      >
                        <span>
                          <i className={`bi bi-${tabl.icon} fs-4`} style={{color: 'inherit'}} />
                        </span>
                        <span>{tabl.name}</span>
                      </button>
                    </li>
                  ))}
                  {/* <li className='nav-item w-100 py-2'>
                    <button
                      style={{textAlign: 'left'}}
                      className='nav-link w-100 d-flex align-items-center gap-2'
                      aria-current='page'
                    >
                      <span>
                        <i className='bi bi-folder-fill fs-4' style={{color: 'inherit'}} />
                      </span>
                      <span>Documents</span>
                    </button>
                  </li>
                  <li className='nav-item w-100 py-2'>
                    <button
                      style={{textAlign: 'left'}}
                      className='nav-link w-100 d-flex align-items-center gap-2'
                      aria-current='page'
                    >
                      <span>
                        <i className='bi bi-pencil-square fs-4' style={{color: 'inherit'}} />
                      </span>
                      <span>Notes</span>
                    </button>
                  </li>
                  <li className='nav-item w-100 py-2'>
                    <button
                      style={{textAlign: 'left'}}
                      className='nav-link w-100 d-flex align-items-center gap-2'
                      aria-current='page'
                    >
                      <span>
                        <i className='bi bi-arrow-repeat fs-4' style={{color: 'inherit'}} />
                      </span>
                      <span>Reminders</span>
                    </button>
                  </li> */}
                </ul>
              </div>
            </div>
          </Col>
          <Col md={8} lg={8} sm={12} xl={9}>
            <div>
              <div className='d-flex flex-column gap-2 flex-xl-row'>
                {[
                  {
                    div: [
                      {num: 1, name: 'Policyholder'},
                      {num: 2, name: 'Members'},
                    ],
                  },
                  {
                    div: [
                      {num: 3, name: 'Insurance & Payment'},
                      {num: 4, name: 'Extras'},
                    ],
                  },
                ].map(({div}, i) => (
                  <div key={i} className='d-flex flex-column flex-md-row' style={{flex: 1}}>
                    {div.map((d) => (
                      <button
                        key={d.num}
                        className='btn d-flex align-items-center gap-2 btn-block bg-white'
                        style={{
                          flex: 1,
                          boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.12), 0 -1px 3px 0 rgba(0,0,0,0.12)',
                          opacity: 0.3,
                        }}
                        disabled
                      >
                        <span
                          style={{
                            fontSize: '1.3rem',
                            fontWeight: 600,
                            flex: '0 0 2.75rem',
                            height: '2.75rem',
                            width: '2.75rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(93, 120, 255, 0.08)',
                            color: '#5d78ff',
                            marginRight: '1rem',
                            borderRadius: ' 0.5rem',
                          }}
                        >
                          {d.num}
                        </span>
                        <span style={{color: 'gray'}}>{d.name}</span>
                      </button>
                    ))}
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
                  backgroundColor: '#fff',
                  marginBottom: '20px',
                  borderRadius: '4px',
                  padding: '8px 10px',
                }}
              >
                <div className='d-flex flex-center' style={{height: '50vh'}}>
                  <Spinner animation='border' />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

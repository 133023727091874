import {useLazyQuery} from '@apollo/client'
import {GET_POLICY_REPORTS_USERS} from '../../../gql/queries/reportsQuery'
import {IResPolicyReportsUsers} from '../../../types/reportsTypes'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {Card, Spinner} from 'react-bootstrap'
import Chart from 'react-apexcharts'

interface IPolicyProps {
  start: Date
  end: Date
}

export const PolicyReportUsers: React.FC<IPolicyProps> = ({end, start}) => {
  const [gqlGet, {data, loading, error}] = useLazyQuery<IResPolicyReportsUsers>(
    GET_POLICY_REPORTS_USERS,
    {fetchPolicy: 'no-cache'}
  )

  useEffect(() => {
    const variables = {
      startDate: start.toISOString(),
      endDate: end.toISOString(),
      pendingByDate: false,
      sortOrder: 'completed_desc',
    }
    gqlGet({
      variables,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (!data || loading) {
    return (
      <div className='d-flex flex-center' style={{height: '35vh'}}>
        <Spinner animation='border' />
      </div>
    )
  }

  const {totals, userStats} = data.policyStatus

  return (
    <div>
      <Card className='text-center mb-4'>
        <Card.Body>
          <Card.Title>Total Policies Summary</Card.Title>
          <Chart
            options={{
              chart: {
                type: 'bar',
              },
              labels: ['Completed', 'Pending', 'Canceled'],
              dataLabels: {
                enabled: true,
              },
              colors: ['#28a745', '#ffc107', '#dc3545'],
            }}
            series={[
              {
                name: 'Total',
                data: [
                  {
                    x: 'Completed',
                    y: totals.totalCompleted,
                    fillColor: '#28a745',
                  },
                  {
                    x: 'Pending',
                    y: totals.totalPending,
                    fillColor: '#ffc107',
                  },
                  {
                    x: 'Canceled',
                    y: totals.totalCanceled,
                    fillColor: '#dc3545',
                  },
                ],
              },
            ]}
            type='bar'
            height={250}
          />
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>Users</Card.Title>
        </Card.Header>
        <Card.Body>
          <Chart
            options={{
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                  show: true,
                },
                zoom: {
                  enabled: true,
                },
              },
              colors: ['#28a745', '#ffc107', '#dc3545'],
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    legend: {
                      position: 'bottom',
                      offsetX: -10,
                      offsetY: 0,
                    },
                  },
                },
              ],
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              xaxis: {
                categories: userStats.map((us) => `${us.user.firstName} ${us.user.lastName}`),
              },
            }}
            series={[
              {
                name: 'COMPLETED',
                data: userStats.map((us) => us.completedCount),
              },
              {
                name: 'PENDING',
                data: userStats.map((us) => us.pendingCount),
              },
              {
                name: 'CANCELED',
                data: userStats.map((us) => us.canceledCount),
              },
            ]}
            type='bar'
          />
        </Card.Body>
      </Card>
    </div>
  )
}

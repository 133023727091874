import {useMutation} from '@apollo/client'
import React, {useEffect} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import {DUPLICATE_PIPELINE} from '../../../gql/mutations/pipelineMutations'
import {IResDuplicatePipe} from '../../../types/pipelines'
import {toast} from 'react-toastify'
import {parseId} from '../../../helpers'

interface IModalDuplicatePipeline {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  infoEdit: {id: string; name: string}
}

export const ModalDuplicatePipeline: React.FC<IModalDuplicatePipeline> = ({
  infoEdit,
  isOpen,
  onClose,
  refetch,
}) => {
  const [gqlAddEditPipeline, {data, loading, error}] =
    useMutation<IResDuplicatePipe>(DUPLICATE_PIPELINE)

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.duplicatePipeline?.pipeline?.id) {
      toast.success(`Success: Duplicate pipeline successfully`)
      onClose()
      refetch()
    } else if (data.duplicatePipeline?.errors?.length > 0) {
      toast.error(`Error: ${data.duplicatePipeline?.errors?.[0]?.message || 'something happened'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleDuplicate = () => {
    gqlAddEditPipeline({
      variables: {
        pipelineId: parseId(infoEdit.id),
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Duplicate Pipeline</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>
          Are you sure you want to duplicate this pipeline "
          <span className='text-info'>{infoEdit.name}</span>"?
        </h2>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='primary'
          className='d-flex align-items-center'
          disabled={loading}
          onClick={handleDuplicate}
        >
          {loading && <Spinner animation='border' size='sm' />}
          Duplicate
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

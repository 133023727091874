import {gql} from '@apollo/client'

export const GET_ALL_POLICY_TYPES = gql`
  query allCategoryPolicy(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $name: String
    $name_Icontains: String
    $name_Istartswith: String
  ) {
    allCategoryPolicy(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      name: $name
      name_Icontains: $name_Icontains
      name_Istartswith: $name_Istartswith
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          policyTypeSet {
            edges {
              node {
                id
                typePolicy
              }
            }
          }
        }
      }
    }
  }
`
